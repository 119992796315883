import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VideoTimeService {

  clearYoutubeSeekTime: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  currentTraining = null;

  private videoConfig = {
    time: 0,
    videoType: '',
    videoIndex: 0
  }
  constructor() { }

  setVideoConfig(time, type, videoIndex?) {
    this.videoConfig.time = time;
    this.videoConfig.videoType = type;
    if (videoIndex) {
      this.videoConfig.videoIndex = videoIndex;
    }
  }

  getVideoConfig() {
    return JSON.parse(JSON.stringify(this.videoConfig));
  }

}
