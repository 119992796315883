import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingSizesService {

  chartSizes: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

  constructor() { }
}
