<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
         style="background-image: url(./assets/media//bg/login-page-bg.jpeg);position: relative;">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
        <div class="app-version">
          <p>Version {{appVersion}}</p>
        </div>
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<img src="./assets/media/logos/logo_mindfield_mitDomain.png" class="max-h-70px" style="height: 70px;"/>
				</a>
				<!--end: Aside header-->

				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center logos-container">
					<h3 class="font-size-h1 mb-5 welcome-heading">{{ "AUTH.ASIDE.WELCOME_TEXT" | translate }}</h3>
					<!-- <p class="font-weight-lighter text-white opacity-80">
						The ultimate Bootstrap, Angular 8, React & VueJS admin theme framework for next generation web
						apps.
					</p> -->
					<div>
<!--						<div class="row">-->
<!--							<div class="col p-5 text-right">-->
<!--								<img src="./assets/media/logos/eSense-SkinReponse.png" class="max-h-70px" />-->
<!--							</div>-->
<!--							<div class="col p-5">-->
<!--								<img src="./assets/media/logos/eSense-Temperature.png" class="max-h-70px" />-->

<!--							</div>-->
<!--						</div>-->
<!--						<div class="row">-->
<!--							<div class="col p-5 text-right">-->
<!--								<img src="./assets/media/logos/eSense-Pulse.png" class="max-h-70px" />-->
<!--							</div>-->
<!--							<div class="col p-5">-->
<!--								<img src="./assets/media/logos/eSense-Respiration.png" class="max-h-70px" />-->
<!--							</div>-->
<!--						</div>-->
            <div class="egenius-logo mt-3">
                <img src="./assets/media/logos/eSense-genius.png" alt="logo"/>
            </div>
					</div>

				</div>
				<!--end: Aside content-->

				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10 footer-bottom">
					<div class="opacity-70 font-weight-bold	text-white">
						&copy; {{currentYear}} Mindfield Biosystems Ltd.
					</div>
					<div class="d-flex">
						<a href="https://www.mindfield.de/en/Lower-Menu/Privacy-Policy-of-Mindfield-Biosystems-Ltd.html"
							class="text-white">{{ "AUTH.ASIDE.DATA_PRIVACY" | translate }}</a>
						<a href="https://www.mindfield.de/en/lower-menu/about-us.html" class="text-white ml-10">{{ "AUTH.ASIDE.ABOUT" | translate }}
							Us</a>
						<a href="https://www.mindfield.de/en/Lower-Menu/Contact.html"
							class="text-white ml-10">{{ "AUTH.ASIDE.CONTACT" | translate }}</a>
					</div>
				</div>
				<!--end: Aside footer for desktop-->
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<!--end::Content header-->

			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div
				class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5 footer-bottom">
				<div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
					&copy; {{currentYear}} Mindfield Biosystems Ltd.
				</div>
				<div class="d-flex order-1 order-sm-2 my-2">
					<a href="#" class="text-dark-75 text-hover-primary">{{ "AUTH.ASIDE.PRIVACY" | translate }}</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">{{ "AUTH.ASIDE.LEGAL" | translate }}</a>
					<a href="#" class="text-dark-75 text-hover-primary ml-4">{{ "AUTH.ASIDE.CONTACT" | translate }}</a>
				</div>
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>
