<!-- begin: Header -->
<div ktHeader #ktHeader class="header " [ngClass]="headerClasses" id="kt_header">
  <!--begin::Container-->
  <div [ngClass]="headerContainerClasses" class="d-flex align-items-center justify-content-between">

    <ng-container class="header-left-side">
      <kt-subheader1
        [fixed]="fixed"
        [clear]="clear"
        [style]="style"
        [displayDesc]="displayDesc"
        [subheaderClasses]="subheaderClasses"
        [subheaderContainerClasses]="subheaderContainerClasses"
        [displayDaterangepicker]="displayDaterangepicker"
      ></kt-subheader1>
    </ng-container>


    <div class="header-right-side">
      <ng-container *ngIf="(loader.progress$|async) > 0">
        <ngb-progressbar class="loading-bar" [value]="loader.progress$|async" height="3px"></ngb-progressbar>
      </ng-container>

      <!-- begin: Header Menu -->
      <ng-container *ngIf="headerMenuSelfDisplay">
        <!--      <kt-menu-horizontal-->
        <!--        [headerLogo]="headerLogo"-->
        <!--        [headerMenuSelfDisplay]="headerMenuSelfDisplay"-->
        <!--        [headerMenuClasses]="headerMenuClasses"-->
        <!--      ></kt-menu-horizontal>-->
      </ng-container>
      <!-- end: Header Menu -->
      <kt-genius-settings *ngIf="isShowGeniusSettings"></kt-genius-settings>
      <!-- begin:: Header Topbar -->
      <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
      <ng-container *ngIf="!headerMenuSelfDisplay">
        <div></div>
      </ng-container>
      <!--begin::Topbar-->
      <kt-topbar class="topbar"></kt-topbar>
      <!--end::Topbar-->
    </div>
    <!-- end:: Header Topbar -->
  </div>
  <!--end::Container-->
</div>
<!-- end: Header -->
