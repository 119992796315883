import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SensorStateService} from '../../../../services/sensor-state/sensor-state.service';
import {MatDialog} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {take, throttleTime} from 'rxjs/operators';
import {MuscleDataService} from '../../../../services/muscle-data.service';
import {
  ElectrodeContactDialogComponent
} from '../../../../components/electrode-contact-dialog/electrode-contact-dialog.component';
import {RewardService} from '../../../../services/reward/reward.service';
import {Router} from '@angular/router';
import {SimulatorService} from '../../../../services/simulator/simulator.service';
import {CurrentUserService} from '../../../../services/current-user/current-user.service';
import {IUser} from '../../../../core/interfaces/user';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {TemplateTranslateService} from '../../../../services/template-translate/template-translate.service';
import {TranslationService} from '../../../../core/_base/layout';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TourService} from 'ngx-ui-tour-md-menu';
import {TransformDateService} from '../../../../services/transform-date/transform-date.service';
import {LimitSessionsService} from '../../../../services/limit-sessions/limit-sessions.service';
import moment from 'moment';


@Component({
  selector: 'kt-training-prepare',
  templateUrl: './training-prepare.component.html',
  styleUrls: ['./training-prepare.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', width: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrainingPrepareComponent implements OnInit, OnDestroy {

  @Output() startTraining : EventEmitter<any> = new EventEmitter();
  isSensorConnected = false;
  currentImpedance = null;
  currentArtefakt = null;
  impedanceSubscription: Subscription;
  artefaktSubscription: Subscription;
  demoModeSubscription: Subscription;
  routerUrl;
  isDemoMode = false;
  currentUser: IUser;
  userSurveyTrainings = [];
  infoExpandIndex = null;
  supervisorTrainingsSubject = new Subject();
  constructor(
    public sensorState: SensorStateService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private muscleData: MuscleDataService,
    private reward: RewardService,
    private router: Router,
    private simulatorService: SimulatorService,
    private firebaseUser: CurrentUserService,
    private firebase: FirebaseService,
    private templateTranslate: TemplateTranslateService,
    private translates: TranslationService,
    private translate: TranslateService,
    private tour: TourService,
    private transformDateService: TransformDateService,
    private monthSessions: LimitSessionsService
  ) { }

  ngOnInit(): void {
    this.routerUrl = this.router.url;
    this.getSensorState();
    this.subscribeOnSignals();
    this.disableScroll();
    this.currentImpedance = null;
    this.currentArtefakt = null;
    this.reward.canPlayVideo.next(false);
    this.cd.markForCheck();
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.firebaseUser.getCurrentUser().pipe(take(1)).subscribe((user: IUser) => {
      this.currentUser = user;
      if (user.showCoachMarks) {
        this.showCoachMarks();
      }
      if (this.currentUser.role === 'normal') {
        if (this.currentUser.allowedSupervisors.length) {
          this.getTrainerTemplates(this.currentUser.allowedSupervisors);
        } else {
          this.getTrainingBasedOnSurvey();
        }
      } else {
        this.getTrainingBasedOnSurvey();
      }
      this.handleUserSessionsLimit();
    });
  }

  deleteSuperUsersFromList(supervisorsArray) {
    const superUserOne = supervisorsArray.findIndex(id => id === '359352');
    const superUserTwo = supervisorsArray.findIndex(id => id === '18212744');
    if (superUserOne > -1) {
      supervisorsArray.splice(superUserOne, 1)
    }
    if (superUserTwo > -1) {
      supervisorsArray.splice(superUserTwo, 1)
    }
    return supervisorsArray;
  }

  getAllSupervisors() {
    this.getTrainerTemplates(this.currentUser.allowedSupervisors);
  }

  getTrainerTemplates(trainerIds) {
    trainerIds.forEach((id, index) => {
      this.firebase.getTrainingConfiguration(id).pipe(take(1)).subscribe((templates:any) => {
        templates.forEach((template) => {
          template.selectedUsers.forEach((item) => {
            if (item === this.currentUser.id) {
              template.isSupervisorTemplate = true;
              this.userSurveyTrainings.push(template);
              this.cd.markForCheck();
            }
          });
        });
        if (index === trainerIds.length - 1) {
          if (!this.userSurveyTrainings.length) {
            this.getTrainingBasedOnSurvey();
          }
        }
      });
    });
  }

  isSupervisorTemplate(array) {
    if (array.some(training => training.isSupervisorTemplate)) {
      return true;
    } else {
      return false;
    }
  }
  getTrainingBasedOnSurvey() {
    this.firebase.getDefaultTrainingConfigurations().pipe(take(1)).subscribe((data: any) => {
      const selectedUserCategories = [];
      this.currentUser.survey.data.forEach((item) => {
        if (item.checked) {
          selectedUserCategories.push(item.subcategory);
        }
      });
      this.userSurveyTrainings = [];
      const templatesBySurvey = [];
      data.forEach((item) => {
        if (selectedUserCategories.includes(item.templateSubcategory)) {
          if (item.name.includes('${de}') || item.name.includes('${en}')) {
            item.supervisorName = 'Mindfield'
            item.translatedName = this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), item, 'name');
            item.data.forEach((item) => {
              if (item.note.includes('${de}') || item.note.includes('${en}')) {
                item.translatedNote = this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), item, 'note');
              }
            })
          }
          templatesBySurvey.push(item);
        }
      });
      this.filterTemplatesBySurveyChoice(templatesBySurvey);
    });
  }

  filterTemplatesBySurveyChoice(templates) {
    const filteredArray = templates;
    if (this.currentUser.role !== 'normal') {
      this.firebase.getTrainingConfiguration(this.currentUser.id).pipe(take(1)).subscribe((data) => {
        this.userSurveyTrainings = [...data, ...filteredArray];
        this.getAllSupervisors();
      });
    } else {
      this.userSurveyTrainings = filteredArray;
    }
  }

  disableScroll() {
    const body = document.getElementsByTagName('body');
    body[0].style.overflow = 'hidden';
  }
  enableScroll() {
    const body = document.getElementsByTagName('body');
    body[0].style.overflow = 'auto';
  }

  setContentHeight() {
    if (this.routerUrl === '/test') {
      return {
        height: 'auto'
      }
    }
  }

  getSensorState() {
    this.sensorState.isSensorConnected.subscribe((state) => {
      this.isSensorConnected = state;
    });
  }

  subscribeOnSignals() {
    this.artefaktSubscription = this.muscleData.artefaktSubject.pipe(throttleTime(1000)).subscribe((value) => {
        this.currentArtefakt = value;
        this.cd.markForCheck();
    });
    this.impedanceSubscription = this.muscleData.muscleDataSubject.subscribe((impedance) => {
      if (impedance && impedance.data.impedance.length) {
        this.currentImpedance = +(impedance.data.impedance[0] / 1000).toFixed(1);
        this.cd.markForCheck();
      }
    });
    this.demoModeSubscription = this.simulatorService.isSimulatorMode.subscribe((condition) => {
      this.isDemoMode = condition;
    });
  }

  openInfoDialogImpedance() {
    this.dialog.open(ElectrodeContactDialogComponent, {
      data: 'ELECTRODE_CONTACT_DIALOG.TEXT_IMPEDANCE'
    })
  }

  openInfoDialogArtefact() {
    this.dialog.open(ElectrodeContactDialogComponent, {
      data: 'ELECTRODE_CONTACT_DIALOG.TEXT_ARTEFAKT'
    })
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
  signalUnsubscribe() {
    if (this.impedanceSubscription) {
      this.impedanceSubscription.unsubscribe();
    }
    if (this.artefaktSubscription) {
      this.artefaktSubscription.unsubscribe();
    }
    if (this.demoModeSubscription) {
      this.demoModeSubscription.unsubscribe();
    }
    this.enableScroll();
    this.isSensorConnected = false;
    this.currentImpedance = null;
    this.currentArtefakt = null;
    this.cd.markForCheck();
  }

  toTraining(training) {
    this.signalUnsubscribe();
    const data = {
      templateToTrain: training,
      listOfTrainings: this.userSurveyTrainings
    }
    this.startTraining.emit(data);
  }
  setExpandIndex(index) {
    if (index === this.infoExpandIndex) {
      this.infoExpandIndex = null;
    } else {
      this.infoExpandIndex = index;
    }
  }
  getThresholdTranslate(value) {
    if (!value) {
      return;
    }
    if (value === 'reward') {
      return this.translate.instant('CHART_OPTIONS.THRESHOLD_TYPE_REWARD');
    } else {
      return this.translate.instant('CHART_OPTIONS.THRESHOLD_TYPE_INHIBIT');
    }
  }

  getRatioName(index) {
    switch (index) {
      case 0 :
        return 'Delta'
      case 1 :
        return 'Theta'
      case 2 :
        return 'Lo-alpha'
      case 3 :
        return 'Alpha'
      case 4 :
        return 'Hi-alpha'
      case 5 :
        return 'Smr, lo-beta'
      case 6 :
        return 'Beta 1'
      case 7 :
        return 'Beta 2'
      case 8 :
        return 'Hi-Beta'
      case 9 :
        return 'Artefakt'
    }
  }

  showCoachMarks() {
    this.tour.initialize([
      {
        anchorId: 'prepare-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_PREPARE_COACHMARKS.MAIN_TEXT')}`,
      },
    ]);
    this.tour.steps.forEach((step) => {
      step.nextBtnTitle = `${this.translate.instant('COACHMARKS_BUTTONS.NEXT')}`;
      step.prevBtnTitle = `${this.translate.instant('COACHMARKS_BUTTONS.PREV')}`
      step.enableBackdrop = true
    });
    setTimeout(() => {
      this.tour.start();
    },1000);
  }

  transformDate(date) {
    return this.transformDateService.transformDate(date);
  }
  sortTrainings(trainings) {
    return trainings.sort((a: any, b: any) =>
      new Date(b.id).getTime() - new Date(a.id).getTime()
    );
  }
  handleUserSessionsLimit() {
    if (!this.currentUser.monthSessions) {
      this.clearUserSessionsLimit();
    }
    this.monthSessions.calculateUserMontLimit(this.currentUser).subscribe((updatedUser) => {
      if (updatedUser) {
        this.currentUser = updatedUser;
        this.monthSessions.setUserMonthSessions(updatedUser);
      }
    });
  }
  clearUserSessionsLimit() {
    this.currentUser.monthSessions = {
      sessionsSecondsPerDay: 0,
      catStartSession: true,
      sessionsList: [],
      nextSessionAvailableInHours: null,
      monthTimeLeft: 0,
      dayLimitReached: false
    }
  }
  convertHoursToHHMM(hours) {
    const duration = moment.duration(hours, 'hours');
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
  }
  convertMonthTime(time) {
    return this.monthSessions.secondsToHHMMSS(time);
  }
}
