<div class="card-body-container">
  <div class="page-heading">
    <h2>{{ "CHARTS_NAMING.AMPLITUDE" | translate }}</h2>
    <div class="charts-options">
      <mat-form-field class="channel-choose" tourAnchor="channel-tour">
        <mat-select disabled [(value)]="selectedChannel" (selectionChange)="changeChannel($event)">
          <mat-option value="bp_ch1">{{ "CHART_OPTIONS.CHANNEL_ONE" | translate }}</mat-option>
          <mat-option value="bp_ch2">{{ "CHART_OPTIONS.CHANNEL_TWO" | translate }}</mat-option>
        </mat-select>
        <mat-select-trigger>
          <kt-channel-one-dots></kt-channel-one-dots>
        </mat-select-trigger>
      </mat-form-field>
      <mat-form-field class="y-asis" tourAnchor="y-asis">
        <mat-label>{{ "CHART_OPTIONS.Y_VALUE" | translate }}</mat-label>
        <input matInput [(ngModel)]="maxChartZoom" min="0" (input)="changeChartZoomLevel($event)">
      </mat-form-field>
      <mat-form-field class="x-length" tourAnchor="x-length">
        <mat-label>{{ "CHART_OPTIONS.X_VALUE" | translate }}</mat-label>
        <input matInput [(ngModel)]="maxChartSeconds" (input)="changeChartUpdateInterval($event)">
      </mat-form-field>
      <mat-form-field class="average-presets" tourAnchor="average-presets">
        <mat-label>{{ "CHART_OPTIONS.AVERAGE_PRESETS" | translate }}</mat-label>
        <input matInput [(ngModel)]="selectedAverage" (input)="changeMovingAverage($event)">
      </mat-form-field>
      <mat-form-field class="channel-choose selected-average" tourAnchor="selected-average">
        <mat-label>{{ "CHART_OPTIONS.AVERAGE_PRESETS" | translate }}</mat-label>
        <mat-select [(value)]="selectedAverage" (selectionChange)="changeLongAveraging($event)" >
          <mat-option value="5">5s</mat-option>
          <mat-option value="30">30s</mat-option>
          <mat-option value="60">60s</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="zoom-step" tourAnchor="zoom-step">
        <mat-label>{{ "CHART_OPTIONS.ZOOM_STEP" | translate }}</mat-label>
        <input matInput [(ngModel)]="zoomStep" (input)="saveSettingsToLocalStorage()">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="center end" fxLayoutGap="10px">
    <div class="channel">
      <div class="channel-name" tourAnchor="delta-item">
        <div class="checkbox-item delta-item">
          <input type="checkbox" value="0" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Delta</h5>
        </div>
        <div class="channel-color-delta channel-color"></div>
      </div>
      <p>0.5-4 Hz</p>
      <p>{{currentChannelValues.delta}}</p>
      <p>ø 5s: {{middleChannelValues.delta}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="1" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Theta</h5>
        </div>
        <div class="channel-color-theta channel-color"></div>
      </div>
      <p>4-8 Hz</p>
      <p>{{currentChannelValues.theta}}</p>
      <p>ø 5s: {{middleChannelValues.theta}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="2" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Lo-alpha </h5>
        </div>
        <div class="channel-color-lo-alpha channel-color"></div>
      </div>
      <p>8-10 Hz</p>
      <p>{{currentChannelValues.lowAlpha}}</p>
      <p>ø 5s: {{middleChannelValues.lowAlpha}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="3" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Alpha</h5>
        </div>
        <div class="channel-color-alpha channel-color"></div>
      </div>
      <p>8-12 Hz</p>
      <p>{{currentChannelValues.alpha}}</p>
      <p>ø 5s: {{middleChannelValues.alpha}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="4" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Hi-alpha</h5>
        </div>
        <div class="channel-color-hi-alpha channel-color"></div>
      </div>
      <p>10-12 Hz</p>
      <p>{{currentChannelValues.hiAlpha}}</p>
      <p>ø 5s: {{middleChannelValues.hiAlpha}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="5" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5 class="lo-beta-name">smr, lo-beta</h5>
        </div>
        <div class="channel-color-lo-beta channel-color"></div>
      </div>
      <p>12-15 Hz</p>
      <p>{{currentChannelValues.loBeta}}</p>
      <p>ø 5s: {{middleChannelValues.loBeta}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="6" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Beta 1</h5>
        </div>
        <div class="channel-color-beta-1 channel-color"></div>
      </div>
      <p>15-19 Hz</p>
      <p>{{currentChannelValues.betaOne}}</p>
      <p>ø 5s: {{middleChannelValues.betaOne}}</p>
    </div>
    <div
      class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="7" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Beta 2</h5>
        </div>
        <div class="channel-color-beta-2 channel-color"></div>
      </div>
      <p>19-23 Hz</p>
      <p>{{currentChannelValues.betaTwo}}</p>
      <p>ø 5s: {{middleChannelValues.betaTwo}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="8" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Hi-beta</h5>
        </div>
        <div class="channel-color-hi-beta channel-color"></div>
      </div>
      <p>23-27 Hz</p>
      <p>{{currentChannelValues.hiBeta}}</p>
      <p>ø 5s: {{middleChannelValues.hiBeta}}</p>
    </div>
    <div class="channel">
      <div class="channel-name">
        <div class="checkbox-item">
          <input type="checkbox" value="9" (change)="triggerChartLabel($event.target.value, $event.target.checked)" checked>
          <h5>Artefact</h5>
        </div>
        <div class="channel-color-artefakt channel-color"></div>
      </div>
      <p>52-58 Hz</p>
      <p>{{currentChannelValues.artefakt}}</p>
      <p>ø 5s: {{middleChannelValues.artefakt}}</p>
    </div>
  </div>
  <div class="charts-container">
    <div class="line-chart">
      <canvas #mainLineChart
              id="mainLineChart"
              class="absolute-mid wide-canvas"
              tourAnchor="line-chart">
      </canvas>
    </div>
    <div class="chart-zoom" tourAnchor="chart-zoom">
      <button mat-raised-button (click)="zoomOutChart()">+</button>
      <button mat-raised-button (click)="zoomInChart()">-</button>
    </div>
  </div>
</div>
