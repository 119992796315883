import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartLabelsService {

  second = 0;
  labelsArray = [];

  constructor() { }


  generateChartLabels(seconds, refreshInterval) {
    const step = refreshInterval ? refreshInterval / 1000 : 1
    this.labelsArray = [];
    this.second = 0;
    seconds = seconds / step;
    for (let i = 0; i < seconds; i++) {
      this.second = this.second + step;
      this.labelsArray.push(+this.second.toFixed(1));
    }
    return this.labelsArray;
  }

  rawLabels(seconds) {
    const step = 0.11;
    this.labelsArray = [];
    this.second = 0;
    seconds = seconds / step;
    for (let i = 0; i < seconds; i++) {
      this.second = this.second + step;
      this.labelsArray.push(+this.second.toFixed(1));
    }
    return this.labelsArray;
  }
  generateRawChartLabels(seconds) {
    const desiredLength = seconds * 83;
    const minValue = 0;
    const stepSize = (seconds - minValue) / (desiredLength - 1);
    const labels = [];
    for (let i = 0; i < desiredLength; i++) {
      const label = +(minValue + i * stepSize).toFixed(2);
      labels.push(label);
    }
    return labels;
  }
}
