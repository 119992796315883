import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  isSimulatorMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  simulatorData: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor() { }
}
