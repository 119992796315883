import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PartialsModule} from '../../partials/partials.module';
import {CoreModule} from '../../../core/core.module';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RouterModule} from '@angular/router';
import {TrainingScreenComponent} from '../training-screen/training-screen.component';
import {NgbDropdownModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from '@rinminase/ng-charts';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {StoreModule} from '@ngrx/store';
import {PermissionEffects, permissionsReducer, RoleEffects, rolesReducer} from '../../../core/auth';
import {EffectsModule} from '@ngrx/effects';
import {ChartsEffects} from '../../../core/charts';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MaterialModule} from '../../../material/material.module';
import {CreditsComponent} from './credits.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    NgApexchartsModule,
    RouterModule.forChild([
      {
        path: '',
        component: CreditsComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgxChartsModule,
    ChartsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature('roles', rolesReducer),
    StoreModule.forFeature('permissions', permissionsReducer),
    EffectsModule.forFeature([PermissionEffects, RoleEffects, ChartsEffects]),
    AngularFireAnalyticsModule,
    NgxGoogleAnalyticsModule,
    NgxSliderModule,
    MaterialModule,
  ]
})
export class CreditsModule { }
