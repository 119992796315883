export const EMOJI18 = {
  ['en'] : {
    search: 'Search',
    emojilist: 'List of emoji',
    notfound: 'No Emoji Found',
    clear: 'Clear',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      people: 'Smileys & People',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  },
  ['de'] : {
    search: 'Suche',
    emojilist: 'Liste der Emoji',
    notfound: 'Kein Emoji gefunden',
    clear: 'Klar',
    categories: {
      search: 'Suchergebnisse',
      recent: 'Häufig verwendet',
      people: 'Smileys & Menschen',
      nature: 'Tiere und Natur',
      foods: 'Essen & Trinken',
      activity: 'Tätigkeit',
      places: 'Reisen & Orte',
      objects: 'Objekte',
      symbols: 'Symbole',
      flags: 'Flaggen',
      custom: 'Benutzerdefiniert',
    },
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  }
}
