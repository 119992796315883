import { Injectable } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {take, throttleTime} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../core/auth';
import {FirebaseService} from '../firebase/firebase.service';
import {AppState} from '../../core/reducers';
import {MuscleDataService} from '../muscle-data.service';
import {MUSCLE_CONVERT_FAC} from '../../core/consts/consts';
import {OtherChartAverageService} from '../other-chart-average/other-chart-average.service';

@Injectable({
  providedIn: 'root'
})
export class OtherChartsService {

  userId = '';
  isUserHaveTrainer = false;
  sensorDataInterval;
  sensorDataIntervalStarted = false;

  currentChannelValues = {
    delta: '0',
    theta: '0',
    alpha: '0',
    lowAlpha: '0',
    hiAlpha: '0',
    loBeta: '0',
    betaOne: '0',
    betaTwo: '0',
    hiBeta: '0',
    artefakt: '0',
  }

  amplitudeDatasets = {
    data: [
      [], [], [], [], [], [], [], [], [], []
    ]
  }

  firebaseDataAmplitude = {
    chartType: 'Amplitude',
    delta: {
      data : [],
      currentValue: null,
      index: 0
    },
    theta: {
      data : [],
      currentValue: null,
      index: 1
    },
    loAlpha: {
      data : [],
      currentValue: null,
      index: 2
    },
    alpha: {
      data : [],
      currentValue: null,
      index: 3
    },
    hiAlpha: {
      data : [],
      currentValue: null,
      index: 4
    },
    smrLoBeta: {
      data : [],
      currentValue: null,
      index: 5
    },
    betaOne: {
      data : [],
      currentValue: null,
      index: 6
    },
    betaTwo: {
      data : [],
      currentValue: null,
      index: 7
    },
    hiBeta: {
      data : [],
      currentValue: null,
      index: 8
    },
    artefakt: {
      data : [],
      currentValue: null,
      index: 9
    }
  }

  firebaseDataRatio = {
    chartType: 'Ratio',
    delta: {
      data : [],
      currentValue: null,
      index: 0
    },
    theta: {
      data : [],
      currentValue: null,
      index: 1
    },
    loAlpha: {
      data : [],
      currentValue: null,
      index: 2
    },
    alpha: {
      data : [],
      currentValue: null,
      index: 3
    },
    hiAlpha: {
      data : [],
      currentValue: null,
      index: 4
    },
    smrLoBeta: {
      data : [],
      currentValue: null,
      index: 5
    },
    betaOne: {
      data : [],
      currentValue: null,
      index: 6
    },
    betaTwo: {
      data : [],
      currentValue: null,
      index: 7
    },
    hiBeta: {
      data : [],
      currentValue: null,
      index: 8
    },
    artefakt: {
      data : [],
      currentValue: null,
      index: 9
    }
  }

  sensorData: BehaviorSubject<any> = new BehaviorSubject<any>('');

  sensorDataSubscription: Subscription;
  simulatorDataSubscription: Subscription;

  constructor(
    private firebase: FirebaseService,
    private store: Store<AppState>,
    private muscleData: MuscleDataService,
    private otherChartAverage: OtherChartAverageService,
  ) {}

  checkForUserTrainer() {
    this.firebase.getUidByUserId(this.userId).pipe(take(1)).subscribe((user:any) => {
      if (user[0].trainerId) {
        this.isUserHaveTrainer = true;
        this.getSensorData();
      } else {
        this.isUserHaveTrainer = false;
      }
    });
  }

  getUserId() {
    this.store.pipe(select(currentUser)).subscribe(user => {
      if(user) {
        this.userId = user.id;
        this.checkForUserTrainer();
      }
    });
  }

  getSensorData() {
    if (this.sensorDataSubscription) {
      this.sensorDataSubscription.unsubscribe();
    }
    this.sensorDataSubscription =  this.muscleData.muscleDataSubject.subscribe((data) => {
      if (data && data.data_type_id_text === 'EEG_BP') {
        if (!this.sensorDataIntervalStarted) {
          this.sensorDataIntervalStarted = true;
          this.sensorDataInterval = setInterval(() => {
            this.processSensorDataAmplitude(data.data.bp_ch1);
            this.processSensorDataRatio(data.data.bp_ch1);
            this.sensorDataIntervalStarted = false;
            clearInterval(this.sensorDataInterval);
          },1500);
        }
      }
    })
  }

  processSensorDataAmplitude(dataArray) {
    dataArray.forEach((value, index) => {
      const sensorValue = this.otherChartAverage.amplitudeAverage(+(value * MUSCLE_CONVERT_FAC).toFixed(2), 30, index);
      if (sensorValue) {
        if (index === 0) {
          this.firebaseDataAmplitude.delta.data.push(sensorValue);
          this.firebaseDataAmplitude.delta.currentValue = sensorValue;
        } else if (index === 1) {
          this.firebaseDataAmplitude.theta.data.push(sensorValue);
          this.firebaseDataAmplitude.theta.currentValue = sensorValue;
        } else if (index === 2) {
          this.firebaseDataAmplitude.loAlpha.data.push(sensorValue);
          this.firebaseDataAmplitude.loAlpha.currentValue = sensorValue;
        } else if (index === 3) {
          this.firebaseDataAmplitude.alpha.data.push(sensorValue);
          this.firebaseDataAmplitude.alpha.currentValue = sensorValue;
        } else if (index === 4) {
          this.firebaseDataAmplitude.hiAlpha.data.push(sensorValue);
          this.firebaseDataAmplitude.hiAlpha.currentValue = sensorValue;
        } else if (index === 5) {
          this.firebaseDataAmplitude.smrLoBeta.data.push(sensorValue);
          this.firebaseDataAmplitude.smrLoBeta.currentValue = sensorValue;
        } else if (index === 6) {
          this.firebaseDataAmplitude.betaOne.data.push(sensorValue);
          this.firebaseDataAmplitude.betaOne.currentValue = sensorValue;
        } else if (index === 7) {
          this.firebaseDataAmplitude.betaTwo.data.push(sensorValue);
          this.firebaseDataAmplitude.betaTwo.currentValue = sensorValue;
        } else if (index === 8) {
          this.firebaseDataAmplitude.hiBeta.data.push(sensorValue);
          this.firebaseDataAmplitude.hiBeta.currentValue = sensorValue;
        } else if (index === 9) {
          this.firebaseDataAmplitude.artefakt.data.push(sensorValue);
          this.firebaseDataAmplitude.artefakt.currentValue = sensorValue;
        }
      }
    });
    if (this.firebaseDataAmplitude.delta.data.length > 600) {
      this.firebaseDataAmplitude.delta.data = [];
      this.clearAmplitudeData();
      this.firebase.deleteOtherSessionAmplitude(this.userId, this.firebaseDataAmplitude ,'OtherTraining');
    } else {
      this.firebase.shareOtherSessionAmplitude(this.userId, this.firebaseDataAmplitude ,'OtherTraining');
    }
  };

  processSensorDataRatio(dataArray) {
    dataArray.forEach((value, index) => {
      const sensorValue = this.otherChartAverage.ratioAverage(+(value * MUSCLE_CONVERT_FAC).toFixed(2), 60, index);
      if (sensorValue) {
        if (index === 0) {
          this.firebaseDataRatio.delta.data.push(sensorValue);
        } else if (index === 1) {
          this.firebaseDataRatio.theta.data.push(sensorValue);
        } else if (index === 2) {
          this.firebaseDataRatio.loAlpha.data.push(sensorValue);
        } else if (index === 3) {
          this.firebaseDataRatio.alpha.data.push(sensorValue);
        } else if (index === 4) {
          this.firebaseDataRatio.hiAlpha.data.push(sensorValue);
        } else if (index === 5) {
          this.firebaseDataRatio.smrLoBeta.data.push(sensorValue);
        } else if (index === 6) {
          this.firebaseDataRatio.betaOne.data.push(sensorValue);
        } else if (index === 7) {
          this.firebaseDataRatio.betaTwo.data.push(sensorValue);
        } else if (index === 8) {
          this.firebaseDataRatio.hiBeta.data.push(sensorValue);
        } else if (index === 9) {
          this.firebaseDataRatio.artefakt.data.push(sensorValue);
        }
      }
    });
    if (this.firebaseDataRatio.delta.data.length > 600) {
      this.firebaseDataRatio.delta.data = [];
      this.clearRatioData();
      this.firebase.deleteOtherSessionRatio(this.userId, this.firebaseDataRatio ,'OtherTraining');
    } else {
      this.firebase.shareOtherSessionRatio(this.userId, this.firebaseDataRatio ,'OtherTraining');
    }
  }

  clearRatioData() {
    this.firebaseDataRatio.delta.data = [];
    this.firebaseDataRatio.theta.data = [];
    this.firebaseDataRatio.loAlpha.data = [];
    this.firebaseDataRatio.alpha.data = [];
    this.firebaseDataRatio.hiAlpha.data = [];
    this.firebaseDataRatio.smrLoBeta.data = [];
    this.firebaseDataRatio.betaOne.data = [];
    this.firebaseDataRatio.betaTwo.data = [];
    this.firebaseDataRatio.hiBeta.data = [];
    this.firebaseDataRatio.artefakt.data = [];
  }

  clearAmplitudeData() {
    this.firebaseDataAmplitude.delta.data = [];
  this.firebaseDataAmplitude.theta.data = [];
  this.firebaseDataAmplitude.loAlpha.data = [];
  this.firebaseDataAmplitude.alpha.data = [];
  this.firebaseDataAmplitude.hiAlpha.data = [];
  this.firebaseDataAmplitude.smrLoBeta.data = [];
  this.firebaseDataAmplitude.betaOne.data = [];
  this.firebaseDataAmplitude.betaTwo.data = [];
  this.firebaseDataAmplitude.hiBeta.data = [];
  this.firebaseDataAmplitude.artefakt.data = [];
  }
}
