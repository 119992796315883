<div class="dialog-container">
  <h1>{{ "CHAT_TEMPLATES.HEADER_CONFIRM_DELETE" | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ "CHAT_TEMPLATES.HEADER_CONFIRM_DELETE" | translate }}: "<strong>{{ data.title }}</strong>"?</p>
  </div>
  <div class="buttons-container" mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">{{ "CHAT_TEMPLATES.DELETE" | translate }}</button>
    <button mat-raised-button color="warn" (click)="onCancel()">{{ "CHAT_TEMPLATES.CANCEL" | translate }}</button>
  </div>
</div>
