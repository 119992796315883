// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import {Observable, Subject, Subscription} from 'rxjs';
import {finalize, take, takeUntil, tap} from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import {
  SetLoggedIn,
  UpdateLoginTime,
} from 'src/app/core/auth/_actions/auth.actions';
import { AngularFirestore } from '@angular/fire/firestore';
import {TranslationService} from '../../../../core/_base/layout';
import {FirebaseService} from "../../../../services/firebase/firebase.service";
import {DeviceDetectorService} from "ngx-device-detector";

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
  EMAIL: 'admin@demo.com',
  PASSWORD: 'demo',
};

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  loadingGoogle = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];

  private unsubscribe: Subject<any>;

  private returnUrl: any;
  details: any;
  currentLanguageSubscription: Subscription;
  selectedLanguage = '';
  isIos = false;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private currentLanguage: TranslationService,
    private firebase: FirebaseService
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.initLoginForm();
    this.getLinksLanguage();
    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/';
    });
  }

  getLinksLanguage() {
    this.currentLanguageSubscription = this.currentLanguage.currentLanguage.subscribe((language: string) => {
      this.selectedLanguage = language;
      if (language === 'en') {
        this.afs
          .collection('links')
          .snapshotChanges()
          .subscribe((values) => {
            const obj = {};
            values.forEach((d: any) => {
              obj[d.payload.doc.id] = {
                id: d.payload.doc.id,
                ...d.payload.doc.data(),
              };
            });
            this.details = obj[language];
          });
      } else if (language === 'de') {
        this.afs
          .collection('links')
          .snapshotChanges()
          .subscribe((values) => {
            const obj = {};
            values.forEach((d: any) => {
              obj[d.payload.doc.id] = {
                id: d.payload.doc.id,
                ...d.payload.doc.data(),
              };
            });
            this.details = obj[language];
          });
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
    this.currentLanguageSubscription.unsubscribe();
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    // demo message to show
    if (!this.authNoticeService.onNoticeChanged$.getValue()) {
      const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    }

    this.loginForm = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    };
    this.auth
      .login(authData.email, authData.password)
      .then((user) => {
        if (user) {
          this.store.dispatch(
            new Login({ authToken: user.user.refreshToken, uid: user.user.uid })
          );
          this.store.dispatch(new UpdateLoginTime({ uid: user.user.uid }));
          this.router.navigateByUrl('/profile'); // Main page
        } else {
          this.authNoticeService.setNotice(
            this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
            'danger'
          );
          setTimeout(() => {
            this.authNoticeService.setNotice(null, 'danger');
          }, 5000);
        }
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.EMAIL_INVALID'),
              'danger'
            );
            break;
          case 'auth/user-not-found':
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.USER_NOT_FOUND'),
              'danger'
            );
            break;
          default:
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.PASSWORD_INVALID'),
              'danger'
            );
            break;
        }
        setTimeout(() => {
          this.authNoticeService.setNotice(null, 'danger');
        }, 5000);
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
    this.firebase.getUidByEmail(authData.email).pipe(take(1)).subscribe((user:any) => {
      user = user[0];
      user.EEGeniusUser = true;
      this.firebase.updateUser(user.uid, user);
    });
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  loginWithGoogle() {
    this.auth
      .googleSignin()
      .then((user) => {
        if (user) {
          this.store.dispatch(
            new Login({ authToken: user.user.refreshToken, uid: user.user.uid })
          );
          this.store.dispatch(new UpdateLoginTime({ uid: user.user.uid }));
          this.router.navigateByUrl(this.returnUrl); // Main page
        } else {
          this.authNoticeService.setNotice(
            this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
            'danger'
          );
        }
      })
      .catch((error) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  loginWithApple() {
    this.auth
      .appleSignin()
      .then((user) => {
        if (user) {
          this.store.dispatch(
            new Login({ authToken: user.user.refreshToken, uid: user.user.uid })
          );
          this.store.dispatch(new UpdateLoginTime({ uid: user.user.uid }));
          this.router.navigateByUrl(this.returnUrl); // Main page
        } else {
          this.authNoticeService.setNotice(
            this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
            'danger'
          );
        }
      })
      .catch((error) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  onNavigate(url: string) {
    window.open(url, '_blank');
  }
}
