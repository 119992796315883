import { createAction, props, Action } from '@ngrx/store';

export enum OthersArchiveActionTypes {
  LoadOthersRecordings = '[LoadOthersRecordings] Action',
  SaveOthersRecordings = '[SaveOthersRecordings] Action',
  SetSharedIdorEmail = '[SetSharedIdorEmail] Action',
  SetId = '[SetId] Action',
  SetInputValue = '[SetInputValue] Action',
}

export class LoadOthersRecordings implements Action {
  readonly type = OthersArchiveActionTypes.LoadOthersRecordings;
}

export class SetSharedIdorEmail implements Action {
  readonly type = OthersArchiveActionTypes.SetSharedIdorEmail;
  constructor(public payload: { value: { id: string } }) {}
}

export class SetId implements Action {
  readonly type = OthersArchiveActionTypes.SetId;
  constructor(public payload: { id: string }) {}
}

export class SetInputValue implements Action {
  readonly type = OthersArchiveActionTypes.SetInputValue;
  constructor(public payload: { idorEmail: string }) {}
}

export class SaveOthersRecordings implements Action {
  readonly type = OthersArchiveActionTypes.SaveOthersRecordings;
  constructor(
    public payload: {
      othersRecordings: any[];
      isOthersRecordingsAvailable: boolean;
    }
  ) {}
}

export type OthersArchiveActions =
  | SetId
  | SetInputValue
  | SetSharedIdorEmail
  | LoadOthersRecordings
  | SaveOthersRecordings;
