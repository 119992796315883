// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {Location} from '@angular/common';
// RxJS
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/reducers';
import { SetChartType } from 'src/app/core/charts/_actions/charts.actions';
import { Router } from '@angular/router';
import { LoadRecordings, LoadSharedRecordings } from 'src/app/core/archive/_actions/archive.actions';
import { LoadOthersRecordings } from 'src/app/core/others-archive/_actions/others-archive.actions';
import {NavigationService} from '../../../../../services/navigation/navigation.service';
import {take} from 'rxjs/operators';
import {CurrentRouteService} from '../../../../../services/current-route/current-route.service';
import {IUser} from '../../../../../core/interfaces/user';
import {CurrentUserService} from '../../../../../services/current-user/current-user.service';
import {APP_VERSION} from '../../../../../core/consts/app-version';

@Component({
  selector: 'kt-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fixed = true;
  @Input() clear = false;
  @Input() width = 'fluid';
  @Input() subheaderClasses = '';
  @Input() subheaderContainerClasses = '';
  @Input() displayDesc = false;
  @Input() displayDaterangepicker = true;

  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];
  option = 'Esense Genius';
  appVersion = APP_VERSION;

  // Private properties
  private subscriptions: Subscription[] = [];
  private routeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  page = '';
  currentUser: IUser;

  constructor(
    public subheaderService: SubheaderService,
    private store: Store<AppState>,
    public router: Router,
    public navigation: NavigationService,
    private firebaseUser: CurrentUserService,
    private currentRoute: CurrentRouteService,) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // this.store.dispatch(new SetChartType({ chartType: 'Skin Response' }));
    this.chartTypeChange({value: 'Esense Genius'})
    this.subscribeOnRouteSubject();
    this.getCurrentRoute();
    this.getUser();
  }

  subscribeOnRouteSubject() {
    this.routeSubject.pipe(take(1)).subscribe((route) => {
      this.chartTypeChange({value: `${route}`})
    })
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
      // breadcrumbs title sometimes can be undefined
      if (bt) {
        Promise.resolve(null).then(() => {
          this.title = bt.title;
          this.desc = bt.desc;
        });
      }
    }));

    this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
      });
    }));
  }

  getUser() {
    this.firebaseUser.getCurrentUser().subscribe((user: IUser) => {
      this.currentUser = user;
    });
  }

  getCurrentRoute() {
    this.currentRoute.currentPage.subscribe((page) => {
      this.page = page;
    });
  }

  chartTypeChange(event: any) {
    this.routeSubject.next(event.value)
    if (event.value === 'Esense Genius' && this.router.url === '/live-view') {
      this.router.navigateByUrl('/amplitude-overview');
    } else if (event.value !== 'Esense Genius' && this.checkRouteUrl(this.router.url)) {
      this.router.navigateByUrl('/live-view');
    }

    this.navigation.navigationSubject.next(event.value)
    this.option = event.value;
    this.store.dispatch(new SetChartType({ chartType: event.value }));
    this.store.dispatch(new LoadRecordings({ chartType: event.value }));
    this.store.dispatch(new LoadOthersRecordings());
    this.store.dispatch(new LoadSharedRecordings({ chartType: event.value }));
  }

  checkRouteUrl(url) {
    if(url === '/amplitude-overview') {
      return true
    } else {
      return false;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
