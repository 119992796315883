import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }

  timerView(recordingTime: number, checkForHours = false): string {
    // tslint:disable-next-line:max-line-length
    const seconds = (moment.duration(recordingTime).seconds() < 10) ? '0' + moment.duration(recordingTime).seconds() : moment.duration(recordingTime).seconds();
    // tslint:disable-next-line:max-line-length
    const minutes = (moment.duration(recordingTime).minutes() < 10) ? '0' + moment.duration(recordingTime).minutes() : moment.duration(recordingTime).minutes();
    // tslint:disable-next-line:max-line-length
    const hours = (moment.duration(recordingTime).hours() < 10) ? '0' + moment.duration(recordingTime).hours() : moment.duration(recordingTime).hours();
    if (checkForHours) {
      if (!moment.duration(recordingTime).hours()) {
        return minutes + ':' + seconds;
      }
    }
    return minutes + ':' + seconds;
  }
}
