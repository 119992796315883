import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ChartComponent,
} from "ng-apexcharts";
import { Store, select } from "@ngrx/store";
import { AppState } from "./../../../core/reducers";
import {
  chartsData,
  currentUser,
  chartType,
} from "src/app/core/charts/_selectors/charts.selectors";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireDatabase } from "@angular/fire/database";
import { MediaObserver } from "@angular/flex-layout";
import { SetChartType } from "src/app/core/charts/_actions/charts.actions";
import { take, takeUntil } from "rxjs/operators";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import * as firebase from "firebase/app";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
  selector: "kt-live-view",
  templateUrl: "./live-view.component.html",
  styleUrls: ["./live-view.component.scss"],
})
export class LiveViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public title: ApexTitleSubtitle;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: ApexXAxis;
  public tooltip: ApexTooltip;

  avg = null;
  maxValue = null;
  minValue = null;
  maxDMin = null;
  sign = null;
  time = null;
  currentValue = null;
  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;
  tickInterval = 1;
  sensorType = null;

  multi: any[];
  view: any[] = [1200, 600];

  // options
  legend = false;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = "";
  yAxisLabel = "";
  timeline = true;

  colorScheme = {
    domain: ["#5AA454", "#C1682D", "#C22D2B", "#EEBF4F"],
  };
  @ViewChild("chart", { static: false }) chartComponent: any;
  pulse: any = [];
  respiration: any = [];
  skinResponse: any = [];
  temperature: any = [];
  yScaleMax = null;
  yScaleMin = null;

  // new chart js
  chartData = [{ data: [65, 59, 80, 81, 56, 55, 40], label: "Series A" }];
  chartLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  chartOptions = {
    responsive: true,
  };
  chartColors = [
    {
      borderColor: "black",
      backgroundColor: "rgba(255,0,0,0.3)",
    },
  ];
  chartLegend = true;
  chartPlugins = [];
  name = null;
  isAvailable = false;
  single: { name: string; value: number }[];
  view1: number[];
  breathMin = null;
  calculatedRA = null;
  coherence = null;
  regularity = null;
  direction = true;
  previousValue = 0;
  type = 'Skin Response';

  sliderValue = 90;
  options = {
    floor: 90,
    ceil: 600,
  };

  destroy$: BehaviorSubject<any>;

  // ref: firebase.database.Reference;

  // test() {
  //   this.ref = this.afd.database
  //     .ref(
  //       `users/OjxLfXar8QNy08Ls3lSGmP9kXkS2/Skin Response/sessions/live/data`
  //     )
  //   this.ref.on("child_added", (snapshot) => {
  //     console.log(snapshot.val());
  //   });
  // }

  constructor(
    private store: Store<AppState>,
    private afd: AngularFireDatabase,
    private cd: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    private analytics: AngularFireAnalytics,
    private $gaService: GoogleAnalyticsService
  ) {
    mediaObserver.media$.subscribe((value) => {
      if (value.mqAlias === "md") {
        this.view = [750, 675];
        this.view1 = [200, 600];
      }
      if (value.mqAlias === "sm") {
        this.view = [650, 575];
        this.view1 = [200, 475];
      }
      if (value.mqAlias === "xs") {
        this.view = [320, 240];
        this.view1 = [200, 200];
      }
      if (value.mqAlias === "md" || value.mqAlias === "lg") {
        this.view = [750, 600];
        this.view1 = [200, 500];
      }
    });
  }

  ngOnInit() {
    this.$gaService.event("web_app_live_view");
    this.loadChart();
    // this.test();
  }

  loadChart() {
    let arrowBumper = 0;
    this.store.pipe(select(chartsData)).subscribe(async ({ type, result }) => {
      if (type === 'Esense Genius') {
        this.sensorType = type;
      } else if (type === 'Esense Raw') {
        this.sensorType = type;
      } else if (type === 'Esense Ratio') {
        this.sensorType = type;
      } else {
        this.sensorType = '';
      }
      this.type = type;
      if (type !== "Respiration") {
        const [
          // this.store
          //   .pipe(select(chartsData))
          //   .subscribe(
          //     async ([
          data,
          cchart,
          min,
          max,
          avg,
          maxValue,
          minValue,
          maxDMin,
          sign,
          time,
          currentValue,
        ] = result;

        if (data && data.length > 0) {
          this.isAvailable = true;
        } else {
          this.isAvailable = false;
        }
        this.yScaleMax = null;
        this.yScaleMin = null;
        // other details
        this.avg = avg;
        this.maxValue = maxValue;
        this.minValue = minValue;
        this.maxDMin = maxDMin;
        this.sign = sign;
        this.time = time;
        this.currentValue = currentValue;
        if (arrowBumper === 4) {
          this.direction =
            this.currentValue > this.previousValue ? true : false;
          arrowBumper = 0;
        } else {
          arrowBumper = arrowBumper + 1;
        }
        this.previousValue = this.currentValue;
        this.single = [
          {
            name: "",
            value: currentValue,
          },
        ];
        if (type === "Skin Response") {
          this.colorScheme = {
            domain: ["#5AA454"],
          };
          this.yScaleMax = max;
          this.yScaleMin = min;
        } else if (type === "Temperature") {
          this.colorScheme = {
            domain: ["#C1682D"],
          };
          this.yScaleMax = max;
          this.yScaleMin = min;
        } else if (type === "Pulse") {
          this.colorScheme = {
            domain: ["#C22D2B"],
          };
          this.yScaleMax = max;
          this.yScaleMin = min;
        } else {
          this.colorScheme = {
            domain: ["#EEBF4F"],
          };
          this.yScaleMax = max;
          this.yScaleMin = min;
        }
        const rangedData = this.formatChartData(data, this.sliderValue);
        // console.log('values --->>>>', data);
        // const rangedData = data;
        if (rangedData) {
          const newData = [];
          const newCategories = [];
          const fullData = [];
          for (const d of rangedData) {
            newData.push(d[1]);
            newCategories.push(d[0]);
            fullData.push({
              name: +d[0],
              value: d[1],
            });
          }
          const details = {
            name: type,
            series: fullData,
          };
          this.name = type;
          this.pulse.data = [];
          this.pulse.data.push(details);
          this.pulse.data = [...this.pulse.data];
          this.cd.markForCheck();
        }
      } else {
        const [
          data,
          cchart,
          min,
          max,
          breathMin,
          calculatedRA,
          coherence,
          regularity,
          time,
          currentValue,
        ] = result;

        if (data && data.length > 0) {
          this.isAvailable = true;
        } else {
          this.isAvailable = false;
        }
        this.yScaleMax = null;
        this.yScaleMin = null;
        // other details
        this.breathMin = breathMin;
        this.calculatedRA = calculatedRA;
        this.coherence = coherence;
        this.regularity = regularity;
        this.time = time;
        this.currentValue = currentValue;
        if (arrowBumper === 2) {
          this.direction =
            this.currentValue > this.previousValue ? true : false;
          arrowBumper = 0;
        } else {
          arrowBumper = arrowBumper + 1;
        }
        this.previousValue = this.currentValue;
        this.single = [
          {
            name: "",
            value: currentValue,
          },
        ];
        this.colorScheme = {
          domain: ["#EEBF4F"],
        };
        this.yScaleMax = max;
        this.yScaleMin = min;
        const rangedData = this.formatChartData(data, this.sliderValue);
        // const rangedData = data;
        if (rangedData) {
          const newData = [];
          const newCategories = [];
          const fullData = [];
          for (const d of rangedData) {
            newData.push(d[1]);
            newCategories.push(d[0]);
            fullData.push({
              name: +d[0],
              value: d[1],
            });
          }
          const details = {
            name: type,
            series: fullData,
          };
          this.name = type;
          this.pulse.data = [];
          this.pulse.data.push(details);
          this.pulse.data = [...this.pulse.data];
          this.cd.markForCheck();
        }
      }
    });
  }

  formatChartData(data: any[], range: number = 90) {
    let _data = [];
    if (data && data.length > 0) {
      const last = data[data.length - 1][0];
      _data = data.filter((a) => a[0] > last - range);
    } else {
      _data = data;
    }
    return _data;
  }

  ngAfterViewInit(): void {
    firebase.analytics().logEvent("web-app-live-view");
  }

  ngOnDestroy() {
    // console.log("Location Service Destroyed");
    // this.ref.off();
    // this.destroy$.complete();
  }

  public initChartData() {
    this.chart = {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    };
    this.dataLabels = {
      enabled: false,
    };
    this.markers = {
      size: 0,
    };
    this.title = {
      text: "",
      align: "left",
    };
    this.fill = {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    };
    this.yaxis = {
      labels: {
        formatter: (val) => {
          return val.toFixed(0);
        },
      },
      title: {
        text: "value",
      },
    };
    this.xaxis = {
      type: "numeric",
    };
    this.tooltip = {
      shared: false,
      y: {
        formatter: (val) => {
          return val.toFixed(0);
        },
      },
    };
  }

  xAxisFormatting = (v) => {
    const days = Math.floor(+v / (24 * 60 * 60));
    const daysms = +v % (24 * 60 * 60);
    const hours = Math.floor(daysms / (60 * 60));
    const hoursms = +v % (60 * 60);
    const minutes = Math.floor(hoursms / 60);
    const minutesms = +v % 60;
    const sec = Math.floor(minutesms);
    return (
      (minutes < 10 ? `0${minutes}` : minutes) +
      ":" +
      (sec < 10 ? `0${sec}` : sec)
    );
  };

  xAxisFormattingPulse = (v) => {
    const days = Math.floor(+v / (24 * 60 * 60 * 1000));
    const daysms = +v % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = +v % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = +v % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    return (
      (minutes < 10 ? `0${minutes}` : minutes) +
      ":" +
      (sec < 10 ? `0${sec}` : sec)
    );
  };

  timeFormatting = (v) => {
    const days = Math.floor(+v / (24 * 60 * 60 * 1000));
    const daysms = +v % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = +v % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = +v % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    return (
      (hours < 10 ? `0${hours}` : hours) +
      ":" +
      (minutes < 10 ? `0${minutes}` : minutes) +
      ":" +
      (sec < 10 ? `0${sec}` : sec)
    );
  };

  onSliderChange(event: any) {
    this.loadChart();
  }
}
