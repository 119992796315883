import { Injectable } from '@angular/core';;
import {MatDialog} from '@angular/material/dialog';
import {PhoneWarningComponent} from '../../views/pages/phone-warning/phone-warning.component';

@Injectable({
  providedIn: 'root'
})
export class PhoneWarningService {

  constructor(
    private dialog: MatDialog
  ) { }

  detectDevice() {
    const isiPhone = /iPhone/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
    if (isiPhone || isAndroid) {
      this.dialog.open(PhoneWarningComponent, {
        disableClose: true,
        panelClass: 'phone-warning-dialog'
      });
    }
  }
}
