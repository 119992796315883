import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatSliderModule} from '@angular/material/slider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatBadgeModule} from "@angular/material/badge";
import {MatExpansionModule} from "@angular/material/expansion";

const material = [
  MatInputModule,
  MatSelectModule,
  MatOptionModule,
  MatButtonModule,
  FormsModule,
  MatRadioModule,
  MatIconModule,
  MatSliderModule,
  MatAutocompleteModule,
  MatTableModule,
  MatSortModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  ReactiveFormsModule,
  MatCardModule,
  MatDatepickerModule,
  MatPaginatorModule,
  MatTabsModule,
  MatSlideToggleModule,
  ClipboardModule,
  MatProgressBarModule,
  NgxMatSelectSearchModule,
  MatExpansionModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...material
  ],
  exports: [
    material
  ],
})
export class MaterialModule { }
