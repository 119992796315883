import { Injectable } from '@angular/core';
import {FirebaseService} from '../firebase/firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  constructor(
    private firebase: FirebaseService,
    private firebaseAuth: AngularFireAuth,
  ) { }


  getCurrentUser() {
    return new Observable((observer) => {
      this.firebaseAuth.authState.subscribe( authState => {
        if (authState) {
          this.firebase.getUidByEmail(authState.email).subscribe((user:any) => {
            observer.next(user[0]);
          });
        }
      });
    })
  }
}
