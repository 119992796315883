import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AverageRatioService {

  constructor() { }

  firstRatioArray = [];
  secondRatioArray = [];
  thirdRadioArray = [];

  thetaOnDeltaArray = [];
  thetaOnAlphaArray = [];
  thetaOnLowBetaArray = [];
  thetaOnBetaOneArray = [];
  thetaOnBetaTwoArray = [];
  thetaOnHiBetaArray = [];
  betaOneOnHiBetaArray = [];
  lowBetaOnBetaOneArray = [];
  trainingAverageArray = [];
  trainingBarAverage = [];

  firstRatioAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.firstRatioArray);
  }
  secondRatioAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.secondRatioArray);
  }
  thirdRatioAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thirdRadioArray);
  }
  thetaOnDeltaAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnDeltaArray);
  }
  thetaOnAlphaAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnAlphaArray);
  }
  thetaOnLowBetaAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnLowBetaArray);
  }
  thetaOnBetaOneAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnBetaOneArray);
  }
  thetaOnBetaTwoAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnBetaTwoArray);
  }
  thetaOnHiBetaAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.thetaOnHiBetaArray);
  }
  betaOneOnHiBetaAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.betaOneOnHiBetaArray);
  }
  lowBetaOnBetaOneAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.lowBetaOnBetaOneArray);
  }
  trainingAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.trainingAverageArray);
  }
  trainingBarChartAverage(inputValue: number, maxLength) {
    return this.ratioAverage(inputValue, maxLength, this.trainingBarAverage);
  }

  ratioAverage(inputValue, maxLength, array) {
    if(inputValue) {
      let count = 0;
      if (array.length >= maxLength) {
        array.shift();
      }
      array.push(inputValue);
      for (let i = array.length - 1; i >= 0; i -= 1) {
        if (array[i]) {
          count += array[i];
        }
      }
      const res = count / array.length;
      return res.toFixed(2);
    }
  }

  resetTrainingBarAverage() {
    this.trainingBarAverage = [];
  }

  resetFirstRatio() {
    this.firstRatioArray = [];
  }

  resetSecondRatio() {
    this.secondRatioArray = [];
  }

  resetThirdRatio() {
    this.thirdRadioArray = [];
  }

  resetTrainingAverage() {
    this.trainingAverageArray = [];
  }

  resetArrays() {
    this.firstRatioArray = [];
    this.secondRatioArray = [];
    this.thirdRadioArray = [];
    this.thetaOnDeltaArray = [];
    this.thetaOnAlphaArray = [];
    this.thetaOnLowBetaArray = [];
    this.thetaOnBetaOneArray = [];
    this.thetaOnBetaTwoArray = [];
    this.thetaOnHiBetaArray = [];
    this.betaOneOnHiBetaArray = [];
    this.lowBetaOnBetaOneArray = [];
    this.trainingAverageArray = [];
  }
}
