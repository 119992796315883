import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MuscleDataService} from '../../../services/muscle-data.service';
import {GeniusChartService} from '../../../services/genius-chart/genius-chart.service';
import {CalculateAverageService} from '../../../services/calculateAverage/calculate-average.service';
import {MUSCLE_CMD_WRITE, MUSCLE_CONVERT_FAC, RATIO_CHANNELS} from '../../../core/consts/consts';
import {auditTime} from 'rxjs/operators';
import {interval, Subscription} from 'rxjs';
import {AverageRatioService} from '../../../services/average-ratio/average-ratio.service';
import {SimulatorService} from '../../../services/simulator/simulator.service';
import {RecordService} from '../../../services/record/record.service';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../core/auth';
import {AppState} from '../../../core/reducers';
import {NoteDialogComponent} from '../training-screen/note-dialog/note-dialog/note-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CurrentUserService} from '../../../services/current-user/current-user.service';
import {IUser} from '../../../core/interfaces/user';
import {TourService} from 'ngx-ui-tour-md-menu';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kt-ratio-chart',
  templateUrl: './ratio-chart.component.html',
  styleUrls: ['./ratio-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatioChartComponent implements OnInit, OnDestroy {

  @ViewChild('mainRatioChart', {static: true}) mainRatioChart: any;
  sensorData;
  lineChart;
  dataType: string;
  chartTime = 0.1;
  selectedChannel = 'bp_ch1';
  selectedAverage = 60;
  frequencyOne = 1;
  frequencyTwo = 5;
  frequencyThree = 1;
  frequencyFour = 6;
  frequencyFive = 1;
  frequencySix = 8;
  maxChartZoom = 5;
  zoomStep = 1;
  maxChartSeconds = 60;
  ratioChannels = RATIO_CHANNELS;

  currentChannelValues = {
    delta: 0,
    theta:0,
    alpha: 0,
    lowAlpha: 0,
    hiAlpha: 0,
    loBeta: 0,
    betaOne: 0,
    betaTwo: 0,
    hiBeta: 0,
    artefakt: 0,
  }

  middleChannelValues = {
    delta: 0,
    theta:0,
    alpha: 0,
    lowAlpha: 0,
    hiAlpha: 0,
    loBeta: 0,
    betaOne: 0,
    betaTwo: 0,
    hiBeta: 0,
    artefakt: 0,
  }

  timeArray = [];
  muscleDataSubscription: Subscription;
  dataConditionSubscription: Subscription;
  refreshChartSubscription: Subscription;
  bluetoothServiceSubscription: Subscription;
  recordSubscription: Subscription;
  bluetoothService;
  chartTimeStarted: Date;
  currentChartTime: Date;
  startTimeSaved = false;
  isDataStreaming = false;
  liveSensorData = {
    data : {}
  }

  thetaOnDelta: number;
  thetaOnAlpha: number;
  thetaOnLowBeta: number;
  thetaOnBetaOne: number;
  thetaOnBetaTwo: number;
  thetaOnHiBeta: number;
  betaOneOnHiBeta: number;
  lowBetaOnBetaOne: number;

  averageRatioOne: number;
  averageRatioTwo: number;
  averageRatioThree: number;

  secondsArray = [];
  singleSecond = 0;
  singleValue = 0;
  chartLabelsLength: number;
  test = false;
  dataArray = [
    [],
    [],
    [],
  ];
  datasetInterval = null;
  chartUpdateInterval: number;
  isSimulator = false;
  isNeedRecord = false;
  firebaseIntervalStarted = false;
  firebaseInterval;
  userId = '';
  recordData = {
    recordDate: new Date(),
    demoMode: false,
    chartType: 'MENU.RATIO_CHART',
    note: '',
    delta: {
      data : []
    },
    theta: {
      data : []
    },
    loAlpha: {
      data : []
    },
    alpha: {
      data : []
    },
    hiAlpha: {
      data : []
    },
    smrLoBeta: {
      data : []
    },
    betaOne: {
      data : []
    },
    betaTwo: {
      data : []
    },
    hiBeta: {
      data : []
    },
    artefakt: {
      data : []
    },
    timeArray: [],
    refreshInterval: 200,
    seconds: 0,
    isCompleted: true,
  }
  simulatorDataSubscription: Subscription;
  simulatorDataCondition: Subscription;
  currentUser: IUser;
  currentSensorData = [];
  dataIntervalSubscription: Subscription;
  secondsIntervalSubscription: Subscription;

  constructor(
    private muscleData: MuscleDataService,
    private chartService: GeniusChartService,
    private averageRatio: AverageRatioService,
    private average: CalculateAverageService,
    private simulatorService: SimulatorService,
    private record: RecordService,
    private store: Store<AppState>,
    private amplitudeAverage : CalculateAverageService,
    private firebase: FirebaseService,
    public dialog: MatDialog,
    private tour: TourService,
    private translates: TranslateService,
    private cd: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.getSettingsFromLocalStorage();
    this.createGeniusChart();
    this.getStreamingCondition();
    this.getSensorData();
    this.subscribeToBluetoothService();
    this.subscribeOnSimulatorMode();
    this.getRecordCondition();
    this.getUserId();
  }

  ngOnDestroy(): void {
    this.startTimeSaved = true;
    this.dataConditionSubscription.unsubscribe();
    this.muscleDataSubscription.unsubscribe();
    this.nullifyChart();
    this.mainRatioChart.destroy();
    this.averageRatio.resetArrays();
    this.mainRatioChart.data.labels = [];
    clearInterval(this.datasetInterval);
    if (this.recordSubscription) {
      this.recordSubscription.unsubscribe();
    }
    clearInterval(this.firebaseInterval);
    if(this.mainRatioChart) {
      this.mainRatioChart.destroy();
    }
    if(this.simulatorDataSubscription) {
      this.simulatorDataSubscription.unsubscribe();
    }
    if(this.simulatorDataCondition) {
      this.simulatorDataCondition.unsubscribe();
    }
    this.tour.end();
    if (this.dataIntervalSubscription) {
      this.dataIntervalSubscription.unsubscribe();
    }
    if (this.secondsIntervalSubscription) {
      this.secondsIntervalSubscription.unsubscribe();
    }
    this.startTimeSaved = false;
  }

  getUserId() {
    this.store.pipe(select(currentUser)).subscribe(user => {
      if(user) {
        this.currentUser = user;
        this.userId = user.id;
        this.cd.markForCheck();
        if (user.showCoachMarks) {
          this.showCoachMarks();
        }
      }
    });
  }

  showCoachMarks() {
    this.tour.initialize([
      {
        anchorId: 'channel-choose',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.CHANNEL_TEXT')}`,
      },
      {
        anchorId: 'y-asis',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.Y_AXIS_TEXT')}`,
      },
      {
        anchorId: 'x-length',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.SECONDS_TEXT')}`,
      },
      {
        anchorId: 'average-presets',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.AVERAGE_TEXT')}`,
      },
      {
        anchorId: 'selected-average',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.AVERAGE_PRESETS')}`,
      },
      {
        anchorId: 'zoom-step',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.ZOOM_STEP_TEXT')}`,
      },
      {
        anchorId: 'line-chart',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.CENTER_CHART_TEXT')}`,
      },
      {
        anchorId: 'chart-zoom',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.ZOOM_BUTTONS')}`,
      },
      {
        anchorId: 'global-averages-container',
        content: `${this.translates.instant('SUPERVISOR_RATIO_COACHMARKS.GLOBAL_AVERAGES_TEXT')}`,
      },
    ]);
    this.tour.steps.forEach((step) => {
      step.nextBtnTitle = `${this.translates.instant('COACHMARKS_BUTTONS.NEXT')}`;
      step.prevBtnTitle = `${this.translates.instant('COACHMARKS_BUTTONS.PREV')}`
      step.enableBackdrop = true
    });
    setTimeout(() => {
      this.tour.start();
    },1000);
  }

  subscribeOnSimulatorMode() {
    this.simulatorDataCondition = this.simulatorService.isSimulatorMode.subscribe((isSimulator) => {
      // @ts-ignore
      this.isSimulator = isSimulator;
      if (this.isSimulator) {
        this.processSimulatorData();
      }
    })
  }

  processSimulatorData() {
    this.simulatorDataSubscription = this.simulatorService.simulatorData.subscribe((dataArray) => {
      if(dataArray.length) {
        this.currentSensorData = dataArray;
      }
      this.processSensorDataSimulator(dataArray);
    })
  }

  processSensorDataSimulator(dataArray) {
    this.selectedChannel = 'bp_ch1';
    // @ts-ignore
    this.liveSensorData.data.bp_ch1 = dataArray;
    this.calculateFirstRatio();
    this.calculateSecondRatio();
    this.calculateThirdRatio();
    this.calculateAllAverages();
  }

  async sendDataToActivateEEGmode(bluetoothService) {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x45,ON)`).buffer;
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  subscribeToBluetoothService(){
    this.bluetoothServiceSubscription = this.muscleData.bluetoothServiceSubject.subscribe((service) =>{
      if(service) {
        this.bluetoothService = service;
      }
    })
  }

  getStreamingCondition() {
    this.dataConditionSubscription = this.muscleData.dataStreamingSubject.subscribe((dataCondition) => {
      if (dataCondition) {
        this.isDataStreaming = dataCondition;
      } else {
        if(this.refreshChartSubscription) {
          this.refreshChartSubscription.unsubscribe();
        }
      }
    });
  }

  getRecordCondition() {
    this.recordSubscription = this.record.isNeedRecord.subscribe((recordCondition) => {
        this.isNeedRecord = recordCondition;
      if (this.isNeedRecord) {
        const dataInterval = interval(200);
        this.dataIntervalSubscription = dataInterval.subscribe(() => {
          this.processSensorDataToFirebase(this.currentSensorData);
        });
        const secondsInterval = interval(1000);
        this.secondsIntervalSubscription = secondsInterval.subscribe(() => {
          this.recordData.seconds++
        })
      } else {
        if (this.dataIntervalSubscription) {
          this.dataIntervalSubscription.unsubscribe();
        }
        if (this.secondsIntervalSubscription) {
          this.secondsIntervalSubscription.unsubscribe();
        }
      }
    });
    this.record.stopRecording.subscribe((condition) => {
      if (this.mainRatioChart.data.datasets[0].data.length > 0 && condition) {
        this.isNeedRecord = false;
        this.dialog.open(NoteDialogComponent, {data: this.recordData}).afterClosed().subscribe((note) => {
          if (note) {
            this.recordData.note = note;
          }
          this.firebase.saveSession(this.userId, this.recordData, 'EEG');
          // const timeout = setTimeout(() => {
          //   this.recordData.seconds = 0;
          //   this.nullifyFirebaseData();
          //   clearTimeout(timeout);
          // },3000)
        })
      }
    })
  }

  getSensorData(): void {
    this.muscleDataSubscription = this.muscleData.muscleDataSubject.pipe(auditTime(0)).subscribe(async (data) => {
      if (data && data.data[this.selectedChannel]) {
        this.currentChartTime = new Date();
        this.dataType = data.data_type_id_text;
        if(this.dataType === 'EEG_BP') {
          this.liveSensorData = data;
          this.currentSensorData = data.data[this.selectedChannel];
          this.calculateFirstRatio();
          this.calculateSecondRatio();
          this.calculateThirdRatio();
          this.calculateAllAverages();
        }
        if (!this.startTimeSaved && this.isDataStreaming) {
          this.startTimeSaved = true;
          await this.sendDataToActivateEEGmode(this.bluetoothService);
        }
      } else {
        this.isDataStreaming = false;
      }
    })
  }

  processSensorDataToFirebase(dataArray) {
    if (!dataArray) {
      return;
    }
    if (this.isSimulator) {
      this.recordData.demoMode = true;
      this.recordData.delta.data.push(Number((dataArray[0])));
      this.recordData.theta.data.push(Number((dataArray[1])));
      this.recordData.loAlpha.data.push(Number((dataArray[2])));
      this.recordData.alpha.data.push(Number((dataArray[3])));
      this.recordData.hiAlpha.data.push(Number((dataArray[4])));
      this.recordData.smrLoBeta.data.push(Number((dataArray[5])));
      this.recordData.betaOne.data.push(Number((dataArray[6])));
      this.recordData.betaTwo.data.push(Number((dataArray[7])));
      this.recordData.hiBeta.data.push(Number((dataArray[8])));
      this.recordData.artefakt.data.push(Number((dataArray[9])));
    } else {
      this.recordData.demoMode = false;
      this.recordData.delta.data.push(Number((dataArray[0] * MUSCLE_CONVERT_FAC)));
      this.recordData.theta.data.push(Number((dataArray[1] * MUSCLE_CONVERT_FAC)));
      this.recordData.loAlpha.data.push(Number((dataArray[2] * MUSCLE_CONVERT_FAC)));
      this.recordData.alpha.data.push(Number((dataArray[3] * MUSCLE_CONVERT_FAC)));
      this.recordData.hiAlpha.data.push(Number((dataArray[4] * MUSCLE_CONVERT_FAC)));
      this.recordData.smrLoBeta.data.push(Number((dataArray[5] * MUSCLE_CONVERT_FAC)));
      this.recordData.betaOne.data.push(Number((dataArray[6] * MUSCLE_CONVERT_FAC)));
      this.recordData.betaTwo.data.push(Number((dataArray[7] * MUSCLE_CONVERT_FAC)));
      this.recordData.hiBeta.data.push(Number((dataArray[8] * MUSCLE_CONVERT_FAC)));
      this.recordData.artefakt.data.push(Number((dataArray[9] * MUSCLE_CONVERT_FAC)));
    }
  }

  createGeniusChart() {
    let stepSize;
    if (this.maxChartSeconds <= 60) {
      stepSize = 1;
    } else if (this.maxChartSeconds >= 60) {
      stepSize = 10;
    }
    const lineChartCanvas = document.getElementById('mainLineChart');
    this.mainRatioChart = this.chartService.createRatioChart(lineChartCanvas, stepSize, this.maxChartZoom);
    this.createChartLabels(this.mainRatioChart);
  }

  createChartLabels(chart) {
    clearInterval(this.datasetInterval);
    this.datasetInterval = null;
    let divider;
    this.secondsArray = [];
    this.singleSecond = 0;
    this.singleValue = 0;
    if (this.maxChartSeconds <= 60) {
      divider = 10;
      this.chartUpdateInterval = 100;
    }
    if (this.maxChartSeconds > 60) {
      divider = 5;
      this.chartUpdateInterval = 200;
    }
    this.chartLabelsLength = divider * this.maxChartSeconds;
    this.singleValue = this.maxChartSeconds / this.chartLabelsLength;
    for (let i = 0; i < this.chartLabelsLength; i++) {
      this.singleSecond += this.singleValue;
      this.secondsArray.push(this.singleSecond.toFixed(2));
    }
    chart.data.labels = this.secondsArray;
    chart.update();
    this.cd.markForCheck();
    this.datasetsInterval();
  }

  changeMovingAverage($event) {
    this.selectedAverage = +$event.target.value;
    this.averageRatio.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  changeLongAveraging($event) {
    this.selectedAverage = +$event.value;
    this.averageRatio.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  resetChartArrayData() {
    this.timeArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.mainRatioChart.data.datasets.length; i++) {
      this.mainRatioChart.data.datasets[i].data = [];
    }
    this.mainRatioChart.update();
    this.cd.markForCheck()
  }

  calculateFirstRatio() {
    const channelDataOne = this.liveSensorData.data[this.selectedChannel][this.frequencyOne];
    const channelDataTwo = this.liveSensorData.data[this.selectedChannel][this.frequencyTwo];
    const averageChannels = (channelDataOne / channelDataTwo);
    if (this.liveSensorData && this.liveSensorData.data[this.selectedChannel]) {
      const average = +this.averageRatio.firstRatioAverage(averageChannels, this.selectedAverage * 25);
      this.averageRatioOne = average;
      if (this.liveSensorData.data[this.selectedChannel] && average) {
        this.dataArray[0].push(+average);
      }
    }
  }


  calculateSecondRatio() {
    const channelDataOne = this.liveSensorData.data[this.selectedChannel][this.frequencyThree];
    const channelDataTwo = this.liveSensorData.data[this.selectedChannel][this.frequencyFour];
    const averageChannels = (channelDataOne / channelDataTwo);
    if (this.liveSensorData && this.liveSensorData.data[this.selectedChannel]) {
      const average = this.averageRatio.secondRatioAverage(averageChannels, this.selectedAverage * 25);
      this.averageRatioTwo = +average;
      if (this.liveSensorData.data[this.selectedChannel] && average) {
        this.dataArray[1].push(+average);
      }
    }
  }

  calculateThirdRatio() {
    const channelDataOne = this.liveSensorData.data[this.selectedChannel][this.frequencyFive];
    const channelDataTwo = this.liveSensorData.data[this.selectedChannel][this.frequencySix];
    const averageChannels = (channelDataOne / channelDataTwo);
    if (this.liveSensorData && this.liveSensorData.data[this.selectedChannel]) {
      const average = this.averageRatio.thirdRatioAverage(averageChannels, this.selectedAverage * 25);
      this.averageRatioThree = +average;
      if (this.liveSensorData.data[this.selectedChannel] && average) {
        this.dataArray[2].push(+average);
      }
    }
  }

  datasetsInterval() {
    if (this.datasetInterval) {
      return;
    }

    this.datasetInterval = setInterval(() => {
      if (this.mainRatioChart.data.datasets[0].data.length >= this.chartLabelsLength) {
        this.mainRatioChart.data.datasets[0].data.length = this.chartLabelsLength;
        this.dataArray.forEach((values, index) => {
          let sum = 0;
          values.forEach((value) => {
            sum += +value;
          });
          const avg = sum / values.length;
          if (avg) {
            // this.mainRatioChart.data.datasets[index].data.pop();
            // this.mainRatioChart.data.datasets[index].data.unshift(avg);
            this.mainRatioChart.data.datasets[index].data.shift();
            this.mainRatioChart.data.datasets[index].data.push(avg);
          }
        })
      }
      if (this.mainRatioChart.data.datasets[0].data.length < this.chartLabelsLength) {
        this.dataArray.forEach((values, index) => {
          if (values.length > 0) {
            let sum = 0;
            values.forEach((value) => {
              sum += +value;
            });
            const avg = sum / values.length;
            if (avg) {
              this.mainRatioChart.data.datasets[index].data.push(avg);
            }
          }
        })
      }
      if (this.mainRatioChart.data.datasets[0].data.length > 0) {
        this.clearDataArray();
        this.mainRatioChart.update();
        this.cd.markForCheck();
      }
    }, this.chartUpdateInterval);
  }

  changeChartUpdateInterval($event) {
    if($event.target.value > 600 ) {
      this.maxChartSeconds = 600;
      this.saveSettingsToLocalStorage();
      setTimeout(() => {
        this.nullifyChart();
        this.createGeniusChart();
      },1000)
      this.saveSettingsToLocalStorage();
      this.cd.markForCheck();
      return;
    }
    this.maxChartSeconds = +$event.target.value;
    if(this.refreshChartSubscription) {
      this.refreshChartSubscription.unsubscribe();
    }
    setTimeout(() => {
      this.nullifyChart();
      this.createGeniusChart();
    },1000)
    this.saveSettingsToLocalStorage();
  }

  resetFirstRatio() {
    this.averageRatio.resetFirstRatio();
  }

  resetSecondRatio() {
    this.averageRatio.resetSecondRatio();
  }

  resetThirdRatio() {
    this.averageRatio.resetThirdRatio();
  }

  calculateAllAverages() {
    const channeltheta = this.liveSensorData.data[this.selectedChannel][1];
    const channelDelta = this.liveSensorData.data[this.selectedChannel][0];
    this.thetaOnDelta = +this.averageRatio.thetaOnDeltaAverage((channeltheta / channelDelta), this.selectedAverage * 25);

    const channelAlpha = this.liveSensorData.data[this.selectedChannel][2];
    this.thetaOnAlpha = +this.averageRatio.thetaOnAlphaAverage((channeltheta / channelAlpha), this.selectedAverage * 25);

    const chanelLowBeta = this.liveSensorData.data[this.selectedChannel][5];
    this.thetaOnLowBeta = +this.averageRatio.thetaOnLowBetaAverage((channeltheta / chanelLowBeta), this.selectedAverage * 25);

    const channelBetaOne = this.liveSensorData.data[this.selectedChannel][6];
    this.thetaOnBetaOne = +this.averageRatio.thetaOnBetaOneAverage((channeltheta / channelBetaOne), this.selectedAverage * 25);

    const channelBetaTwo = this.liveSensorData.data[this.selectedChannel][7];
    this.thetaOnBetaTwo = +this.averageRatio.thetaOnBetaTwoAverage((channeltheta / channelBetaTwo), this.selectedAverage * 25);

    const channelHiBeta = this.liveSensorData.data[this.selectedChannel][8];
    this.thetaOnHiBeta = +this.averageRatio.thetaOnHiBetaAverage((channeltheta / channelHiBeta), this.selectedAverage * 25);

    this.betaOneOnHiBeta = +this.averageRatio.betaOneOnHiBetaAverage((channelBetaOne / channelHiBeta), this.selectedAverage * 25);

    this.lowBetaOnBetaOne = +this.averageRatio.lowBetaOnBetaOneAverage((chanelLowBeta / channelBetaOne), this.selectedAverage * 25);
  }

  disableSelectedRatio(index: number, condition: boolean) {
    condition = !condition
    this.mainRatioChart.data.datasets[index].hidden = condition;
    this.mainRatioChart.update();
    this.saveSettingsToLocalStorage();
  }
  changeChartZoomLevel($event) {
    if ($event.target.value < 0) {
      $event.target.value = 0;
      return;
    }
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = +$event.target.value;
    this.mainRatioChart.update();
    this.cd.markForCheck();
    this.saveSettingsToLocalStorage();
  }

  changeChannel($event) {
    this.selectedChannel = $event.value;
    // this.selectedChannel =
    this.averageRatio.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  zoomInChart() {
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = this.mainRatioChart.options.scales.yAxes[0].ticks.max + +this.zoomStep;
    this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
    this.mainRatioChart.update();
    this.saveSettingsToLocalStorage();
  }

  zoomOutChart() {
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = this.mainRatioChart.options.scales.yAxes[0].ticks.max - +this.zoomStep;
    this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
    this.mainRatioChart.update();
    this.saveSettingsToLocalStorage();
  }

  getSettingsFromLocalStorage() {
    if (localStorage.length > 0 && localStorage.getItem('ratioSettings')) {
      const settings = JSON.parse(localStorage.getItem('ratioSettings'));
      this.selectedChannel = settings.selectedChannel;
      this.maxChartZoom = settings.maxChartZoom;
      this.maxChartSeconds = settings.maxChartSeconds;
      this.selectedAverage = settings.selectedAverage;
      this.zoomStep = settings.zoomStep;

      setTimeout(() => {
        this.mainRatioChart.options.scales.yAxes[0].ticks.max = +settings.maxChartZoom;
        this.mainRatioChart.update();
        this.cd.markForCheck();
      },100)
    }
  }

  saveSettingsToLocalStorage() {
    const settings = JSON.parse(localStorage.getItem('ratioSettings'));
    if (settings) {
      localStorage.removeItem('ratioSettings');
    }
    const ratioSettings = {
      selectedChannel: this.selectedChannel,
      maxChartZoom: this.maxChartZoom,
      maxChartSeconds: this.maxChartSeconds,
      selectedAverage: this.selectedAverage,
      zoomStep: this.zoomStep,
    }
    localStorage.setItem('ratioSettings', JSON.stringify(ratioSettings));
  }

  clearDataArray() {
    this.dataArray = [[], [], []];
  }

  nullifyChart() {
    this.timeArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.mainRatioChart.data.datasets.length; i++) {
      this.mainRatioChart.data.datasets[i].data = [];
    }
  }
  nullifyFirebaseData() {
    this.recordData.delta.data = [];
    this.recordData.theta.data = [];
    this.recordData.loAlpha.data = [];
    this.recordData.alpha.data = [];
    this.recordData.hiAlpha.data = [];
    this.recordData.smrLoBeta.data = [];
    this.recordData.betaOne.data = [];
    this.recordData.betaTwo.data = [];
    this.recordData.hiBeta.data = [];
    this.recordData.artefakt.data = [];
    this.recordData.timeArray = [];
  }
}

