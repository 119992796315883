<kt-splash-screen></kt-splash-screen>
<tour-step-template>
  <ng-template let-step="step">
    <mat-card (click)="$event.stopPropagation()">
      <mat-card-title style="text-align: right">
        <div class="title-text">{{step?.title}}</div>
        <mat-icon style="cursor: pointer" class="title-close" (click)="tourService.end()">close</mat-icon>
      </mat-card-title>

      <mat-card-content [innerHTML]="step?.content"></mat-card-content>

      <mat-card-actions align="end" style="align-items: baseline;">
        <button
          mat-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          <mat-icon>chevron_left</mat-icon> {{step?.prevBtnTitle}}
        </button>
        <button
          mat-button
          class="next"
          *ngIf="tourService.hasNext(step)"
          (click)="tourService.next()"
        >
          {{step?.nextBtnTitle}} <mat-icon>chevron_right</mat-icon>
        </button>
        <button
          mat-button
          (click)="tourService.end();endTourProfile(step)"
          *ngIf="!tourService.hasNext(step)"
        >
          {{step?.endBtnTitle}}
        </button>
        <mat-checkbox [(ngModel)]="showProfileTourAgain" (change)="triggerProfileTourEnd($event)" color="primary" *ngIf="lastTourStep">{{ 'COACHMARKS_BUTTONS.SHOW_AGAIN' | translate}}</mat-checkbox>
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
<router-outlet></router-outlet>
