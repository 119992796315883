// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { AngularFirestoreModule } from '@angular/fire/firestore';;
import { ChartsModule } from '@rinminase/ng-charts';


// ngrx imports
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { archiveReducer } from 'src/app/core/archive/_reducers/archive.reducer';
import { ArchiveEffects } from 'src/app/core/archive/_effects/archieve.effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import {MaterialModule} from '../../material/material.module';
import {PortalModule} from '@angular/cdk/portal';
import { CreditsComponent } from './credits/credits.component';
import {CreditsModule} from './credits/credits.module';
import {TrainingScreenModule} from './training-screen/training-screen.module';
import {TrainingScreenComponent} from './training-screen/training-screen.component';
import {LiveViewModule} from './live-view/live-view.module';
import {YoutubeVideoComponent} from './youtube-video/youtube-video.component';
import {AnalysisComponent} from './analysis/analysis.component';
import {TestScreenComponent} from './test-screen/test-screen.component';
import {TestConfigurationComponent} from './test-configuration/test-configuration.component';
import {TrainingConfigurationComponent} from './training-configuration/training-configuration.component';
import {CustomTrainingScreenComponent} from './custom-training-screen/custom-training-screen/custom-training-screen.component';
import {
  CustomTrainingChartComponent
} from './custom-training-screen/custom-training-screen/custom-training-chart/custom-training-chart.component';
import {RecordingsComponent} from './recordings/recordings.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { StatisticsComponent } from './statistics/statistics.component';


import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import {SupervisorsTreeComponent} from "./supervisors-tree/supervisors-tree.component";
import {SupervisorActivityComponent} from "./supervisor-activity/supervisor-activity.component";
import { PhoneWarningComponent } from './phone-warning/phone-warning.component';
import { DeviceOverviewComponent } from './device-overview/device-overview.component';
import {MatDialogModule} from "@angular/material/dialog";
import {UnregisterDeviceDialogComponent} from "./device-overview/unregister-device-dialog/unregister-device-dialog.component";
@NgModule({
  declarations: [
    CreditsComponent,
    TrainingScreenComponent,
    YoutubeVideoComponent,
    AnalysisComponent,
    TestScreenComponent,
    TestConfigurationComponent,
    TrainingConfigurationComponent,
    CustomTrainingScreenComponent,
    CustomTrainingChartComponent,
    RecordingsComponent,
    StatisticsComponent,
    SupervisorsTreeComponent,
    SupervisorActivityComponent,
    PhoneWarningComponent,
    DeviceOverviewComponent,
    UnregisterDeviceDialogComponent
  ],
  exports: [
    YoutubeVideoComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    ComponentsModule,
    AngularFirestoreModule,
    ChartsModule,
    StoreModule.forFeature('archive', archiveReducer),
    EffectsModule.forFeature([ArchiveEffects]),
    NgxPermissionsModule.forChild(),
    TranslateModule.forChild(),
    MaterialModule,
    PortalModule,
    CreditsModule,
    TrainingScreenModule,
    LiveViewModule,
    YouTubePlayerModule,
    IvyCarouselModule,
    PortalModule,
    NgbTooltipModule,
    TourMatMenuModule,
    MatDialogModule
  ],
  providers: []
})
export class PagesModule {
}
