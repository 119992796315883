import { createAction, props, Action } from '@ngrx/store';

export enum ChartsActionTypes {
  LoadCharts = '[LoadCharts] Action',
  SetChartType = '[SetChartType] Action',
  AddCustomChart = '[AddCustomChart] Action',
  AddCustomChart2 = '[AddCustomChart2] Action'
}

export const loadChartsSuccess = createAction(
  '[Charts] Load Chartss Success',
  props<{ data: any }>()
);

export const loadChartsFailure = createAction(
  '[Charts] Load Chartss Failure',
  props<{ error: any }>()
);

export class LoadCharts implements Action {
  readonly type = ChartsActionTypes.LoadCharts;
  constructor(public payload: { data: any[], isDataAvailable: boolean }) { }
}

export class SetChartType implements Action {
  readonly type = ChartsActionTypes.SetChartType;
  constructor(public payload: { chartType: string }) { }
}

export class AddCustomChart implements Action {
  readonly type = ChartsActionTypes.AddCustomChart;
  constructor(public payload: { id: string, type: string }) { }
}

export class AddCustomChart2 implements Action {
  readonly type = ChartsActionTypes.AddCustomChart2;
  constructor(public payload: { id?: string, email?: string, type: string }) { }
}

export type ChartsActions = LoadCharts | SetChartType | AddCustomChart | AddCustomChart2;