import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kt-bind-sensor',
  templateUrl: './bind-sensor.component.html',
  styleUrls: ['./bind-sensor.component.scss']
})
export class BindSensorComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BindSensorComponent>
  ) { }

  ngOnInit(): void {
  }
  confirm() {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close(false);
  }

}
