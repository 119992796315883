import { Injectable } from '@angular/core';
import {TranslationService} from "../../core/_base/layout";

@Injectable({
  providedIn: 'root'
})
export class TemplateTranslateService {

  constructor() { }

  getTemplateTranslation(language, template, character) {
    if (language === 'en') {
      return template[character].split('${en}')[1];
    } else if (language === 'de') {
      let germanName = template[character].substring(template[character].indexOf('${de}') + 1, template[character].lastIndexOf('${en}'));
      germanName.split('${de}');
      germanName = germanName.replace('{de}', '');
      return germanName;
    } else {
      return template[character].split('${en}')[1];
    }
  }
}
