import { Pipe, PipeTransform } from '@angular/core';
import {TranslationService} from '../core/_base/layout';
import {TemplateTranslateService} from '../services/template-translate/template-translate.service';

@Pipe({
  name: 'templateTranslate',
})
export class TemplateTranslatePipe implements PipeTransform {

  constructor(
    private translates: TranslationService,
    private templateTranslate: TemplateTranslateService,
  ) {}

  transform(item: any, ...args: unknown[]): unknown {
    if (item.name) {
      if (item.name.includes('${de}') || item.name.includes('${en}')) {
        return this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), item, 'name');
      } else {
        return item.name;
      }
    }
    if (item.note) {
      if (item.note.includes('${de}') || item.note.includes('${en}')) {
        return this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), item, 'note');
      } else {
        return item.note;
      }
    }
  }

}
