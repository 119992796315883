import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {

  currentPage: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor() { }
}
