<div class="main-container">
  <div class="template-container">
<!--    <div class="template-preview" *ngIf="!running">-->
<!--      <div class="template-heading">-->
<!--        <h2>{{selectedTemplate.name}}</h2>-->
<!--      </div>-->
<!--      <div class="template-data" *ngFor="let item of selectedTemplate.data">-->
<!--        <div class="image-container">-->
<!--          <img [src]="item.imgSrc" alt="icon">-->
<!--        </div>-->
<!--        <div class="selected-option">-->
<!--          {{item.selectedOption | translate}} : &nbsp;-->
<!--        </div>-->
<!--        <div class="training-protocol">-->
<!--          <div class="ratio-protocol" *ngIf="item.trainingProtocol === 'ratio'">-->
<!--            <p class="protocol-type">{{item.trainingProtocol}}</p>-->
<!--            <div class="ratio-channels">-->
<!--              <p>( {{getRatioName(item.frequencyOne)}}</p>-->
<!--              <span class="spacer"> / </span>-->
<!--              <p>{{getRatioName(item.frequencyTwo)}} )</p>-->
<!--              <span class="spacer"></span>-->
<!--              <p class="threshHoldType">, {{getThresholdTranslate(item.threshHoldType)}}</p>-->
<!--              <p class="time"> - {{item.length}} min</p>-->
<!--              <img class="youtube-img" *ngIf="item.youtubePlaylistUrl" src="./assets/images/youtube.png" alt="youtube">-->
<!--              <span class="material-icons note" *ngIf="item.note">-->
<!--            description-->
<!--            </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="amplitude-protocol" *ngIf="item.trainingProtocol === 'amplitude'">-->
<!--            <p class="protocol-type">{{item.trainingProtocol}}</p>-->
<!--            <p class="channel">{{getRatioName(item.frequencyOne)}}</p>-->
<!--            <p class="threshHoldType">, {{getThresholdTranslate(item.threshHoldType)}}</p>-->
<!--            <p class="time"> - {{item.length}} min</p>-->
<!--            <img class="youtube-img" *ngIf="item.youtubePlaylistUrl" src="./assets/images/youtube.png" alt="youtube">-->
<!--            <span class="material-icons note" *ngIf="item.note">-->
<!--            description-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="training-running" *ngIf="currentTemplate">
    <div class="training-description">
      <h2 class="training-name">{{getTemplateTitle(templateForTraining, 'name')}}</h2>
      <p>{{getTemplateTitle(currentTemplate, 'customText')}}</p>
    </div>
    <div class="training-note" *ngIf="currentTemplate.note">
      <p>{{getCurrentTemplateNoteTranslation(currentTemplate)}}</p>
    </div>
  </div>
  <div class="timer-container">
    <p>{{'TRAINER_TEMPLATE.TRAINING_PART' | translate}} {{displayTimer}}</p>
    <p>{{'TRAINER_TEMPLATE.TOTAL_TIME' | translate}} {{totalDisplayTimer}}</p>
  </div>
  <div class="timer-actions">
<!--    <button mat-raised-button *ngIf="userRole === 'supervisor'" (click)="startTraining(selectedTemplate.data)" [disabled]="running">{{'TRAINER_TEMPLATE.START' | translate}}</button>-->
<!--    <button mat-raised-button color="warn" *ngIf="userRole === 'supervisor'" [disabled]="!running" (click)="stopTraining()">{{'TRAINER_TEMPLATE.STOP' | translate}}</button>-->
    <button mat-raised-button *ngIf="!trainingEnded" (click)="startTraining(selectedTemplate.data, true)" [disabled]="running">{{'TRAINER_TEMPLATE.START' | translate}}</button>
    <button mat-raised-button *ngIf="trainingEnded" (click)="repeatTraining(templateForTraining)" [disabled]="running">{{'TRAINER_TEMPLATE.START' | translate}}</button>
    <button mat-raised-button class="pause" color="accent" [disabled]="!running" (click)="pauseTraining(true)">Pause</button>
    <button mat-raised-button color="warn" [disabled]="!running" (click)="stopTraining(true)">{{'TRAINER_TEMPLATE.STOP' | translate}}</button>
  </div>
  <div class="threshold-note" *ngIf="showNoteText">
    <p>{{'TRAINER_TEMPLATE.NOTE_TEXT' | translate}}</p>
  </div>
</div>

<div class="pause-backdrop" *ngIf="trainingPaused">
  <div class="backdrop-content">
    <h3 *ngIf="previousErrorCount > 0">{{'TRAINER_TEMPLATE.PAUSE_BY_ERRORS' | translate}}</h3>
    <h1>{{'TRAINER_TEMPLATE.PAUSED' | translate}}</h1>
    <button mat-raised-button [ngClass]="pausedByApp ? 'hide-pause-btn' : 'show-pause-btn'" (click)="startTraining(selectedTemplate.data, true, true)" [disabled]="running">{{'TRAINER_TEMPLATE.RESUME' | translate}}</button>
  </div>
</div>

<div class="bad-artefact-wrap" *ngIf="showArtefaktWarning">
  <mat-icon class="material-symbols-outlined">
warning
</mat-icon>
  <h1>{{'BAD_ARTEFAKT.HEADING' | translate}}</h1>
  <div class="hints">
    <h3 class="good-signal">{{'BAD_ARTEFAKT.ARTEFAKT_FIRST_PART' | translate}}</h3>
    <h3 class="current-signal">{{'BAD_ARTEFAKT.ARTEFAKT_SECOND_PART' | translate}}</h3>
    <span [ngClass]="artefaktValue > 6 ? 'bad-signal' : 'good-signal'">{{artefaktValue}}</span>
  </div>
  <div class="hints">
    <h3 class="good-signal">{{'BAD_ARTEFAKT.IMPEDANCE_FIRST_PART' | translate}}</h3>
    <h3 class="current-signal">{{'BAD_ARTEFAKT.IMPEDANCE_SECOND_PART' | translate}}</h3>
    <span [ngClass]="impedanceValue > 20 ? 'bad-signal' : 'good-signal'">{{impedanceValue}}</span>
  </div>
<!--  <button class="resume-training-hint-btn" mat-raised-button (click)="autoResumeTraining()" [disabled]="artefaktValue > 6 || impedanceValue > 20">{{'TRAINER_TEMPLATE.RESUME' | translate}}</button>-->
<!--  <button class="resume-training-hint-btn" mat-raised-button (click)="startTraining(selectedTemplate.data, true, true)" [disabled]="artefaktValue > 6 || impedanceValue > 20">{{'TRAINER_TEMPLATE.RESUME' | translate}}</button>-->
<!--  <button (click)="artOk()">ARROK</button>-->
</div>

<!--<button (click)="art()">ARTE</button>-->
