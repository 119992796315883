export const TRAINING_ICONS = [
  {
    path: './assets/training-icons/focus.png',
    name: 'TRAINING_ICONS.FOCUS',
    link: 'https://www.flaticon.com/free-icons/focus',
    title: 'focus icons'
  },
  {
    path: './assets/training-icons/concentration.png',
    name: 'TRAINING_ICONS.CONCENTRATION',
    link: 'https://www.flaticon.com/free-icons/concentration',
    title: 'concentration icons'
  },
  {
    path: './assets/training-icons/calm.png',
    name: 'TRAINING_ICONS.CALM',
    link: 'https://www.flaticon.com/free-icons/calm',
    title: 'calm icons'
  },
  {
    path: './assets/training-icons/relaxation.png',
    name: 'TRAINING_ICONS.RELAXATION',
    link: 'https://www.flaticon.com/free-icons/meditation',
    title: 'meditation icons'
  },
  {
    path: './assets/training-icons/memory.png',
    name: 'TRAINING_ICONS.MEMORY',
    link: 'https://www.flaticon.com/free-icons/memory',
    title: 'memory icons'
  },
  {
    path: './assets/training-icons/thinking.png',
    name: 'TRAINING_ICONS.THINKING',
    link: 'https://www.flaticon.com/free-icons/brain',
    title: 'brain icons'
  },
  {
    path: './assets/training-icons/learning.png',
    name: 'TRAINING_ICONS.LEARNING',
    link: 'https://www.flaticon.com/free-icons/idea',
    title: 'idea icons'
  },
  {
    path: './assets/training-icons/speed-up.png',
    name: 'TRAINING_ICONS.SPEED_UP',
    link: 'https://www.flaticon.com/free-icons/brain',
    title: 'brain icons'
  },
  {
    path: './assets/training-icons/slow-down.png',
    name: 'TRAINING_ICONS.SLOW_DOWN',
    link: 'https://www.flaticon.com/free-icons/calm',
    title: 'calm icons'
  },
  {
    path: './assets/training-icons/tuning.png',
    name: 'TRAINING_ICONS.TUNING',
    link: 'https://www.flaticon.com/free-icons/tune',
    title: 'tune icons'
  },
]
