import { Component, OnInit } from '@angular/core';
import {TRAINING_ICONS} from '../../../core/consts/training-icons';

@Component({
  selector: 'kt-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  imagesList = TRAINING_ICONS

  constructor() { }

  ngOnInit(): void {
  }

}
