import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kt-electrode-contact-dialog',
  templateUrl: './electrode-contact-dialog.component.html',
  styleUrls: ['./electrode-contact-dialog.component.scss']
})
export class ElectrodeContactDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ElectrodeContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public text: any
  ) { }

  ngOnInit(): void {
  }

}
