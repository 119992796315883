import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChangeVideoPauseService {

  pauseSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }
}
