import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingTemplateDataService {

  youtubePlaylistSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customYouTubeVideoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  changedCustomVideoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  customVideoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  chartOptionsSubject: BehaviorSubject<object> = new BehaviorSubject<object>(null);

  currentTemplate: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

  startTraining: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  trainingStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
}
