<div class="card-body-container">
  <div class="page-heading">
    <h2>{{ "CHARTS_NAMING.RATIO" | translate }}</h2>
  </div>
  <div class="charts-options">
    <mat-form-field class="channel-choose" tourAnchor="channel-choose">
      <mat-select disabled [(value)]="selectedChannel" (selectionChange)="changeChannel($event)">
        <mat-option value="bp_ch1">{{ "CHART_OPTIONS.CHANNEL_ONE" | translate }}</mat-option>
        <mat-option value="bp_ch2">{{ "CHART_OPTIONS.CHANNEL_TWO" | translate }}</mat-option>
      </mat-select>
      <mat-select-trigger>
        <kt-channel-one-dots></kt-channel-one-dots>
      </mat-select-trigger>
    </mat-form-field>
    <mat-form-field class="y-asis" tourAnchor="y-asis">
      <mat-label>{{ "CHART_OPTIONS.Y_VALUE" | translate }}</mat-label>
      <input matInput [(ngModel)]="maxChartZoom" min="0" (input)="changeChartZoomLevel($event)">
    </mat-form-field>
    <mat-form-field class="x-length" tourAnchor="x-length">
      <mat-label>{{ "CHART_OPTIONS.X_VALUE" | translate }}</mat-label>
      <input matInput [(ngModel)]="maxChartSeconds" (input)="changeChartUpdateInterval($event)">
    </mat-form-field>
    <mat-form-field class="average-presets" tourAnchor="average-presets">
      <mat-label>{{ "CHART_OPTIONS.AVERAGE_PRESETS" | translate }}</mat-label>
      <input matInput [(ngModel)]="selectedAverage" (input)="changeMovingAverage($event)">
    </mat-form-field>
    <mat-form-field class="channel-choose selected-average" tourAnchor="selected-average">
      <mat-label>{{ "CHART_OPTIONS.AVERAGE_PRESETS" | translate }}</mat-label>
      <mat-select [(value)]="selectedAverage" (selectionChange)="changeLongAveraging($event)">
        <mat-option value="5">5s</mat-option>
        <mat-option value="30">30s</mat-option>
        <mat-option value="60">60s</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="zoom-step" tourAnchor="zoom-step">
      <mat-label>{{ "CHART_OPTIONS.ZOOM_STEP" | translate }}</mat-label>
      <input matInput [(ngModel)]="zoomStep" (input)="saveSettingsToLocalStorage()">
    </mat-form-field>
  </div>

  <div class="frequency-container">
    <div class="ratio-one">
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencyOne" (selectionChange)="resetFirstRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <span> / </span>
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencyTwo" (selectionChange)="resetFirstRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="ratio-disable-trigger">
        <input type="checkbox" value="0" (change)="disableSelectedRatio($event.target.value, $event.target.checked)" checked>
        <span>Ratio 1</span>
      </div>
    </div>
    <div class="ratio-second">
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencyThree" (selectionChange)="resetSecondRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <span> / </span>
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencyFour" (selectionChange)="resetSecondRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <label class="ratio-disable-trigger">
        <input type="checkbox" value="1" (change)="disableSelectedRatio($event.target.value, $event.target.checked)" checked>
        <span>Ratio 2</span>
      </label>
    </div>
    <div class="ratio-third">
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencyFive" (selectionChange)="resetThirdRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <span> / </span>
      <mat-form-field class="ratio-choose">
        <mat-select [(ngModel)]="frequencySix" (selectionChange)="resetThirdRatio()">
          <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="ratio-disable-trigger">
        <input type="checkbox" value="2" (change)="disableSelectedRatio($event.target.value, $event.target.checked)" checked>
        <span>Ratio 3</span>
      </div>
    </div>
  </div>
  <div class="ratio-after">
    <p>
      <span class="red"></span>
      Ratio 1
    </p>
    <p>
      <span class="blue"></span>
      Ratio 2
    </p>
    <p>
      <span class="green"></span>
      Ratio 3
    </p>
  </div>
  <div class="charts-container">
    <div class="line-chart">
      <canvas #mainRatioChart
              id="mainLineChart"
              class="absolute-mid wide-canvas"
      tourAnchor="line-chart">
      </canvas>
    </div>
    <div class="chart-zoom" tourAnchor="chart-zoom">
      <button mat-raised-button (click)="zoomOutChart()">+</button>
      <button mat-raised-button (click)="zoomInChart()">-</button>
    </div>
  </div>
  <div class="ratios-container">
    <p class="ratio ratio-one">Ratio 1: {{averageRatioOne}}</p>
    <p class="ratio ratio-two">Ratio 2: {{averageRatioTwo}}</p>
    <p class="ratio ratio-three">Ratio 3: {{averageRatioThree}}</p>
  </div>
  <div class="global-averages-container" tourAnchor="global-averages-container">
    <div class="average-item">
      <p>Theta / Delta: <span>{{thetaOnDelta}}</span></p>
      <p>Theta / Alpha: <span>{{thetaOnAlpha}}</span></p>
    </div>
    <div class="average-item">
      <p>Theta / Smr, Lo-Beta: <span>{{thetaOnLowBeta}}</span></p>
      <p>Theta / Beta 1: <span>{{thetaOnBetaOne}}</span></p>
    </div>
    <div class="average-item">
      <p>Theta / Beta 2: <span>{{thetaOnBetaTwo}}</span></p>
      <p>Theta / Hi-Beta: <span>{{thetaOnHiBeta}}</span></p>
    </div>
    <div class="average-item">
      <p>Beta 1 / Hi-beta: <span>{{betaOneOnHiBeta}}</span></p>
      <p>Smr, Lo-Beta / Beta 1: <span>{{lowBetaOnBetaOne}}</span></p>
    </div>
  </div>
</div>
