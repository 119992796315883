<div class="dialog-wrap">
  <span class="material-symbols-outlined warning-icon">
        warning
  </span>
  <p>{{"GENIUS_SETTINGS.BIND_SENSOR_TEXT" | translate}}</p>
  <div class="dialog-actions">
    <button mat-raised-button color="primary" (click)="confirm()">{{'GENIUS_SETTINGS.CONFIRM_BIND' | translate}}</button>
    <button mat-raised-button color="warn" (click)="cancel()">{{'GENIUS_SETTINGS.CANCEL_BIND' | translate}}</button>
  </div>
</div>
