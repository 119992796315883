// Actions
import { AuthActions, AuthActionTypes, CleanAuth } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';
import { Subscription } from 'src/app/views/pages/profile/revenue-api.model';

export interface AuthState {
  loggedIn: boolean;
  authToken: string;
  user: User;
  isUserLoaded: boolean;
  uid: string;
  groups: any[],
  plans: Subscription[],
  createdAt: any;
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  authToken: undefined,
  user: undefined,
  isUserLoaded: false,
  uid: undefined,
  groups: [],
  plans: [],
  createdAt: null
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.Login: {
      const token: string = action.payload.authToken;
      const uid: string = action.payload.uid;
      return {
        ...state,
        loggedIn: true,
        authToken: token,
        user: undefined,
        isUserLoaded: false,
        uid
      };
    }

    case AuthActionTypes.Register: {
      const token: string = action.payload.authToken;
      const uid: string = action.payload.uid;
      return {
        ...state,
        loggedIn: true,
        authToken: token,
        user: undefined,
        isUserLoaded: false,
        uid
      };
    }

    case AuthActionTypes.UpdateUser: {
      const payload = action.payload as any;
      return {
       ...state,
       user: {
         ...state.user,
         ...payload
       }
      };
    }

    case AuthActionTypes.SetLoggedIn: {
      const payload = action.payload;
      return {
       ...state,
       loggedIn: true,
       createdAt: payload.createdAt
      };
    }

    case AuthActionTypes.SaveGroups: {
      const payload = action.payload;
      return {
        ...state,
        groups: payload.groups
      }
    }

    case AuthActionTypes.SavePlans: {
      const payload = action.payload;
      return {
        ...state,
        plans: payload.plans
      }
    }

    case AuthActionTypes.CleanAuth:
      return initialAuthState;

    case AuthActionTypes.Logout:
      return initialAuthState;

    case AuthActionTypes.UserLoaded: {
      const user: User = action.payload.user;
      return {
        ...state,
        user,
        isUserLoaded: true
      };
    }

    default:
      return state;
  }
}
