<div class="card-body-container">
  <div class="page-heading">
    <h2>EEG {{ "CHARTS_NAMING.RAW_SIGNAL" | translate }}</h2>
    <div class="charts-options">
      <mat-form-field class="channel-choose" tourAnchor="channel-choose">
        <mat-select disabled [(value)]="selectedChannel" (selectionChange)="changeChannel($event)">
          <mat-option value="samples_ch1">{{ "CHART_OPTIONS.CHANNEL_ONE" | translate }}</mat-option>
          <mat-option value="samples_ch2">{{ "CHART_OPTIONS.CHANNEL_TWO" | translate }}</mat-option>
        </mat-select>
        <mat-select-trigger>
          <kt-channel-one-dots></kt-channel-one-dots>
        </mat-select-trigger>
      </mat-form-field>
      <mat-form-field class="y-asis" tourAnchor="y-asis">
        <mat-label>{{ "CHART_OPTIONS.Y_VALUE" | translate }}</mat-label>
        <input matInput [(ngModel)]="maxChartZoom" (input)="changeChartZoomLevelMax($event)">
      </mat-form-field>
      <mat-form-field class="x-length" tourAnchor="x-length">
        <mat-label>{{ "CHART_OPTIONS.X_VALUE" | translate }}</mat-label>
        <input matInput [(ngModel)]="maxChartSeconds" (input)="changeChartUpdateInterval($event)">
      </mat-form-field>
      <mat-form-field class="average-presets" tourAnchor="zoom-step">
        <mat-label>{{ "CHART_OPTIONS.ZOOM_STEP" | translate }}</mat-label>
        <input matInput [(ngModel)]="zoomStep" (input)="saveSettingsToLocalStorage()">
      </mat-form-field>
    </div>
  </div>

  <div class="charts-container">
    <div class="line-chart">
      <canvas #mainRawChart
              id="mainLineChart"
              class="absolute-mid wide-canvas"
              tourAnchor="line-chart">
      </canvas>
    </div>
    <div class="chart-zoom" tourAnchor="chart-zoom">
      <button mat-raised-button (click)="zoomOutChart()">+</button>
      <button mat-raised-button (click)="zoomInChart()">-</button>
    </div>
  </div>
</div>
