<div class="player-container" *ngIf="!showPortal">
  <div class="trainer" *ngIf="trainerUser">
    <div class="credential">
      <h3>{{ "YOUTUBE_VIDEO.TRAINER_TEXT" | translate }}</h3>
      <p>{{trainerUser.displayName}}</p>
    </div>
    <div class="button-container">
      <button mat-raised-button (click)="deleteTrainer()">{{ "YOUTUBE_VIDEO.DISCONNECT_TEXT" | translate }}</button>
    </div>
  </div>
  <carousel
    *ngIf="showOptions && showVideo && videoType === 'youtube'"
    [cellsToShow]="3"
    [cellWidth]="100"
    [height]="carouselHeight"
    [loop]="true"
  style="z-index: 1">
    <ng-container *ngFor="let video of videosArray; let i = index">
      <div class="carousel-cell" *ngIf="video.imageUrl">
        <img [src]="video?.imageUrl" alt="image" (click)="setVideo(video.id, i)"/>
              <p>
                {{video?.name | slice:0: limitOfCharacters(video.name.length) }}
                <span *ngIf="video?.name.length > limitOfCharacters(video.name.length)">...</span>
              </p>
      </div>
    </ng-container>
  </carousel>
  <div class="portal-button-container" *ngIf="currentUser?.role === 'supervisor' && showOptions">
<!--    <button mat-raised-button (click)="openPortal()">{{ "YOUTUBE_VIDEO.OPEN_WINDOW" | translate }}</button>-->
  </div>
  <div class="change-video-container">
    <button mat-raised-button *ngIf="currentUser?.role === 'normal' && trainingTime < 60"  (click)="changeVideoDialog()">{{ "YOUTUBE_VIDEO.CHANGE_VIDEO" | translate }}</button>
  </div>
  <div class="youtube-container" id="youtube" *ngIf="videoType === 'youtube'">
    <img  class="trigger-video" (click)="triggerVideoHide()" [src]="showVideo ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
    <div id="player-size">
      <div id="cropping-div" style="">
        <div id="div-to-crop" style="">
          <div id="player-wrapper">
            <div id="player">
              <youtube-player *ngIf="showVideo"
                              class="youtube-player"
                              [videoId]="videoId"
                              [playerVars]="options"
                              (ready)="savePlayer($event)"
              >
              </youtube-player>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <youtube-player *ngIf="showVideo"-->
<!--      class="youtube-player"-->
<!--      [videoId]="videoId"-->
<!--      [playerVars]="options"-->
<!--      (ready)="savePlayer($event)"-->
<!--    >-->
<!--    </youtube-player>-->
    <div class="video-options" *ngIf="showVideo && !isEmptyVideo()">
      <div class="payback-rate" *ngIf="feedBackType === 'startStop'">
        <mat-form-field>
          <mat-label>{{ "YOUTUBE_VIDEO.PLAYBACK_RATE" | translate }}</mat-label>
          <mat-select [ngModel]="paybackRate" (selectionChange)="setPlayerPlaybackRate($event.value, true)">
            <mat-option *ngFor="let rate of paybackRates" [value]="rate">{{rate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="feedback-type">
        <mat-form-field>
          <mat-label>{{ "YOUTUBE_VIDEO.FEEDBACK_TYPE" | translate }}</mat-label>
          <mat-select [ngModel]="feedBackType" (selectionChange)="changeFeedbackType($event.value)">
            <mat-option value="startStop">{{ "YOUTUBE_VIDEO.FEEDBACK_STOP" | translate }}</mat-option>
            <mat-option value="slowNormal">{{ "YOUTUBE_VIDEO.FEEDBACK_SLOW" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="video-controls" *ngIf="videoType === 'youtube' && trainingTemplate && showVideo && !isEmptyVideo()">
      <div class="actions" >
        <div class="button-wrap">
          <button [disabled]="videoIndex === 0" (click)="previousYoutubeVideo()">
      <span class="material-symbols-outlined">
        skip_previous
      </span>
          </button>
        </div>
        <div class="timing">
          <p>{{youtubeVideoTiming(currentYoutubeTime)}}</p>
        </div>
        <mat-slider *ngIf="!isEmptyVideo()" min="0" [max]="maxYoutubeTime" step="1" [value]="currentYoutubeTime" (change)="seekYoutubeVideo($event.value)"></mat-slider>
        <div class="timing">
          <p>{{youtubeVideoTiming(maxYoutubeTime)}}</p>
        </div>
        <div class="button-wrap">
          <button [disabled]="videoIndex > videosArray.length - 2" (click)="nextYoutubeVideo()">
      <span class="material-symbols-outlined">
        skip_next
      </span>
          </button>
        </div>
      </div>
    </div>

    <div class="video-actions" *ngIf="showVideo && !isEmptyVideo()">
      <mat-slider min="0" [max]="100" step="1" [value]="videoVolume" (change)="setVideoVolume($event.value)"></mat-slider>
      <button (click)="muteVideo()">
        <img src="{{videoMuted ? './assets/images/mute.png' : './assets/images/play.png'}}" alt="mute">
      </button>
    </div>
    <div class="warnings" tourAnchor="atrefakt-tour" *ngIf="trainingTemplate">
      <h2>
        {{ "YOUTUBE_VIDEO.ARTEFACT_TEXT" | translate }}
        <img (click)="triggerArtefakt()" [src]="showArtefaktSignal ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
      </h2>
      <p class="artefakt-value" *ngIf="artefaktValue && showArtefaktSignal">Artefakt: {{artefaktValue}}</p>
      <div class="artefact-container" *ngIf="showArtefaktSignal">
        <mat-radio-group [ngModel]="artefactTresHold" (change)="setArtefakt($event)">
          <mat-radio-button value="2">2</mat-radio-button>
          <mat-radio-button value="4">4</mat-radio-button>
          <mat-radio-button value="6">6</mat-radio-button>
        </mat-radio-group>
        <div class="lighter">
          <div [ngClass]="showGreenColor ? 'green' : 'dot'"></div>
          <div [ngClass]="showYellowColor ? 'yellow' : 'dot'"></div>
          <div [ngClass]="showRedColor ? 'red' : 'dot'"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-video-container" *ngIf="videoType === 'custom'">
    <img class="trigger-video" (click)="triggerVideoHide()" [src]="showVideo ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
    <video *ngIf="showVideo" class="player absolute-mid h-100 w-100" id="video-player"  preload="auto"></video>
    <div class="video-options" *ngIf="showVideo">
      <div class="payback-rate">
        <mat-form-field>
          <mat-label>{{ "YOUTUBE_VIDEO.PLAYBACK_RATE" | translate }}</mat-label>
          <mat-select [ngModel]="paybackRate" (selectionChange)="setPlayerPlaybackRate($event.value)">
            <mat-option *ngFor="let rate of paybackRates" [value]="rate">{{rate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="feedback-type">
        <mat-form-field>
          <mat-label>{{ "YOUTUBE_VIDEO.FEEDBACK_TYPE" | translate }}</mat-label>
          <mat-select [ngModel]="feedBackType" (selectionChange)="changeFeedbackType($event.value)">
            <mat-option value="startStop">{{ "YOUTUBE_VIDEO.FEEDBACK_STOP" | translate }}</mat-option>
            <mat-option value="slowNormal">{{ "YOUTUBE_VIDEO.FEEDBACK_SLOW" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="video-actions">
      <mat-slider  min="0" [max]="100" step="1" [value]="customVideoVolume" (change)="setCustomVideoVolume($event.value)"></mat-slider>
      <button (click)="muteVideo()">
        <img src="{{muteCustomVideo ? './assets/images/mute.png' : './assets/images/play.png'}}" alt="mute">
      </button>
    </div>
    <div class="warnings" *ngIf="trainingTemplate">
      <h2>
        {{ "YOUTUBE_VIDEO.ARTEFACT_TEXT" | translate }}
        <img (click)="triggerArtefakt()" [src]="showArtefaktSignal ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
      </h2>
      <p class="artefakt-value" *ngIf="artefaktValue && showArtefaktSignal">Artefakt: {{artefaktValue}}</p>
      <div class="artefact-container" *ngIf="showArtefaktSignal">
        <mat-radio-group [ngModel]="artefactTresHold" (change)="setArtefakt($event)">
          <mat-radio-button value="2">2</mat-radio-button>
          <mat-radio-button value="4">4</mat-radio-button>
          <mat-radio-button value="6">6</mat-radio-button>
        </mat-radio-group>
        <div class="lighter">
          <div [ngClass]="showGreenColor ? 'green' : 'dot'"></div>
          <div [ngClass]="showYellowColor ? 'yellow' : 'dot'"></div>
          <div [ngClass]="showRedColor ? 'red' : 'dot'"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="player-container container-portal" *cdkPortal>-->
<!--  <carousel-->
<!--    [cellsToShow]="3"-->
<!--    [cellWidth]="100"-->
<!--    [height]="carouselHeight"-->
<!--    [loop]="true">-->
<!--    <ng-container *ngFor="let video of videosArray">-->
<!--      <div class="carousel-cell" *ngIf="video.imageUrl">-->
<!--        <img [src]="video?.imageUrl" alt="image" (click)="setVideo(video.id)"/>-->
<!--        <p>-->
<!--          {{video?.name | slice:0: limitOfCharacters(video.name.length) }}-->
<!--          <span *ngIf="video?.name.length > limitOfCharacters(video.name.length)">...</span>-->
<!--        </p>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </carousel>-->
<!--  <div class="youtube-container">-->
<!--&lt;!&ndash;    <div class="video-hide" *ngIf="hideVideo"></div>&ndash;&gt;-->
<!--    <div class="video-hide-bottom" *ngIf="hideVideo"></div>-->
<!--    <div class="video-hide-top" *ngIf="hideVideo"></div>-->
<!--    <youtube-player-->
<!--      class="youtube-player"-->
<!--      [videoId]="videoId"-->
<!--      [playerVars]="options"-->
<!--      (ready)="savePlayer($event)"-->
<!--    >-->
<!--    </youtube-player>-->
<!--    <div class="video-actions">-->
<!--      <mat-form-field>-->
<!--        <mat-label>{{ "YOUTUBE_VIDEO.VOLUME" | translate }}</mat-label>-->
<!--        <input matInput [(ngModel)]="videoVolume" (input)="setVideoVolumeWindow($event)">-->
<!--      </mat-form-field>-->
<!--      <button mat-raised-button (click)="muteVideo()" *ngIf="!videoMuted">{{ "YOUTUBE_VIDEO.MUTE_VIDEO" | translate }}</button>-->
<!--      <button mat-raised-button (click)="muteVideo()" *ngIf="videoMuted">{{ "YOUTUBE_VIDEO.UNMUTE_VIDEO" | translate }}</button>-->
<!--    </div>-->
<!--    <div class="warnings">-->
<!--      <h2>{{ "YOUTUBE_VIDEO.ARTEFACT_TEXT" | translate }}</h2>-->
<!--      <p class="artefakt-value" *ngIf="artefaktValue">Artefakt: {{artefaktValue}}</p>-->
<!--      <div class="artefact-container">-->
<!--        <mat-radio-group [ngModel]="artefactTresHold" (change)="setArtefakt($event)">-->
<!--          <mat-radio-button value="2">2</mat-radio-button>-->
<!--          <mat-radio-button value="4">4</mat-radio-button>-->
<!--          <mat-radio-button value="6">6</mat-radio-button>-->
<!--        </mat-radio-group>-->
<!--        <div class="lighter">-->
<!--          <div [ngClass]="showRedColor ? 'red' : 'dot'"></div>-->
<!--          <div [ngClass]="showYellowColor ? 'yellow' : 'dot'"></div>-->
<!--          <div [ngClass]="showGreenColor ? 'green' : 'dot'"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="close-portal-container">-->
<!--      <button mat-raised-button (click)="closePortal()">Close</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="video-position-ask" *ngIf="showSeekHint">
  <h3>{{ "VIDEO_HINT.HEADING" | translate }} <b>{{transformSeconds(hintSeconds)}}</b></h3>
  <button class="resume" (click)="setVideoTime()">
    {{ "VIDEO_HINT.CONTINUE" | translate }}
  </button>
  <button class="reset" (click)="clearPlayerTime()">
    {{ "VIDEO_HINT.RESTART" | translate }}
  </button>
  <div class="close" (click)="closeYoutubeHint()">
    <mat-icon>close</mat-icon>
  </div>
</div>
