import { ExgDecodedData, ExgDecodingError } from './muscle-data';

//  BLE notification packet data:

//  1 byte : PACKET_ID
//  1 byte : packet_counter, +1 for each packet, 0..255 with wrap around
//  n bytes: 'n' and content encoding depends on PACKET_ID

export const ExgPacket = {
  EMG_RAW_BP: {
    ID: 0x15,      // ADC 2ksps
    LEN: 140,      // 2bytes header + 20SMP x 2ch x 3bytes + 3BP x 2ch x 3bytes
    INTERVAL_MS: 10,
    AVG_NPS: 100,  // average notifications per second
    TEXT: 'EMG_RAW_BP',
  },

  EMG_RAW: {
    ID: 0x20,      // ADC 2ksps decimated by 20
    LEN: 8,        // 2bytes header + 1SMP x 2ch x 3bytes
    INTERVAL_MS: 10,
    AVG_NPS: 100,  // average notifications per second
    TEXT: 'EMG_RAW',
  },

  EMG_BP: {
    ID: 0x25,      // <-- firmware startup mode
    LEN: 20,       // 2bytes header + 3BP x 2ch x 3bytes
    INTERVAL_MS: 10,
    AVG_NPS: 100,  // average notifications per second
    TEXT: 'EMG_BP',
  },

  // -----------------------------------------------------------------------
  EEG_RAW_BP: {
    ID: 0x35,      // ADC 250sps
    LEN: 122,      // 2bytes header + 10SMP x 2ch x 3bytes + 10BP x 2ch x 3bytes
    INTERVAL_MS: 40,
    AVG_NPS: 25,   // average notifications per second
    TEXT: 'EEG_RAW_BP',
  },

  EEG_RAW: {
    ID: 0x40,      // ADC 250sps
    LEN: 20,       // 2bytes header + 3SMP x 2ch x 3bytes
    INTERVAL_MS: 12,
    AVG_NPS: 83.333,  // average notifications per second
    TEXT: 'EEG_RAW',
  },

  EEG_BP: {      // <-- firmware toggle mode when holding SW2 >10sec
    ID: 0x45,    // ADC 250sps, BP ABS decimation by 10
    ID_START: 0x45,
    ID_CONTINUE: 0x46,
    LEN_ARRAY: [20, 20, 20, 8], // 2bytes header + 3BP x 2ch x 3bytes
    INTERVAL_MS: 40,   // total ms. must be the sum of the below array:
    INTERVAL_MS_ARRAY: [40, 0, 0, 0],
    AVG_NPS: 100,  // average notifications per second
    TEXT: 'EEG_BP',
    TEXT_BP_ARRAY: // EEG BP names:
      [
        'EEG DELTA (0.5-4 Hz)',
        'EEG THETA (4-8 Hz)',
        'EEG LO-ALPHA (8-10 Hz)',
        'EEG ALPHA (8-12 Hz)',
        'EEG HI-ALPHA (10-12 Hz)',
        'EEG LO-BETA (12-15 Hz)',
        'EEG BETA 1 (15-19 Hz)',
        'EEG BETA 2 (19-23 Hz)',
        'EEG HI-BETA (23-27 Hz)',
        'EEG ARTEFAKT (52-58 Hz)',
      ],
  },

  ACCELEROMETER: {
    ID: 0x60,    // ACCELEROMETER ca. 1.28 sps
    ID_CONTINUE: 0x61,
    LEN_ARRAY: [18, 18, 6],
    // 0: 2bytes header + 3 x LIN_XYZ x 4bytes + 1 x GYRO_X x 4bytes
    // 1: 2bytes header + 2 x GYRO_YZ x 4bytes + 2 x MAGN_XY x 4bytes
    // 2: 2bytes header + 1 x MAGN_Z x 4bytes
    INTERVAL_MS_ARRAY: [780, 0, 0],
    AVG_NPS: 1.28,  // average notifications per second
    TEXT: 'ACCELEROMETER',
  },

  IMPEDANCE : {
    ID : 0x50,    // EEG electrode impedance 1.0 sps
    LEN : 18,
    // 2 bytes header + 2 ch x ( IMP_ABS_OHMS x 4bytes float + IMP_PHASE_DEG x 4bytes float )
    INTERVAL_MS : 1000,
    AVG_NPS : 1.0,  // average notifications per second
    TEXT : 'IMPEDANCE',
  },

  FUEL_GAUGE: {
    ID: 0x65,    // FUEL_GAUGE ca. 1 sps
    LEN: 12,
    // 2bytes header +
    //   2 bytes int16_t Battery voltage (mV)
    //   2 bytes int16_t Battery current (mA)
    //   2 bytes int16_t Coulomb counter value (mAh)
    //   2 bytes int16_t convertion counter (counts)
    //   2 bytes int16_t Battery temperature (0.1°C)
    INTERVAL_MS: 1000,
    AVG_NPS: 1.0,  // average notifications per second
    TEXT: 'FUEL_GAUGE',
  },

  // todo:
  // DEVICE_STATUS : 0x???,  // LEDs on/off/brightness, SD card free memory
}

/*
commands (sent and response received via Nordic UART service):

mutually exclusive modes: (only one can be active at any given moment)
'SET_PACKET(0x15,ON)' : EMG_RAW_BP
'SET_PACKET(0x20,ON)' : EMG_RAW
'SET_PACKET(0x25,ON)' : EMG_BP

'SET_PACKET(0x35,ON)' : EEG_RAW_BP
'SET_PACKET(0x40,ON)' : EEG_RAW
'SET_PACKET(0x45,ON)' : EEG_BP

optional modes: (can be added to the above modes)
'SET_PACKET(0x65,ON)'  : FUEL_GAUGE ON
'SET_PACKET(0x65,OFF)' : FUEL_GAUGE OFF
'SET_PACKET(0x60,ON)'  : ACCELEROMETER ON
'SET_PACKET(0x60,OFF)' : ACCELEROMETER OFF

other commands:
'SET_DATE_TIME('<date_timezone_long_int>')' : set FW RTC date_time
'GET_FW_VERSION'  : request firmware version, GIT hash, build date
'VIBRATE(<id0>, <id1>, ... <id7>)' : vibrate tactile actuator using sequencer patterns (see datasheet) e.g. 'VIBRATE(84,1,0,0,0,0,0,0)'

'RECORDING(<ON|OFF>)' : Start/Stopp recording data on SD-card with new filename with timestamp

*/



// List of IDs for streaming ADS frontend data:
/*
example callback_data:

{
  'data_type_id':53,
  'data_type_id_text':'EEG_RAW_BP',
  'data':{
    'packet_dump':'',
    'samples_ch1':[-27586,-27572,-27650,-27732,-27698,-27638,-27620,-27700,-27714,-27720],
    'samples_ch2':[-622614,-625288,-626368,-624508,-622160,-622560,-625104,-626234,-624498,-622110],
    'bp_ch1':[16,11,8,13,12,13,14,13,13,26],
    'bp_ch2':[168,33,12,11,10,14,18,15,18,719]}
}

*/


export const real_time_ms_start = Date.now()

export const ExgPacketDecoder = {

  callback_data: {},             // working copy. can be incomplete during start / continue packet assembly
  callback_data_complete: {},    // final always complete copy for start / continue packet assembly
  rcv_byte_data: new Uint8Array(null),

  packet_drop_fake_error_count: 0, // number of fake BLE notification packet drop to test re-sync etc.

  packet_counter: undefined,     // 1 byte packet counter 0..255 to check packet loss, 'undefined' to catch 1st sync
  packet_counter_total: 0,       // all packets counted
  packet_counter_diff: 0,        // difference packet count. Should be 1 if all is OK. >1 counts lost packets
  packet_drop_total: 0,          // sum of difference packet count. How many packets have been lost
  packet_drop_current: 0,

  packet_start_continue: 0,      // 0 is start, 1..n is continuation packet to be assembled
  packet_index: 0,               // counter for assembled multi part packets.
  // each multi part packet has to be sent contingently, i.e.
  // sending interleaving multi part packets is not permitted!
  packet_index_max: 0,           // multi part packet maximum index. Depends on packet type id.
  nominal_time_ms : 0,            // time since session start in milli seconds, based on receive samples and sample rate
  nominal_time : 0,               // time since session start in seconds, based on receive samples and sample rate
  real_time_ms : 0,               // time since session start in milli seconds, based on host system clock
  real_time : 0,                  // time since session start in seconds, based on host system clock

  real_time_start : real_time_ms_start / 1000,
  lastCorrectData : null,
  total_error_count : 0, // all errors total packet errors count (e.g. wrong length packets, wrong id, re-sync)
  sync_error_count : 0,
  trainingErrors: 0,
  trainingLostPackets: 0,


  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
  // this, bind
  simulatePacketLoss(packet_drop_fake_error_count) {
    console.log('simulatePacketLoss: ' + packet_drop_fake_error_count);
    // console.log('debug this: ', this);
    this.packet_drop_fake_error_count = packet_drop_fake_error_count; // not working without bind
    // ExgPacketDecoder.packet_drop_fake_error_count = packet_drop_fake_error_count;
  },

  resetDecoder () {
    this.packet_counter = undefined;
    this.packet_counter_total = 0;
    this.packet_counter_diff = 0;
    this.packet_drop_total = 0;
    this.packet_drop_current = 0;
    this.total_error_count = 0; // all errors total packet errors count (e.g. wrong length packets, wrong id, re-sync)
    this.sync_error_count = 0;
  },

  resetErrors() {
    this.packet_drop_total = 0;
    this.packet_drop_current = 0;
    this.total_error_count = 0; // all errors total packet errors count (e.g. wrong length packets, wrong id, re-sync)
    this.sync_error_count = 0;
  },

  // decode helper function:
  decode_3_byte_to_int24 (byte0, byte1, byte2) {
    let ADCdecoded =
      256 * (byte0 * 256 + byte1) + byte2;  // LSB first (normal byte order)
    // handle sign:
    let sign = byte0 & 0x80;
    if (sign) {
      // -((~0b11111110 & 0xff) + 1);   // ex.: twos complement for 8 bit length (0xff)
      ADCdecoded = -((~ADCdecoded & 0xffffff) + 1); // twos complement for 3x8 bit length (0xffffff)
    }
    return ADCdecoded;
  },

  clear_callback_data () {
    this.callback_data = {
      data_type_id: 0,
      data_type_id_text: 'clear',
      data: {
        nominal_time: 0,
        nominal_time_ms: 0,
        /*here each protocol decoder adds some arrays
        with data*/
        packet_dump: '',
      }
    };

    this.clear_samples();
    this.clear_bandpasses();
    this.clear_accelerometer();
    this.clear_fuel_gauge();
    this.clear_impedance();
  },

  clear_impedance(){
    // clear result array
    this.callback_data.data.impedance = [];
  },

  clear_samples () {
    // clear emg sample result arrays:
    this.callback_data.data.samples_ch1 = [];
    this.callback_data.data.samples_ch2 = [];
  },

  get_next_int16_t () {
    // get next 2 bytes int16_t:
    let b0 = this.rcv_byte_data[this.rcv_byte_index++];
    let b1 = this.rcv_byte_data[this.rcv_byte_index++];

    // https://stackoverflow.com/questions/42699162/javascript-convert-array-of-4-bytes-into-a-float-value-from-modbustcp-read
    // https://www.w3docs.com/learn-javascript/arraybuffer-binary-arrays.html
    let array = new Uint8Array([b0, b1]);
    let view = new DataView(array.buffer);
    let decoded = view.getInt16(0);
    return decoded;
  },

  get_next_float () {
    // get next 4 bytes float :
    let b0 = this.rcv_byte_data[this.rcv_byte_index++];
    let b1 = this.rcv_byte_data[this.rcv_byte_index++];
    let b2 = this.rcv_byte_data[this.rcv_byte_index++];
    let b3 = this.rcv_byte_data[this.rcv_byte_index++];

    // https://stackoverflow.com/questions/42699162/javascript-convert-array-of-4-bytes-into-a-float-value-from-modbustcp-read
    // https://www.w3docs.com/learn-javascript/arraybuffer-binary-arrays.html
    let array = new Uint8Array([b0, b1, b2, b3]);
    let view = new DataView(array.buffer);
    let decoded = view.getFloat32(0);
    return decoded;
  },

  clear_accelerometer () {
    // clear result array
    this.callback_data.data.accelerometer = [];
  },

  resetDecoderTime() {
    ExgPacketDecoder.nominal_time_ms = 0;
    ExgPacketDecoder.nominal_time = 0;
  },

  clear_fuel_gauge () {
    // clear result array
    this.callback_data.data.fuel_gauge = [];
  },

  get_push_next_samples () {
    // get next pair of samples and store to sample result arrays:
    let b0 = this.rcv_byte_data[this.rcv_byte_index++];
    let b1 = this.rcv_byte_data[this.rcv_byte_index++];
    let b2 = this.rcv_byte_data[this.rcv_byte_index++];
    let decoded = this.decode_3_byte_to_int24(b0, b1, b2);
    this.callback_data.data.samples_ch1.push(decoded);

    b0 = this.rcv_byte_data[this.rcv_byte_index++];
    b1 = this.rcv_byte_data[this.rcv_byte_index++];
    b2 = this.rcv_byte_data[this.rcv_byte_index++];
    decoded = this.decode_3_byte_to_int24(b0, b1, b2);
    this.callback_data.data.samples_ch2.push(decoded);
  },

  clear_bandpasses () {
    // clear emg sample result arrays:
    this.callback_data.data.bp_ch1 = [];
    this.callback_data.data.bp_ch2 = [];
  },

  get_push_next_bandpasses () {
    // get next pair of samples and store to sample result arrays:
    let b0 = this.rcv_byte_data[this.rcv_byte_index++];
    let b1 = this.rcv_byte_data[this.rcv_byte_index++];
    let b2 = this.rcv_byte_data[this.rcv_byte_index++];
    let decoded = this.decode_3_byte_to_int24(b0, b1, b2);
    this.callback_data.data.bp_ch1.push(decoded);

    b0 = this.rcv_byte_data[this.rcv_byte_index++];
    b1 = this.rcv_byte_data[this.rcv_byte_index++];
    b2 = this.rcv_byte_data[this.rcv_byte_index++];
    decoded = this.decode_3_byte_to_int24(b0, b1, b2);
    this.callback_data.data.bp_ch2.push(decoded);
  },


  /*
  * check if packet_id is:
  *   true:   ExG analog frontend data or
  *   false:  aux data from fuel gauge and accelerometer
  */
  // tslint:disable-next-line:variable-name
  isPacketIdExg (packet_id) {
    return ([ExgPacket.EMG_RAW_BP.ID, ExgPacket.EMG_RAW.ID, ExgPacket.EMG_BP.ID,
      ExgPacket.EEG_RAW_BP.ID, ExgPacket.EEG_RAW.ID,
      ExgPacket.EEG_BP.ID, ExgPacket.EEG_BP.ID_CONTINUE].some(el => el === packet_id));
  },

  /*
  * A BLE notification payload packet 'byte_array_buffer' is decoded
  * into one or more channels of different types in the structure 'callback_data'
  * If a decoded result for a data type is avaliabable, the
  * 'decoded_data_callback' is called.
  */
  decode (byte_array_buffer: any) {
    let error_code = 0;
    let message = 'OK';
    this.rcv_byte_data = new Uint8Array(byte_array_buffer.buffer)

    let packet_id = 0;

    // process simulate packet drop error:
    let drop_now_flag = false;
    if( this.packet_drop_fake_error_count > 0){
      drop_now_flag = true;
      this.packet_drop_fake_error_count--;
    }

    if (this.rcv_byte_data.length > 2  && !drop_now_flag) {  // any data there?
      // reset rcv_byte_index:
      this.rcv_byte_index = 2; // where the data begins...

      this.packet_counter_total++;  // count total packets received

      if(this.packet_start_continue == 0){
        // if start_packet, initialize packet
        this.clear_callback_data();
      }


      // check packet counter contingency
      let rcv_packet_counter = this.rcv_byte_data[1];

      // handle 1st sync
      if(this.packet_counter == undefined){
        this.packet_counter = rcv_packet_counter;
      }

      this.packet_counter_diff = rcv_packet_counter - this.packet_counter;
      // fix uint8_t wrap around:
      if(this.packet_counter_diff < 0){
        this.packet_counter_diff+=256;
      }

      // log("this.packet_counter_diff:" + this.packet_counter_diff);

      if(rcv_packet_counter == this.packet_counter){
        // ok, calculate next
        this.packet_counter+=1;
        this.packet_counter%=256;
      }else{
        // mismatch
        // estimate lost packets:
        this.packet_drop_total += this.packet_counter_diff;
        this.trainingLostPackets += this.packet_counter_diff

        // this.packet_counter_diff
        // todo: check realtime difference for plausibility...

        // re-sync
        error_code = 1;
        this.packet_counter = rcv_packet_counter + 1;
        this.packet_counter%=256;
      }

      packet_id = this.rcv_byte_data[0];
      let packet_length = this.rcv_byte_data.length;

      this.nominal_time_ms += ExgPacket.EMG_RAW_BP.INTERVAL_MS;
      this.nominal_time = this.nominal_time_ms / 1000;
      switch (packet_id) {
        case ExgPacket.EMG_RAW_BP.ID:
          this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = ExgPacket.EMG_RAW_BP.TEXT;
          // check expected packet length:
          if (packet_length == ExgPacket.EMG_RAW_BP.LEN) {
            // 20smp x 2ch x 3bytes
            for (let set_index = 0; set_index < 20; set_index++) {
              this.get_push_next_samples();
            }
            // 3BP x 2ch x 3bytes
            for (let set_index = 0; set_index < 3; set_index++) {
              this.get_push_next_bandpasses();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('1')

        case ExgPacket.EMG_RAW.ID:
          // Status.set_detected_mode_emg();
          // Status.set_data_type_id(packet_id);
          // Status.calculateJitter(ExgPacket.EMG_RAW.INTERVAL_MS, this.real_time_ms);
          this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = ExgPacket.EMG_RAW.TEXT;
          // check expected packet length:
          if (packet_length == ExgPacket.EMG_RAW.LEN) {
            // 1smp x 2ch x 3bytes
            for (let set_index = 0; set_index < 1; set_index++) {
              this.get_push_next_samples();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('2')
          break;

        case ExgPacket.EMG_BP.ID:
          // Status.set_detected_mode_emg();
          // Status.set_data_type_id(packet_id);
          // Status.calculateJitter(ExgPacket.EMG_BP.INTERVAL_MS, this.real_time_ms);
          this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = ExgPacket.EMG_BP.TEXT;
          // check expected packet length:
          if (packet_length == ExgPacket.EMG_BP.LEN) {
            // 1smp x 2ch x 3bytes
            for (let set_index = 0; set_index < 3; set_index++) {
              this.get_push_next_bandpasses();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('3')
          break;

        case ExgPacket.EEG_RAW_BP.ID:
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          // Status.calculateJitter(ExgPacket.EEG_RAW_BP.INTERVAL_MS, this.real_time_ms);
          this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = ExgPacket.EEG_RAW_BP.TEXT;
          // check expected packet length:
          if (packet_length == ExgPacket.EEG_RAW_BP.LEN) {
            // 10smp x 2ch x 3bytes
            for (let set_index = 0; set_index < 10; set_index++) {
              this.get_push_next_samples();
            }
            // 10BP x 2ch x 3bytes
            for (let set_index = 0; set_index < 10; set_index++) {
              this.get_push_next_bandpasses();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('4')
          break;

        case ExgPacket.EEG_RAW.ID:
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          // Status.calculateJitter(ExgPacket.EEG_RAW.INTERVAL_MS, this.real_time_ms);
          this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = ExgPacket.EEG_RAW.TEXT;
          // check expected packet length:
          if (packet_length == ExgPacket.EEG_RAW.LEN) {
            // 3smp x 2ch x 3bytes
            for (let set_index = 0; set_index < 3; set_index++) {
              this.get_push_next_samples();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            this.callback_data.data.nominal_time = this.nominal_time;
            this.callback_data.data.nominal_interval_ms = ExgPacket.EMG_RAW.INTERVAL_MS;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;
          }

          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('5')
          break;

        case ExgPacket.EEG_BP.ID:
          this.callback_data.data_type_id = packet_id;
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          // Status.calculateJitter(ExgPacket.EEG_BP.INTERVAL_MS, this.real_time_ms);
          this.packet_start_continue = 0;
        case ExgPacket.EEG_BP.ID_CONTINUE:
          this.callback_data.data_type_id_text = ExgPacket.EEG_BP.TEXT;
          this.packet_index = this.packet_start_continue++;
          this.packet_index_max = ExgPacket.EEG_BP.LEN_ARRAY.length;

          // check expected packet length:
          if (packet_length == ExgPacket.EEG_BP.LEN_ARRAY[this.packet_index]) {
            // start + 2 continue with 3BP x 2ch x 3bytes,
            //   finally 1BP x 2ch x 3bytes,
            // in total 9BP x 2ch x 3bytes
            let get_count = (ExgPacket.EEG_BP.LEN_ARRAY[this.packet_index] - 2) / 6;
            for (let set_index = 0; set_index < get_count; set_index++) {
              this.get_push_next_bandpasses();
            }
            this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
            if (this.packet_index >= this.packet_index_max - 1) {
              // last continuation packet
              this.packet_start_continue = 0; // reset
              Object.assign(this.callback_data_complete, this.callback_data); // make a copy
              this.setLastCorrectData(Object.assign(this.callback_data_complete, this.callback_data))
              return this.callback_data_complete;
            }
          }
          // console.log(packet_length)
          // console.log(ExgPacket.EEG_BP.LEN_ARRAY[this.packet_index])
          // error_code = 2;  // error: packet length mismatch
          // message = 'packet length mismatch';
          // console.log('6')
          break;

        case ExgPacket.IMPEDANCE.ID:
        {
          let Sc = ExgPacket.IMPEDANCE;  // short cut access
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          this.real_time_ms = Date.now() - this.real_time_ms_start;
          this.real_time = this.real_time_ms / 1000;
          // this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = Sc.TEXT;
          // check expected packet length:
          // #### get_next_int16_t()

          if( packet_length == Sc.LEN ){
            for(let set_index=0;set_index<4;set_index++) {
              this.callback_data.data.impedance.push( this.get_next_float() );
            }

            this.callback_data.data.real_time = this.real_time;
            this.callback_data.data.real_time_ms = this.real_time_ms;
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            return this.callback_data_complete;

          }else{
            error_code = 2;  // error: packet length mismatch
            message = 'packet length mismatch';
          }
        }
          break;


        // ACCELEROMETER
        case ExgPacket.ACCELEROMETER.ID:
          this.callback_data.data_type_id = packet_id;
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          this.packet_start_continue = 0;
        case ExgPacket.ACCELEROMETER.ID_CONTINUE:
          this.callback_data.data_type_id_text = ExgPacket.ACCELEROMETER.TEXT;
          this.packet_index = this.packet_start_continue++;
          this.packet_index_max = ExgPacket.ACCELEROMETER.LEN_ARRAY.length;

          // check expected packet length:
          if (packet_length == ExgPacket.ACCELEROMETER.LEN_ARRAY[this.packet_index]) {
            // ### separate timebase required...
            // ### this.nominal_time_ms += ExgPacket.ACCELEROMETER.INTERVAL_MS_ARRAY[this.packet_index];
            // ### this.nominal_time = this.nominal_time_ms/1000;
            // this.packet_index:
            // 0: 2bytes header + 3 x LIN_XYZ x 4bytes + 1 x GYRO_X x 4bytes
            // 1: 2bytes header + 2 x GYRO_YZ x 4bytes + 2 x MAGN_XY x 4bytes
            // 2: 2bytes header + 1 x MAGN_Z x 4bytes

            // how many 4 byte float numbers do we have in this packet?
            let get_count = (ExgPacket.ACCELEROMETER.LEN_ARRAY[this.packet_index] - 2) / 4;
            for (let set_index = 0; set_index < get_count; set_index++) {
              // push to accelerometer channel
              this.callback_data.data.accelerometer.push(this.get_next_float());
            }
            if (this.packet_index >= this.packet_index_max - 1) {
              // last continuation packet
              this.packet_start_continue = 0; // reset
              Object.assign(this.callback_data_complete, this.callback_data); // make a copy
              return this.callback_data_complete;
            }
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('7')
          break;

        case ExgPacket.FUEL_GAUGE.ID:
          let Sc = ExgPacket.FUEL_GAUGE;  // short cut access
          // Status.set_detected_mode_eeg();
          // Status.set_data_type_id(packet_id);
          // this.packet_start_continue = 0;
          this.callback_data.data_type_id = packet_id;
          this.callback_data.data_type_id_text = Sc.TEXT;
          // check expected packet length:
          // #### get_next_int16_t()

          if (packet_length == Sc.LEN) {
            // 5 x 2byte int16_t fuel gauge values:
            for (let set_index = 0; set_index < 5; set_index++) {
              // push to fuel gauge channel
              this.callback_data.data.fuel_gauge.push(this.get_next_int16_t());
            }
            Object.assign(this.callback_data_complete, this.callback_data); // make a copy
            let batteryPercent = 0;
            let maxChargeBattery = 1600;
            const calculatedPercentage = batteryPercent = 100 + this.callback_data.data.fuel_gauge[2] / maxChargeBattery * 100;
            if (calculatedPercentage) {
              this.callback_data_complete.batteryPercentage = calculatedPercentage;
            }
            return this.callback_data_complete;
          }
          error_code = 2;  // error: packet length mismatch
          message = 'packet length mismatch';
          console.log('8')
          break;

        default:
          // error, unknown packet ID
          error_code = 4;  // error: unknown packet id
          message = 'unknown packet id';
          console.log('exg decode unknown packet id', { error_code, message });
          this.callback_data.data.ID = packet_id;
          this.callback_data.data.packet_counter_diff = this.packet_counter_diff;
          this.callback_data.data.nominal_time = this.nominal_time;
          this.callback_data.data.nominal_time_ms = this.nominal_time_ms;
          Object.assign(this.callback_data_complete, this.callback_data); // make a copy
          return this.callback_data_complete;
      }
    }
    this.count_errors(error_code);
  },
  setLastCorrectData(data) {
    if (data.data.bp_ch1.length === 10) {
      this.lastCorrectData = JSON.parse(JSON.stringify(data));
    }
  },
  getSensorInfo() {
    return {
      totalPackets: this.packet_counter_total,
      packetsDropped: this.packet_drop_total,
      syncError: this.sync_error_count,
      totalErrorCount: this.total_error_count
    }
  },
  count_errors(error_code) {
    if (error_code !== 0){
      this.total_error_count++;
      this.trainingErrors++;
    }
    if (error_code === 1){
      this.sync_error_count++;
    }
  },
  resetTrainingErrors() {
    this.trainingErrors = 0;
    this.trainingLostPackets = 0;
  },
  getTrainingErrors() {
    return {
      errors: this.trainingErrors,
      lostPackets: this.trainingLostPackets
    }
  }
};
