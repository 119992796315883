import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransformDateService {

  constructor() { }

  transformDate(date) {
    if (date) {
      const time = new Date(date);
      const trainingDay = time.toLocaleDateString();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      if (minutes < 10 && seconds < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + `0${minutes}` + ':' + `0${seconds}`;
      }
      if (minutes < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + `0${minutes}` + ':' + seconds;
      }
      if (seconds < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + minutes + ':' + `0${seconds}`;
      }
      return trainingDay + ' ' + '-' + ' ' + hour + ':' + minutes + ':' + seconds
    }
  }
}
