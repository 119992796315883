import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailDateService {

  constructor() { }

  transformDate(date) {
    if (date) {
      const time = new Date(date);
      const trainingDay = time.toLocaleDateString();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      if (minutes < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + `0${minutes}`;
      }
      return trainingDay + ' ' + '-' + ' ' + hour + ':' + minutes;
    }
  }
}
