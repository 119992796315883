<div class="note-container">
  <mat-form-field>
  <mat-label>{{ "EDIT_NOTE.NOTE" | translate }}</mat-label>
    <textarea matInput [disabled]="!data.isCompleted" [(ngModel)]="note" rows="5" cols="40"></textarea>
  </mat-form-field>
  <ng-container *ngIf="data.templatesList && data.templatesList.length">
    <div class="rating-container" *ngFor="let template of data.templatesList">
      <h2>{{'SELECT_RECORDING.RATING_TEXT' | translate}}</h2>
      <img *ngIf="template.ratingImg" [src]="template.ratingImg">
    </div>
  </ng-container>
  <div class="button-container">
    <button mat-raised-button (click)="saveNote()" class="confirm-button">{{ "EDIT_NOTE.SAVE" | translate }}</button>
<!--    <button mat-raised-button color="warn" (click)="close()">{{ "EDIT_NOTE.CLOSE" | translate }}</button>-->
  </div>
</div>
