import { Injectable } from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private afstorage: AngularFireStorage,
  ) { }

  getChatId(userId , currentUserId) {
    let chatId;
    if (currentUserId.role === 'supervisor') {
      chatId = currentUserId.id + userId;
    } else {
      chatId = userId + currentUserId.id;
    }
    return chatId;
  }
  setFileDownloadUrl(chatId, file) {
    return this.afstorage.upload(`chat/${chatId}/${file.name}`, file).percentageChanges();
  }
  downloadFile(chatId, file) {
    const fileUrl = `chat/${chatId}/${file.fileName}`;
    const ref = this.afstorage.ref(fileUrl);
    ref.getDownloadURL().subscribe((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        saveAs(blob, `${file.fileName}`);
      };
      xhr.open('GET', url);
      xhr.send();
    });
  }

  getPreviewUrl(chatId, file) {
    const fileUrl = `chat/${chatId}/${file.fileName}`;
    const ref = this.afstorage.ref(fileUrl);
    return ref.getDownloadURL();
  }
}
