<div class="credits-container">
  <h1>www.flaticon.com</h1>
  <div class="images-container">
    <div class="item" *ngFor="let image of imagesList">
      <a [href]="image.link" target="_blank" [title]="image.title">
        <img [src]="image.path" alt="icon">
      </a>
    </div>
  </div>
  <h1 class="icon-pack-heading">
    Icon Pack: Spring Avatars | Flat

    <a href="https://www.flaticon.com/packs/spring-avatars-12" target="_blank" class="icon-pack">
    <span class="material-icons">
link
</span>
    </a>
  </h1>
</div>
