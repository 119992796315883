import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {map, take} from 'rxjs/operators';
import {FirebaseService} from './firebase/firebase.service';
import {UserStatusService} from './user-status/user-status.service';
import {Router} from '@angular/router';
import {IUser} from '../core/interfaces/user';
import {Observable} from 'rxjs';
import {superUser} from '../core/consts/super-user';

@Injectable({
  providedIn: 'root'
})
export class SupervisorsService {
  superUser = superUser;

  constructor(private afs: AngularFirestore,
              private firebaseService: FirebaseService,
              private router: Router,
              private userStatus: UserStatusService,
  ) {
  }

  getAllSupervisors(isProfilePage?) {
    return this.afs.collection('users', ref => ref.where('role', '==', 'supervisor'))
      .valueChanges()
      .pipe(
        map((users) => {
          if (isProfilePage){
            users.push(this.superUser);
          }
          return users.map((user: any) => {
            return {
              id: user.id,
              email: user.email,
              name: user.displayName? user.displayName : 'Supervisor',
              status: user.status,
              lastSeen: user.lastSeen,
              pendingList: user.pendingList ? user.pendingList : [],
              photoURL: user.photoURL ? user.photoURL : null,
              lastLogin: user.lastLogin
            }
          })
        })
      )
  }
  getSupervisorForActivityPage() {
    return this.afs.collection('users', ref => ref.where('role', '==', 'supervisor')).valueChanges()
  }

  getAllEEGeniusUsers() {
    return this.afs.collection('users', ref => ref.where('EEGeniusUser', '==', true))
      .valueChanges()
      .pipe(
        map((users) => {
          return users.map((user: any) => {
            return {
              id: user.id,
              email: user.email,
              name: user.displayName,
              status: user.status,
              lastSeen: user.lastSeen,
              pendingList: user.pendingList ? user.pendingList : [],
              photoURL: user.photoURL ? user.photoURL : null,
              lastLogin: user.lastLogin,
              role: user.role,
              uid: user.uid,
              hidden: user.hidden === undefined ? false : user.hidden
            }
          })
        })
      )
  }

  getMySupervisors(id) {
    return this.afs.collection('users', db => db.where('allowedSupervisors', 'array-contains' , id)).valueChanges();
  }

  getAllUsers() {
    return this.afs.collection('users')
      .valueChanges()
      .pipe(
        map((users) => {
          return users;
        })
      )
  }

   handleAllUserRecords() {
     return this.afs.collection('users', ref => ref.where('EEGeniusUser', '==', true)).valueChanges();
  }

  triggerUserStatus() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden' && this.router.url !== '/remote-training') {
        this.userStatus.setUserOffline().subscribe(() => {});
      }
      if (document.visibilityState === 'visible' && this.router.url !== '/remote-training') {
        this.userStatus.setUserOnline();
      }
    }
  }
}
