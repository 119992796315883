import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/database';
import { AppState } from '../../reducers';

// NGRX
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  LoadCharts,
  ChartsActionTypes,
  SetChartType,
  AddCustomChart,
} from '../_actions/charts.actions';
import { withLatestFrom, map, switchMap, tap, take, takeUntil } from 'rxjs/operators';
import { currentUser } from '../../auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, defer, of } from 'rxjs';

@Injectable()
export class ChartsEffects {
  @Effect({ dispatch: false })
  loadChartsData$ = this.actions$.pipe(
    ofType<SetChartType>(ChartsActionTypes.SetChartType),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, id: storeState ? storeState.uid : null };
    }),
    switchMap(({ payload, id }) => {
      if (id) {
        // TODO
        // console.log(`users/${id}/${payload.chartType}/sessions/live/data`);
        return this.afd
        .list(`users/${id}/${payload.chartType}/sessions/live/data`)
        .snapshotChanges(['child_added']);
      } else {
        return of(null);
      }
    }),
    map((snapshot: SnapshotAction<any>[]) => {
      if(snapshot && snapshot.length > 0) {
        this.store.dispatch(
          new LoadCharts({
            isDataAvailable: snapshot.length > 0,
            data: snapshot.map(s => s.payload.val())
          })
        );
      }
    })

  );

  @Effect({ dispatch: false })
  loadChartsData1$ = this.actions$.pipe(
    ofType<SetChartType>(ChartsActionTypes.SetChartType),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, id: storeState ? storeState.id : null };
    }),
    switchMap(({ payload, id }) => {
      if (id) {
         // TODO
        // console.log(`users/${id}/${payload.chartType}/sessions/live/data`);
        return this.afd
        .list(`users/${id}/${payload.chartType}/sessions/live/data`)
        .snapshotChanges(['child_added']);
      } else {
        return of(null);
      }
    }),
    map((snapshot: SnapshotAction<any>[]) => {
      if(snapshot && snapshot.length > 0) {
        this.store.dispatch(
          new LoadCharts({
            isDataAvailable: snapshot.length > 0,
            data: snapshot.map(s => s.payload.val())
          })
        );
      }
    })

  );

  @Effect({ dispatch: false })
  addCustomChart$ = this.actions$.pipe(
    ofType<AddCustomChart>(ChartsActionTypes.AddCustomChart),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return {
        payload: action.payload,
        uid: storeState.uid,
        customCharts: storeState.customCharts,
      };
    }),
    switchMap(({ payload, customCharts, uid }) => {
      return this.afs
        .collection(`users`)
        .doc(uid)
        .update({
          customCharts: [...customCharts, payload],
        });
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    return of(new SetChartType({ chartType: 'Skin Response' }));
  });
  constructor(
    private actions$: Actions,
    private router: Router,
    private afd: AngularFireDatabase,
    private afs: AngularFirestore,
    private store: Store<AppState>
  ) {}
}
