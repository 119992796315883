import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularFireDatabase,
  SnapshotAction,
  snapshotChanges,
} from '@angular/fire/database';
import { AppState } from '../../reducers';

// NGRX
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  LoadRecordings,
  ArchiveActionTypes,
  SaveRecordings,
  DeleteRecord,
  LoadSharedRecordings,
  SaveSharedRecordings,
  LoadOthersRecordings,
  SaveOthersRecordings,
} from '../_actions/archive.actions';
import { withLatestFrom, map, switchMap, tap, take } from 'rxjs/operators';
import { currentUser } from '../../auth';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/firestore';
import { Observable, defer, of } from 'rxjs';

@Injectable()
export class ArchiveEffects {
  @Effect({ dispatch: false })
  loadRecordingsData$ = this.actions$.pipe(
    ofType<LoadRecordings>(ArchiveActionTypes.LoadRecordings),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, id: storeState ? storeState.id : null };
    }),
    switchMap(({ payload, id }) => {
      if (id) {
        return this.afs
          .collection(`records/${id}/${payload.chartType}`)
          .snapshotChanges();
      } else {
        return of(null);
      }
    }),
    map((snapshot: DocumentChangeAction<any>[]) => {
      if (snapshot) {
        const values = [];
        let isRecordingsAvailable = false;
        snapshot.forEach((snap) => {
          isRecordingsAvailable = true;
          const otherData = snap.payload.doc.data().recordAsString
          ? JSON.parse(snap.payload.doc.data().recordAsString)
          : snap.payload.doc.data();
          const pieData =
            typeof otherData.pie === 'string'
              ? JSON.parse(otherData.pie)
              : otherData.pie;
          values.push({
            // @ts-ignore
            docId: snap.payload.doc.id,
            ...otherData,
            pie: Object.keys(pieData).map((key) => {
              const { data, labels, colors, title } = pieData[key];
              const result = [];
              const len = data.length;
              if (key === 'lungs') {
                for (let index = 0; index < len; index++) {
                  result.push({
                    name:
                      labels[index] === 'HAND'
                        ? `${colors[index].toUpperCase()} ${labels[index]}`
                        : `${labels[index]}`,
                    value: data[index],
                    colors,
                  });
                }
              } else {
                for (let index = 0; index < len; index++) {
                  result.push({
                    name: labels[index]
                      ? labels[index]
                      : `${labels[index]}${index}`,
                    value: data[index],
                    colors,
                  });
                }
              }
              return {
                key,
                result,
                title,
              };
            }),
            hearts: otherData.pulseData
              ? [
                  {
                    name: 'Green Hearts',
                    value: +otherData.pulseData.totalHearts.greenHearts,
                  },
                  {
                    name: 'Orange Hearts',
                    value: +otherData.pulseData.totalHearts.orangeHearts,
                  },
                  {
                    name: 'Red Hearts',
                    value: +otherData.pulseData.totalHearts.redHearts,
                  },
                  {
                    name: 'Yellow Hearts',
                    value: +otherData.pulseData.totalHearts.yellowHearts,
                  },
                ]
              : null,
          });
        });
        this.store.dispatch(
          new SaveRecordings({ recordings: values, isRecordingsAvailable })
        );
      }
    })
  );

  // @Effect({ dispatch: false })
  // loadOthersRecordingsData$ = this.actions$.pipe(
  //   ofType<LoadOthersRecordings>(ArchiveActionTypes.LoadOthersRecordings),
  //   switchMap(({ payload }) => {
  //     const { id, chartType } = payload;
  //     return this.afs
  //       .collection(`records/${id}/${chartType}`)
  //       .snapshotChanges();
  //   }),
  //   map((snapshot: DocumentChangeAction<any>[]) => {
  //     const values = [];
  //     let isOthersRecordingsAvailable = false;
  //     snapshot.forEach((snap) => {
  //       isOthersRecordingsAvailable = true;
  //       const otherData = JSON.parse(snap.payload.doc.data().recordAsString);
  //       const pieData =
  //         typeof otherData.pie === 'string'
  //           ? JSON.parse(otherData.pie)
  //           : otherData.pie;
  //       values.push({
  //         docId: snap.payload.doc.id,
  //         ...otherData,
  //         pie: Object.keys(pieData).map((key) => {
  //           const { data, labels, colors, title } = pieData[key];
  //           const result = [];
  //           const len = data.length;
  //           if(key === 'lungs') {
  //             for (let index = 0; index < len; index++) {
  //               result.push({
  //                 name: labels[index] === 'HAND'
  //                   ? `${colors[index].toUpperCase()} ${labels[index]}`
  //                   : `${labels[index]}`,
  //                 value: data[index],
  //                 colors,
  //               });
  //             }
  //           } else {
  //             for (let index = 0; index < len; index++) {
  //               result.push({
  //                 name: labels[index]
  //                   ? labels[index]
  //                   : `${labels[index]}${index}`,
  //                 value: data[index],
  //                 colors,
  //               });
  //             }
  //           }
  //           return {
  //             key,
  //             result,
  //             title,
  //           };
  //         }),
  //       });
  //     });
  //     this.store.dispatch(
  //       new SaveOthersRecordings({ othersRecordings: values, isOthersRecordingsAvailable })
  //     );
  //   })
  // );

  @Effect({ dispatch: false })
  loadSharedSChartsData$ = this.actions$.pipe(
    ofType<LoadSharedRecordings>(ArchiveActionTypes.LoadSharedRecordings),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, uid: storeState.uid };
    }),
    switchMap(({ payload, uid }) => {
      return this.afs
        .collection(`shared/${uid}/${payload.chartType}`)
        .snapshotChanges();
    }),
    map((snapshot: DocumentChangeAction<any>[]) => {
      const values = [];
      let isRecordingsAvailable = false;
      snapshot.forEach((snap) => {
        isRecordingsAvailable = true;
        const otherData = JSON.parse(snap.payload.doc.data().recordAsString);
        const pieData =
          typeof otherData.pie === 'string'
            ? JSON.parse(otherData.pie)
            : otherData.pie;
        values.push({
          // @ts-ignore
          docId: snap.payload.doc.id,
          ...otherData,
          pie: Object.keys(pieData).map((key) => {
            const { data, labels, colors, title } = pieData[key];
            const result = [];
            const len = data.length;
            for (let index = 0; index < len; index++) {
              result.push({
                name: labels[index]
                  ? labels[index]
                  : `${labels[index]}${index}`,
                value: data[index],
                colors,
              });
            }
            return {
              key,
              result,
              title,
            };
          }),
        });
      });
      this.store.dispatch(
        new SaveSharedRecordings({ recordings: values, isRecordingsAvailable })
      );
    })
  );

  @Effect({ dispatch: false })
  deleteRecord$ = this.actions$.pipe(
    ofType<DeleteRecord>(ArchiveActionTypes.DeleteRecord),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, id: storeState.id };
    }),
    switchMap(({ payload, id }) => {
      return this.afs
        .collection(`records/${id}/${payload.chartType}`)
        .doc(payload.id)
        .delete();
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    return of(new LoadRecordings({ chartType: 'Skin Response' }));
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private afd: AngularFireDatabase,
    private afs: AngularFirestore,
    private store: Store<AppState>
  ) {}
}
