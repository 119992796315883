import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-custom-video',
  templateUrl: './delete-custom-video.component.html',
  styleUrls: ['./delete-custom-video.component.scss']
})
export class DeleteCustomVideoComponent implements OnInit {

  videoName = '';

  constructor(
    public dialogRef: MatDialogRef<DeleteCustomVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.videoName = this.data;
  }

  confirmDelete() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close()
  }

}
