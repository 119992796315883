// Angular
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable, Subject} from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import {takeUntil} from 'rxjs/operators';
import {CurrentUserService} from '../../../../../services/current-user/current-user.service';
import {IUser} from '../../../../../core/interfaces/user';

@Component({
  selector: 'kt-user-profile4',
  templateUrl: './user-profile4.component.html',
})
export class UserProfile4Component implements OnInit {
  // Public properties
  completeSubject = new Subject();
  currentUser: IUser

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  constructor(
    private store: Store<AppState>,
    private firebaseUser: CurrentUserService,
    private cd: ChangeDetectorRef
  ) {
  }
  ngOnInit(): void {
    this.firebaseUser.getCurrentUser().subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
        this.cd.markForCheck();
        if (user.photoURL) {
          this.completeSubject.complete();
        }
      }
    })
  }
  logout() {
    this.store.dispatch(new Logout());
  }
}

