// Angular
import { Injectable} from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import {NavigationService} from '../../../../services/navigation/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from './translation.service';
import {CurrentUserService} from '../../../../services/current-user/current-user.service';
import {IUser} from '../../../interfaces/user';
import {take} from 'rxjs/operators';
import {ASIDE_CHARTS} from '../../../consts/aside-menu-variables';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {currentUser} from '../../../auth';
import {BrandComponent} from '../../../../views/theme/brand/brand.component';

@Injectable()
export class MenuConfigService {
  currentLanguage = '';


  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;


  /**
   * Service Constructor
   */
  constructor() {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    this.menuConfig = config;
    this.onConfigUpdated$.next(this.menuConfig);
  }
}
