import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MuscleDataService} from '../../../services/muscle-data.service';
import {GeniusChartService} from '../../../services/genius-chart/genius-chart.service';
import {interval, Subscription} from 'rxjs';
import {auditTime} from 'rxjs/operators';
import {MUSCLE_CMD_WRITE, MUSCLE_CONVERT_FAC} from '../../../core/consts/consts';
import {CalculateAverageService} from '../../../services/calculateAverage/calculate-average.service';
import {RecordService} from '../../../services/record/record.service';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {currentUser} from '../../../core/auth';
import {SimulatorService} from '../../../services/simulator/simulator.service';
import {NoteDialogComponent} from '../training-screen/note-dialog/note-dialog/note-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {IUser} from '../../../core/interfaces/user';
import {CurrentUserService} from '../../../services/current-user/current-user.service';
import {TourService} from 'ngx-ui-tour-md-menu';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kt-genius-chart',
  templateUrl: './genius-chart.component.html',
  styleUrls: ['./genius-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class GeniusChartComponent implements OnInit, OnDestroy {

  @ViewChild('mainLineChart', {static: true}) mainLineChart: any;
  sensorData;
  lineChart;
  dataType: string;
  chartTime = 0.1;
  selectedChannel = 'bp_ch1';
  selectedAverage = 30;
  maxChartZoom = 32;
  maxChartSeconds = 60;

  currentChannelValues = {
    delta: '0',
    theta: '0',
    alpha: '0',
    lowAlpha: '0',
    hiAlpha: '0',
    loBeta: '0',
    betaOne: '0',
    betaTwo: '0',
    hiBeta: '0',
    artefakt: '0',
  }

  middleChannelValues = {
    delta: 0,
    theta:0,
    alpha: 0,
    lowAlpha: 0,
    hiAlpha: 0,
    loBeta: 0,
    betaOne: 0,
    betaTwo: 0,
    hiBeta: 0,
    artefakt: 0,
  }

  timeArray = [];
  muscleDataSubscription: Subscription;
  dataConditionSubscription: Subscription;
  middleValuesSubscription: Subscription;
  refreshChartSubscription: Subscription;
  bluetoothServiceSubscription: Subscription;
  recordSubscription : Subscription;
  recordDateSubscription : Subscription;
  firebaseDataSubscription: Subscription;
  tourEventSubscription: Subscription;
  bluetoothService;
  currentChartTime: Date;
  isDataStreaming = false;
  zoomStep = 4;
  labelsTimeoutStarted = false;
  secondsArray = [];
  singleSecond = 0;
  singleValue = 0;
  chartLabelsLength: number;
  sessionStarted = false;
  dataArray = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  datasetInterval = null;
  chartUpdateInterval: number;
  isNeedRecord = false;
  recordData = {
    recordDate: new Date(),
    demoMode: false,
    chartType: 'MENU.AMPLITUDE_OVERVIEW',
    note: '',
    delta: {
      data : []
    },
    theta: {
      data : []
    },
    loAlpha: {
      data : []
    },
    alpha: {
      data : []
    },
    hiAlpha: {
      data : []
    },
    smrLoBeta: {
      data : []
    },
    betaOne: {
      data : []
    },
    betaTwo: {
      data : []
    },
    hiBeta: {
      data : []
    },
    artefakt: {
      data : []
    },
    timeArray: [],
    refreshInterval: 200,
    seconds: 0,
    isCompleted: true,
  }
  recordStartDate = '';
  userId = '';
  firebaseInterval;
  firebaseIntervalStarted = false;
  isSimulator = false;
  simulatorDataSubscription: Subscription;
  simulatorDataCondition: Subscription;
  currentUser: IUser;
  currentSensorData = [];
  dataIntervalSubscription: Subscription;
  secondsIntervalSubscription: Subscription;

  constructor(
    private muscleData: MuscleDataService,
    private chartService: GeniusChartService,
    private average: CalculateAverageService,
    private record: RecordService,
    private firebase: FirebaseService,
    private store: Store<AppState>,
    private simulatorService: SimulatorService,
    private amplitudeAverage : CalculateAverageService,
    public dialog: MatDialog,
    private firebaseUser: CurrentUserService,
    private tour: TourService,
    private translates: TranslateService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.getSettingsFromLocalStorage();
    this.createGeniusChart();
    this.getStreamingCondition();
    this.getSensorData();
    this.middleValuesInterval();
    this.subscribeToBluetoothService();
    this.getRecordCondition();
    this.getUserId();
    this.subscribeOnSimulatorMode();
    this.getUser();
  }

  ngOnDestroy(): void {
    this.dataConditionSubscription.unsubscribe();
    this.middleValuesSubscription.unsubscribe();
    this.muscleDataSubscription.unsubscribe();
    this.nullifyChart();
    this.mainLineChart.destroy();
    this.average.resetArrays();
    this.mainLineChart.data.labels = [];
    clearInterval(this.datasetInterval);
    if (this.recordSubscription) {
      this.recordSubscription.unsubscribe();
    }
    if(this.mainLineChart) {
      this.mainLineChart.destroy();
    }
    if(this.simulatorDataSubscription) {
      this.simulatorDataSubscription.unsubscribe();
    }
    if(this.simulatorDataCondition) {
      this.simulatorDataCondition.unsubscribe();
    }
    this.tour.end();
    if (this.dataIntervalSubscription) {
      this.dataIntervalSubscription.unsubscribe();
    }
    if (this.secondsIntervalSubscription) {
      this.secondsIntervalSubscription.unsubscribe();
    }
    this.sessionStarted = false;
  }

  async sendDataToActivateEEGmode(bluetoothService) {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x45,ON)`).buffer;
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
    this.bluetoothService = bluetoothService;
  }

  async impedance() {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x50,ON)`);
    const CmdWriteCharacteristic = await this.bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  getUser() {
    this.firebaseUser.getCurrentUser().subscribe((user: IUser) => {
      this.currentUser = user;
      if (user.showCoachMarks) {
        this.showCoachMarks();
      }
    });
  }

  showCoachMarks() {
    this.tour.initialize([
      {
        anchorId: 'channel-tour',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.SHOW_HIDE_TEXT')}`,
      },
      {
        anchorId: 'y-asis',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.Y_AXIS_TEXT')}`,
      },
      {
        anchorId: 'x-length',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.SECONDS_TEXT')}`,
      },
      {
        anchorId: 'average-presets',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.AVERAGE_TEXT')}`,
      },
      {
        anchorId: 'selected-average',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.AVERAGE_PRESETS')}`,
      },
      {
        anchorId: 'zoom-step',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.ZOOM_STEP_TEXT')}`,
      },
      {
        anchorId: 'delta-item',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.SHOW_HIDE_TEXT')}`,
      },
      {
        anchorId: 'line-chart',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.CENTER_CHART_TEXT')}`,
      },
      {
        anchorId: 'chart-zoom',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.ZOOM_BUTTONS')}`,
      },
      {
        anchorId: 'record-actions',
        content: `${this.translates.instant('SUPERVISOR_AMPLITUDE_COACHMARKS.RECORD_ACTIVITY')}`,
      },
    ]);
    if (this.currentUser?.role !== 'supervisor') {
      this.tour.steps.splice(9, 1);
    }
    this.tour.steps.forEach((step) => {
      step.nextBtnTitle = `${this.translates.instant('COACHMARKS_BUTTONS.NEXT')}`;
      step.prevBtnTitle = `${this.translates.instant('COACHMARKS_BUTTONS.PREV')}`
      step.enableBackdrop = true
    });
    setTimeout(() => {
      this.tour.start();
    },1000);
  }

  subscribeToBluetoothService(){
    this.bluetoothServiceSubscription = this.muscleData.bluetoothServiceSubject.subscribe((service) =>{
      if(service) {
        this.bluetoothService = service;
      }
    })
  }

  subscribeOnSimulatorMode() {
    this.simulatorDataCondition = this.simulatorService.isSimulatorMode.subscribe((isSimulator) => {
      // @ts-ignore
      this.isSimulator = isSimulator;
      if (this.isSimulator) {
        this.processSimulatorData();
      }
    })
  }

  processSimulatorData() {
    this.simulatorDataSubscription = this.simulatorService.simulatorData.subscribe((dataArray) => {
      this.currentSensorData = dataArray;
      this.processSensorDataSimulator(dataArray);
    })
  }

  getStreamingCondition() {
    this.dataConditionSubscription = this.muscleData.dataStreamingSubject.subscribe((dataCondition) => {
      if (dataCondition) {
        this.isDataStreaming = dataCondition;
      } else {
        if(this.refreshChartSubscription) {
          this.refreshChartSubscription.unsubscribe();
        }
      }
    });
  }

  getUserId() {
    this.store.pipe(select(currentUser)).subscribe(user => {
      if(user) {
        this.userId = user.id;
      }
    });
  }

  getRecordCondition() {
    this.recordSubscription = this.record.isNeedRecord.subscribe((recordCondition) => {
      this.isNeedRecord = recordCondition;
      if (this.isNeedRecord) {
        const dataInterval = interval(200);
        this.dataIntervalSubscription = dataInterval.subscribe(() => {
          this.processSensorDataToFirebase(this.currentSensorData);
        });
        const secondsInterval = interval(1000);
        this.secondsIntervalSubscription = secondsInterval.subscribe(() => {
          this.recordData.seconds++
        })
      } else {
        if (this.dataIntervalSubscription) {
          this.dataIntervalSubscription.unsubscribe();
        }
        if (this.secondsIntervalSubscription) {
          this.secondsIntervalSubscription.unsubscribe();
        }
      }
    });
    this.record.stopRecording.subscribe((condition) => {
      if (this.mainLineChart.data.datasets[0].data.length > 0 && condition) {
        this.isNeedRecord = false;
        this.dialog.open(NoteDialogComponent, {data: this.recordData}).afterClosed().subscribe((note) => {
          if (note) {
            this.recordData.note = note;
          }
          this.firebase.saveSession(this.userId, this.recordData, 'EEG');
          const timeout = setTimeout(() => {
            this.recordData.seconds = 0;
            this.nullifyFirebaseData();
            clearTimeout(timeout);
          },3000)
        })
      }
    })
  }

  getSensorData(): void {
    this.muscleDataSubscription = this.muscleData.muscleDataSubject.pipe(auditTime(0)).subscribe(async (data) => {
      if (data && data.data[this.selectedChannel]) {
        this.currentChartTime = new Date();
        this.dataType = data.data_type_id_text;
        this.parseSensorData(data.data[this.selectedChannel]);
        if (this.isDataStreaming && !this.sessionStarted) {
          this.sessionStarted = true;
          await this.sendDataToActivateEEGmode(this.bluetoothService);
        }
      } else {
        this.isDataStreaming = false;
      }
    })
  }

  parseSensorData(data) {
    if (data) {
      switch (this.dataType) {
        case 'EEG_BP' :
          this.processSensorData(data);
      }
    }
  }

  createGeniusChart() {
    let stepSize;
    if (this.maxChartSeconds <= 60) {
      stepSize = 1;
    } else if (this.maxChartSeconds >= 60) {
      stepSize = 10;
    }
    const lineChartCanvas = document.getElementById('mainLineChart');
    this.mainLineChart = this.chartService.createLineChart(lineChartCanvas, stepSize, this.maxChartZoom);
    this.createChartLabels(this.mainLineChart);
  }

  createDemoChart() {
    let stepSize;
    if (this.maxChartSeconds <= 60) {
      stepSize = 1;
    } else if (this.maxChartSeconds >= 60) {
      stepSize = 10;
    }
    if (this.mainLineChart) {
      this.mainLineChart.destroy();
    }
    const lineChartCanvas = document.getElementById('mainLineChart');
    this.mainLineChart = this.chartService.createLineChart(lineChartCanvas, stepSize, this.maxChartZoom, true);
    this.createChartLabels(this.mainLineChart);
  }

  createChartLabels(chart) {
    clearInterval(this.datasetInterval);
    this.datasetInterval = null;
    let divider;
    this.secondsArray = [];
    this.singleSecond = 0;
    this.singleValue = 0;
    if (this.maxChartSeconds <= 60) {
      divider = 10;
      this.chartUpdateInterval = 100;
    }
    if (this.maxChartSeconds > 60) {
      divider = 5;
      this.chartUpdateInterval = 200;
    }
    this.chartLabelsLength = divider * this.maxChartSeconds;
    this.singleValue = this.maxChartSeconds / this.chartLabelsLength;
    for (let i = 0; i < this.chartLabelsLength; i++) {
      this.singleSecond += this.singleValue;
      this.secondsArray.push(this.singleSecond.toFixed(2));
    }
    chart.data.labels = this.secondsArray;
    chart.update();
    this.cd.markForCheck();
    this.datasetsInterval();
  }

  processSensorDataToFirebase(dataArray) {
    if (!dataArray) {
      return;
    }
    if (this.isSimulator) {
      this.recordData.demoMode = true;
      this.recordData.delta.data.push(Number((dataArray[0])));
      this.recordData.theta.data.push(Number((dataArray[1])));
      this.recordData.loAlpha.data.push(Number((dataArray[2])));
      this.recordData.alpha.data.push(Number((dataArray[3])));
      this.recordData.hiAlpha.data.push(Number((dataArray[4])));
      this.recordData.smrLoBeta.data.push(Number((dataArray[5])));
      this.recordData.betaOne.data.push(Number((dataArray[6])));
      this.recordData.betaTwo.data.push(Number((dataArray[7])));
      this.recordData.hiBeta.data.push(Number((dataArray[8])));
      this.recordData.artefakt.data.push(Number((dataArray[9])));
    } else {
      this.recordData.demoMode = false;
      this.recordData.delta.data.push(Number((dataArray[0] * MUSCLE_CONVERT_FAC)));
      this.recordData.theta.data.push(Number((dataArray[1] * MUSCLE_CONVERT_FAC)));
      this.recordData.loAlpha.data.push(Number((dataArray[2] * MUSCLE_CONVERT_FAC)));
      this.recordData.alpha.data.push(Number((dataArray[3] * MUSCLE_CONVERT_FAC)));
      this.recordData.hiAlpha.data.push(Number((dataArray[4] * MUSCLE_CONVERT_FAC)));
      this.recordData.smrLoBeta.data.push(Number((dataArray[5] * MUSCLE_CONVERT_FAC)));
      this.recordData.betaOne.data.push(Number((dataArray[6] * MUSCLE_CONVERT_FAC)));
      this.recordData.betaTwo.data.push(Number((dataArray[7] * MUSCLE_CONVERT_FAC)));
      this.recordData.hiBeta.data.push(Number((dataArray[8] * MUSCLE_CONVERT_FAC)));
      this.recordData.artefakt.data.push(Number((dataArray[9] * MUSCLE_CONVERT_FAC)));
    }
  }

  processSensorData(dataArray) {
    this.currentSensorData = dataArray;
    dataArray.forEach((value, index) => {
      const sensorValue = this.average.amplitudeAverage(+(value * MUSCLE_CONVERT_FAC).toFixed(2), this.selectedAverage * 25, index);
      this.currentChannelValue(index, sensorValue);
      if (sensorValue) {
        this.dataArray[index].push(sensorValue);
      }
    });
  }

  processSensorDataSimulator(dataArray) {
    dataArray.forEach((value, index) => {
      const sensorValue = this.average.amplitudeAverage(+value, this.selectedAverage * 25, index);
      this.currentChannelValue(index, sensorValue);
      if (sensorValue) {
        this.dataArray[index].push(sensorValue);
      }
    });
  }

  datasetsInterval() {
    if (this.datasetInterval) {
      return;
    }

    this.datasetInterval = setInterval(() => {
      this.dataArray.forEach((values, index) => {
        if (this.dataArray[index].length >= 3) {
          this.dataArray[index].splice(3);
        }
      })

      if (this.mainLineChart.data.datasets[0].data.length >= this.chartLabelsLength) {
        this.mainLineChart.data.datasets[0].data.length = this.chartLabelsLength;
        this.dataArray.forEach((values, index) => {
          let sum = 0;
          values.forEach((value) => {
            sum += +value;
          });
          const avg = sum / values.length;
          // this.mainLineChart.data.datasets[index].data.pop();
          this.mainLineChart.data.datasets[index].data.shift();
          // this.mainLineChart.data.datasets[index].data.unshift(avg);
          this.mainLineChart.data.datasets[index].data.push(avg);
        })
      }
      if (this.mainLineChart.data.datasets[0].data.length < this.chartLabelsLength) {
        this.dataArray.forEach((values, index) => {
          if (values.length > 0) {
            let sum = 0;
            values.forEach((value) => {
              sum += +value;
            });
            const avg = sum / values.length;
            if (avg) {
              this.mainLineChart.data.datasets[index].data.push(avg);
            }
          }
        })
      }
      this.clearDataArray();
      this.mainLineChart.update();
      if (this.mainLineChart.data.datasets[0].data.length) {
        this.cd.markForCheck();
      }
    }, this.chartUpdateInterval);
  }

  changeChartUpdateInterval($event) {
    if($event.target.value > 600 ) {
      this.maxChartSeconds = 600;
      this.saveSettingsToLocalStorage();
      setTimeout(() => {
        this.nullifyChart();
        this.createGeniusChart();
      },1000)
      this.saveSettingsToLocalStorage();
      this.cd.markForCheck();
      return;
    }
    this.maxChartSeconds = +$event.target.value;
    if(this.refreshChartSubscription) {
      this.refreshChartSubscription.unsubscribe();
    }
    setTimeout(() => {
      this.nullifyChart();
      if (this.isSimulator) {
        this.createDemoChart();
      } else {
        this.createGeniusChart();
      }
    },1000)
    this.saveSettingsToLocalStorage();
  }

  changeMovingAverage($event) {
    this.selectedAverage = +$event.target.value;
    this.average.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  changeLongAveraging($event) {
    this.selectedAverage = +$event.value;
    this.average.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  currentChannelValue(index, value) {
    switch (index) {
      case 0 :
        this.currentChannelValues.delta = value;
        break;
      case 1 :
        this.currentChannelValues.theta = value;
        break;
      case 2 :
        this.currentChannelValues.lowAlpha = value;
        break;
      case 3 :
        this.currentChannelValues.alpha = value;
        break;
      case 4 :
        this.currentChannelValues.hiAlpha = value;
        break;
      case 5 :
        this.currentChannelValues.loBeta = value;
        break;
      case 6 :
        this.currentChannelValues.betaOne = value;
        break;
      case 7 :
        this.currentChannelValues.betaTwo = value;
        break;
      case 8 :
        this.currentChannelValues.hiBeta = value;
        break;
      case 9 :
        this.currentChannelValues.artefakt = value;
    }
  }

  calculateAverageValues(): void {
    const dataItems = this.mainLineChart.data.datasets;
    let maxLength;
    if (this.maxChartSeconds <= 60) {
      maxLength = 50
    }
    if (this.maxChartSeconds > 60) {
      maxLength = 25;
    }
    if (dataItems[0].data.length >= maxLength) {
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.delta = (dataItems[0].data.slice(Math.max(dataItems[0].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.theta = (dataItems[1].data.slice(Math.max(dataItems[1].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.lowAlpha = (dataItems[2].data.slice(Math.max(dataItems[2].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.alpha = (dataItems[3].data.slice(Math.max(dataItems[3].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.hiAlpha = (dataItems[4].data.slice(Math.max(dataItems[4].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.loBeta = (dataItems[5].data.slice(Math.max(dataItems[5].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.betaOne = (dataItems[6].data.slice(Math.max(dataItems[6].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.betaTwo = (dataItems[7].data.slice(Math.max(dataItems[7].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.hiBeta = (dataItems[8].data.slice(Math.max(dataItems[8].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
      // tslint:disable-next-line:max-line-length
      this.middleChannelValues.artefakt = (dataItems[9].data.slice(Math.max(dataItems[9].data.length - maxLength, 0))[Math.floor(length / 2)]).toFixed(2);
    }
  }

  middleValuesInterval() {
    const refreshInterval = interval(1000);
    this.middleValuesSubscription = refreshInterval.subscribe(val => this.calculateAverageValues())
  }

  triggerChartLabel(index: number, condition: boolean) {
    this.mainLineChart.data.datasets[index].hidden = !condition;
    this.mainLineChart.update();
  }

  changeChartZoomLevel($event) {
    if ($event.target.value < 0) {
      $event.target.value = 0;
      return;
    }
    this.mainLineChart.options.scales.yAxes[0].ticks.max = +$event.target.value;
    this.mainLineChart.update();
    this.cd.markForCheck();
    this.saveSettingsToLocalStorage();
  }

  changeChannel($event) {
    this.selectedChannel = $event.value;
    this.average.resetArrays();
    this.saveSettingsToLocalStorage();
  }

  zoomInChart() {
    this.mainLineChart.options.scales.yAxes[0].ticks.max = this.mainLineChart.options.scales.yAxes[0].ticks.max + +this.zoomStep;
    this.maxChartZoom = this.mainLineChart.options.scales.yAxes[0].ticks.max;
    this.mainLineChart.update();
    this.saveSettingsToLocalStorage();
  }

  zoomOutChart() {
    this.mainLineChart.options.scales.yAxes[0].ticks.max = this.mainLineChart.options.scales.yAxes[0].ticks.max - +this.zoomStep;
    this.maxChartZoom = this.mainLineChart.options.scales.yAxes[0].ticks.max;
    this.mainLineChart.update();
    this.saveSettingsToLocalStorage();
  }

  getSettingsFromLocalStorage() {
    if (localStorage.length > 0 && localStorage.getItem('amplitudeSettings')) {
      const settings = JSON.parse(localStorage.getItem('amplitudeSettings'));
      this.selectedChannel = settings.selectedChannel;
      this.maxChartZoom = settings.maxChartZoom;
      this.maxChartSeconds = settings.maxChartSeconds;
      this.selectedAverage = settings.selectedAverage;
      this.zoomStep = settings.zoomStep;

      setTimeout(() => {
        this.mainLineChart.options.scales.yAxes[0].ticks.max = +settings.maxChartZoom;
        this.mainLineChart.update();
        this.cd.markForCheck();
      },100)
    }
  }

  saveSettingsToLocalStorage() {
    const settings = JSON.parse(localStorage.getItem('amplitudeSettings'));
    if (settings) {
      localStorage.removeItem('amplitudeSettings');
    }
    const amplitudeSettings = {
      selectedChannel: this.selectedChannel,
      maxChartZoom: this.maxChartZoom,
      maxChartSeconds: this.maxChartSeconds,
      selectedAverage: this.selectedAverage,
      zoomStep: this.zoomStep,
    }
    localStorage.setItem('amplitudeSettings', JSON.stringify(amplitudeSettings));
  }

  clearDataArray() {
    this.dataArray = [[], [], [], [], [], [], [], [], [], [],];
  }

  nullifyChart() {
    this.timeArray = [];
    this.dataArray = [[], [], [], [], [], [], [], [], [], [],];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.mainLineChart.data.datasets.length; i++) {
      this.mainLineChart.data.datasets[i].data = [];
    }
    this.mainLineChart.update();
    this.mainLineChart.data.labels = [];
  }

  nullifyFirebaseData() {
    this.recordData.delta.data = [];
    this.recordData.theta.data = [];
    this.recordData.loAlpha.data = [];
    this.recordData.alpha.data = [];
    this.recordData.hiAlpha.data = [];
    this.recordData.smrLoBeta.data = [];
    this.recordData.betaOne.data = [];
    this.recordData.betaTwo.data = [];
    this.recordData.hiBeta.data = [];
    this.recordData.artefakt.data = [];
    this.recordData.timeArray = [];
  }
}
