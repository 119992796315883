import { createAction, props, Action } from '@ngrx/store';

export enum ArchiveActionTypes {
  LoadRecordings = '[LoadRecordings] Action',
  SaveRecordings = '[SaveRecordings] Action',
  LoadSharedRecordings = '[LoadSharedRecordings] Action',
  SaveSharedRecordings = '[SaveSharedRecordings] Action',
  DeleteRecord = '[Delete Record] Action',
  LoadOthersRecordings = '[LoadOthersRecordings] Action',
  SaveOthersRecordings = '[SaveOthersRecordings] Action',
  SetSharedIdorEmail = '[SetSharedIdorEmail] Action'
}

export class LoadRecordings implements Action {
  readonly type = ArchiveActionTypes.LoadRecordings;
  constructor(public payload: { chartType: string }) {}
}

export class SaveRecordings implements Action {
  readonly type = ArchiveActionTypes.SaveRecordings;
  constructor(
    public payload: { recordings: any[]; isRecordingsAvailable: boolean }
  ) {}
}

export class LoadSharedRecordings implements Action {
  readonly type = ArchiveActionTypes.LoadRecordings;
  constructor(public payload: { chartType: string }) {}
}

export class SaveSharedRecordings implements Action {
  readonly type = ArchiveActionTypes.SaveSharedRecordings;
  constructor(
    public payload: { recordings: any[]; isRecordingsAvailable: boolean }
  ) {}
}

export class LoadOthersRecordings implements Action {
  readonly type = ArchiveActionTypes.LoadOthersRecordings;
  constructor(public payload: { id: string; chartType: string }) {}
}

export class SetSharedIdorEmail implements Action {
  readonly type = ArchiveActionTypes.LoadOthersRecordings;
  constructor(public payload: { id: string; chartType: string }) {}
}

export class SaveOthersRecordings implements Action {
  readonly type = ArchiveActionTypes.SaveOthersRecordings;
  constructor(
    public payload: {
      othersRecordings: any[];
      isOthersRecordingsAvailable: boolean;
    }
  ) {}
}

export class DeleteRecord implements Action {
  readonly type = ArchiveActionTypes.DeleteRecord;
  constructor(public payload: { id: string; chartType: string }) {}
}

// export class SetChartType implements Action {
//   readonly type = ChartsActionTypes.SetChartType;
//   constructor(public payload: { chartType: string }) { }
// }

export type ArchiveActions =
  | LoadRecordings
  | SaveRecordings
  | DeleteRecord
  | LoadSharedRecordings
  | SaveSharedRecordings
  | LoadOthersRecordings
  | SaveOthersRecordings;
