import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {

  constructor(
    private toast: ToastrService
  ) { }

  public message(message: string, title: string, toastClass: string, timeout?): void {
    this.toast.show(message, title,  {
      toastClass,
      timeOut: timeout ? timeout : 5000
    });
  }
}
