// Angular
import {AfterViewInit, ChangeDetectorRef, Component, Injectable, OnInit} from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import {RecordService} from '../../../services/record/record.service';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
  // Public properties
  headerLogo = '';
  brandClasses = '';
  asideSelfMinimizeToggle = true;
  collapseData = false;

  toggleOptions: ToggleOptions = {
    target: 'kt_body',
    targetState: 'aside-minimize',
    toggleState: 'active'
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   * @param record: Check for collapse record data
   */
  constructor(private layoutConfigService: LayoutConfigService, public htmlClassService: HtmlClassService, public record: RecordService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.headerLogo = this.getAsideLogo();
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');
  }

  /**
   * On after view init
   */
  ngAfterViewInit(): void {
  }

  getAsideLogo() {
    let result = 'logo_mindfield_mitDomain_inside.png';
    const brandSelfTheme = this.layoutConfigService.getConfig('brand.self.theme') || '';
    if (brandSelfTheme === 'light') {
      result = 'logo_mindfield_mitDomain_inside.png';
    }
    return `./assets/media/logos/${result}`;
  }

  public toggleAsideClick() {
    this.collapseData = !this.collapseData;
    this.record.columnData.next(this.collapseData);
    document.body.classList.toggle('aside-minimize');
  }
}
