<kt-portlet>
  <ng-container *ngIf="isAvailable; else elseTemplate">
    <kt-portlet-body>
      <h2>{{ name }}</h2>
      <br />
      <ng-container *ngIf="type !== 'Respiration'; else elseTemplate">
        <div fxLayout="row wrap" fxLayoutAlign="center end" fxLayoutGap="10px">
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.TIME" | translate }}</h5>
            <p>{{ timeFormatting(time) }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.MINIMUM" | translate }}</h5>
            <p>{{ minValue }} {{ sign }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.MAXIMUM" | translate }}</h5>
            <p>{{ maxValue }} {{ sign }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.CURRENT_VALUE" | translate }}</h5>
            <p>{{ currentValue }} {{ sign }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.AVERAGE_OF_SESSION" | translate }}</h5>
            <p>{{ avg }} {{ sign }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.DIFFERENCE" | translate }}</h5>
            <p>{{ maxDMin }} {{ sign }}</p>
          </div>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.TIME" | translate }}</h5>
            <p>{{ timeFormatting(time) }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.COHERENCE" | translate }}</h5>
            <p>{{ coherence }}%</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.BREATHING" | translate }}</h5>
            <p>{{ currentValue }} {{ sign }}</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.REGULARITY" | translate }}</h5>
            <p>{{ regularity }} RA</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.CURRENT_CALCULATED_RA" | translate }}</h5>
            <p>{{ calculatedRA }} RA</p>
          </div>
          <div
            fxFlex="0 1 calc(20% - 10px)"
            fxFlex.lt-md="0 1 calc(50% - 32px)"
            fxFlex.lt-sm="100%"
          >
            <h5>{{ "LIVE_VIEW.BREATH_PER_MINUTE" | translate }}</h5>
            <p>{{ breathMin }} {{ sign }}</p>
          </div>
        </div>
      </ng-template>

      <div fxLayout="row">
        <div
          fxFlex="0 1 calc(7%)"
          *ngIf="type === 'Skin Response' && direction"
        >
          <img
            src="assets/media/arrows/up-red-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <div
          fxFlex="0 1 calc(7%)"
          *ngIf="type === 'Skin Response' && !direction"
        >
          <img
            src="assets/media/arrows/down-green-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Temperature' && direction">
          <img
            src="assets/media/arrows/up-green-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Temperature' && !direction">
          <img
            src="assets/media/arrows/down-red-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <!-- <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Pulse' && direction">
          <img
            src="assets/media/arrows/up-green-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Pulse' && !direction">
          <img
            src="assets/media/arrows/down-red-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div> -->
        <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Respiration' && direction">
          <img
            src="assets/media/arrows/up-yellow-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <div fxFlex="0 1 calc(7%)" *ngIf="type === 'Respiration' && !direction">
          <img
            src="assets/media/arrows/down-orange-arrow.svg"
            width="150px"
            height="400px"
            alt=""
          />
        </div>
        <ng-container *ngIf="type === 'Pulse'; else pulseTemplate">
          <div fxFlex="0 1 calc(75%)">
            <ngx-charts-line-chart
              [scheme]="colorScheme"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [yScaleMin]="yScaleMin"
              [yScaleMax]="yScaleMax"
              [xAxisTickFormatting]="xAxisFormatting"
              [results]="pulse.data"
            >
            </ngx-charts-line-chart>
          </div>
        </ng-container>
        <ng-template #pulseTemplate>
          <div fxFlex="0 1 calc(75%)">
            <ngx-charts-line-chart
              [scheme]="colorScheme"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [yScaleMin]="yScaleMin"
              [yScaleMax]="yScaleMax"
              [xAxisTickFormatting]="xAxisFormatting"
              [results]="pulse.data"
            >
            </ngx-charts-line-chart>
          </div>
        </ng-template>
        <div fxFlex="0 1 calc(5% - 10px)">
          <div style="height: 90%; width: 150px">
            <ngx-charts-bar-vertical
              [scheme]="colorScheme"
              [results]="single"
              [gradient]="gradient"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [showXAxisLabel]="''"
              [showYAxisLabel]="showYAxisLabel"
              [yScaleMin]="yScaleMin"
              [yScaleMax]="yScaleMax"
              [yAxisLabel]="yAxisLabel"
            >
            </ngx-charts-bar-vertical>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="0 1 calc(10%)"></div>
        <div class="custom-slider" fxFlex="0 1 calc(75%)" [ngClass]="{'green': type === 'Skin Response', 'yellow': type === 'Respiration', 'red': type === 'Pulse', 'orange': type === 'Temperature'}" style="z-index: 2000; width: 80%; padding-left: 100px;" (click)="$event.preventDefault()">
          <!-- <mat-slider (click)="$event.stopPropagation()"  (change)="$event.stopPropagation()"
          (input)="$event.stopPropagation()"></mat-slider> -->
          <ngx-slider [(value)]="sliderValue" [options]="options" (valueChange)="onSliderChange($event)"></ngx-slider>
        </div>
      </div>

    </kt-portlet-body>
  </ng-container>
  <ng-template #elseTemplate>
    <kt-portlet-body [ngStyle]="{'display': sensorType === 'Esense Genius' || sensorType === 'Esense Raw' || sensorType === 'Esense Ratio' && !isAvailable ? 'none' : 'block' }">
      <h2>{{ "LIVE_VIEW.NO_LIVE_VIEW" | translate }}</h2>
    </kt-portlet-body>
  </ng-template>
  <kt-genius-chart *ngIf="sensorType === 'Esense Genius'"></kt-genius-chart>
  <kt-raw-chart *ngIf="sensorType === 'Esense Raw'"></kt-raw-chart>
  <kt-ratio-chart *ngIf="sensorType === 'Esense Ratio'"></kt-ratio-chart>
</kt-portlet>
