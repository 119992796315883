// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import {
  filter,
  mergeMap,
  tap,
  withLatestFrom,
  map,
  switchMap,
  catchError,
  take,
} from 'rxjs/operators';
import { defer, Observable, of, from } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested,
  UpdateUser,
  SaveGroups,
  FetchGroups,
  UpdateLoginTime,
  FetchPlans,
  SavePlans,
} from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded, currentUser } from '../_selectors/auth.selectors';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RevenueCat } from 'src/app/views/pages/profile/revenue-api.model';
import * as moment from 'moment-timezone';

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap((action) => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
      this.store.dispatch(new UserRequested());
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: this.returnUrl },
      });
      // document.location.reload();
    })
  );

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap((action) => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
    })
  );

  @Effect({ dispatch: false })
  updateUser$ = this.actions$.pipe(
    ofType<UpdateUser>(AuthActionTypes.UpdateUser),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([action, storeState]) => {
      return { payload: action.payload, uid: storeState.uid };
    }),
    switchMap(({ payload, uid }) => {
      return from(this.af.collection('users').doc(uid).update(payload.user));
    })
  );

  @Effect({ dispatch: false })
  updateLoginTime$ = this.actions$.pipe(
    ofType<UpdateLoginTime>(AuthActionTypes.UpdateLoginTime),
    map((action) => {
      return { uid: action.payload.uid };
    }),
    switchMap(({ uid }) => {
      const lastLogin = new Date();
      return from(
        this.af
          .collection('users')
          .doc(uid)
          .update({ lastLogin: lastLogin.toString() })
      );
    })
  );

  @Effect({ dispatch: false })
  fetchGroups$ = this.actions$.pipe(
    ofType<FetchGroups>(AuthActionTypes.FetchGroups),
    map((action) => {
      return { id: action.payload.id };
    }),
    switchMap(({ id }) => {
      return this.af
        .collection('groups', (ref) => ref.where('users', 'array-contains', id))
        .valueChanges();
    }),
    tap((v) => {
      this.store.dispatch(new SaveGroups({ groups: v }));
    })
  );

  // @Effect({ dispatch: false })
  // fetchPlans$ = this.actions$.pipe(
  //   ofType<FetchPlans>(AuthActionTypes.FetchPlans),
  //   map((action) => {
  //     return { uid: action.payload.uid };
  //   }),
  //   // @ts-ignore
  //   switchMap(({ uid }) => {
  //     const headers = new HttpHeaders();
  //     // TODO HEADERS
  //     headers.set('Authorization', 'Bearer aAjctpllKMQleLuAzutuhzOMsNnAzisJ');
  //     headers.set('Content-Type', 'application/json');
  //     return this.http.get(`https://api.revenuecat.com/v1/subscribers/${uid}`, {
  //       headers,
  //     });
  //   }),
  //   take(1),
  //   tap((v: RevenueCat) => {
  //     const plans = Object.keys(v.subscriber.entitlements).map((key) => {
  //       return {
  //         key,
  //         ...v.subscriber.entitlements[key],
  //       };
  //     }).filter(a => moment().tz('Europe/Berlin').isBefore(moment(a.expires_date).tz('Europe/Berlin')))
  //     .map(c => {
  //       return {
  //         ...c,
  //         purchase_date: moment(c.purchase_date).tz('Europe/Berlin').toString(),
  //         expires_date: moment(c.expires_date).tz('Europe/Berlin').toString()
  //       }
  //     })
  //     this.store.dispatch(new SavePlans({ plans }));
  //   })
  // );

  @Effect({ dispatch: false })
  loadUser$ = this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([action, _isUserLoaded]) => !_isUserLoaded),
    mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
    tap((_user) => {
      if (_user) {
        this.store.dispatch(new UserLoaded({ user: _user }));
      } else {
        // this.store.dispatch(new Logout());
      }
    })
  );

  @Effect()
  init$: Observable<Action> = defer(() => {
    const userToken = localStorage.getItem(environment.authTokenKey);
    let observableResult = of({ type: 'NO_ACTION' });
    if (userToken) {
      observableResult = of(new Login({ authToken: userToken, uid: null }));
    }
    return observableResult;
  });

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private af: AngularFirestore,
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
