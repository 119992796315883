import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartialsModule } from '../../partials/partials.module';
import { CoreModule } from 'src/app/core/core.module';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveViewComponent } from './live-view.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ChartsEffects } from 'src/app/core/charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from '@rinminase/ng-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PermissionEffects, permissionsReducer, RoleEffects, RoleGuard, rolesReducer } from './../../../core/auth';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {GeniusChartComponent} from '../genius-chart/genius-chart.component';
import {MaterialModule} from '../../../material/material.module';
import {RawChartComponent} from '../raw-chart/raw-chart.component';
import {RatioChartComponent} from '../ratio-chart/ratio-chart.component';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';
import {ChannelOneDotsComponent} from '../channel-one-dots/channel-one-dots.component';

@NgModule({
  imports: [
    CommonModule,
    PartialsModule,
    CoreModule,
    NgApexchartsModule,
    RouterModule.forChild([
      {
        path: '',
        component: LiveViewComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgxChartsModule,
    ChartsModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature('roles', rolesReducer),
    StoreModule.forFeature('permissions', permissionsReducer),
    EffectsModule.forFeature([PermissionEffects, RoleEffects, ChartsEffects]),
    AngularFireAnalyticsModule,
    NgxGoogleAnalyticsModule,
    NgxSliderModule,
    MaterialModule,
    TourMatMenuModule,
  ],
  providers: [
    RoleGuard,
  ],
  declarations: [
    LiveViewComponent,
    GeniusChartComponent,
    RawChartComponent,
    RatioChartComponent,
    ChannelOneDotsComponent
  ],
  exports: [
    ChannelOneDotsComponent
  ],
  entryComponents: []
})
export class LiveViewModule { }
