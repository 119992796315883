export const MUSCLE_SERVICE_ID = '180D';
export const MUSCLE_EXG_SERVICE = '5e6da50a-7fcc-11eb-9439-0242ac130002'
export const MUSCLE_CHARACTERISTIC_ID = '2A37';
export const MUSCLE_CONVERT_FAC = 0.023842;

// Muscle CMD via Nordic UART service
export const MUSCLE_CMD_SERVICE = '6e400001-b5a3-f393-e0a9-e50e24dcca9e'; // Nordic UART service
export const MUSCLE_CMD_READ = '6e400003-b5a3-f393-e0a9-e50e24dcca9e'; // cmd read and notify response, Nordic UART service
export const MUSCLE_CMD_WRITE = '6e400002-b5a3-f393-e0a9-e50e24dcca9e'; // cmd write, Nordic UART service
export const EXG_MEASUREMENT = '5e6da762-7fcc-11eb-9439-0242ac130002'; // exg data characteristic

export const RATIO_CHANNELS = [
  {
    name: 'delta',
    firebaseRatioName: 'delta',
    id:0
  },
  {
    name: 'theta',
    firebaseRatioName: 'theta',
    id:1
  },
  {
    name: 'alpha',
    firebaseRatioName: 'alpha',
    id:3
  },
  {
    name: 'lo-alpha',
    firebaseRatioName: 'loAlpha',
    id:2
  },
  {
    name: 'hi-alpha',
    firebaseRatioName: 'hiAlpha',
    id:4
  },
  {
    name: 'smr, lo-beta',
    firebaseRatioName: 'smrLoBeta',
    id:5
  },
  {
    name: 'beta 1',
    firebaseRatioName: 'betaOne',
    id:6
  },
  {
    name: 'beta 2',
    firebaseRatioName: 'betaTwo',
    id:7
  },
  {
    name: 'hi-Beta',
    firebaseRatioName: 'hiBeta',
    id:8
  },
  {
    name: 'artefakt',
    firebaseRatioName: 'artefakt',
    id:9
  },
]

export const dataBySignals = ['delta', 'theta', 'alpha', 'loAlpha', 'hiAlpha', 'smrLoBeta', 'betaOne', 'betaTwo', 'hiBeta', 'artefakt'];

export const DEFAULT_CONFIGURATION_PART = [
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.CLOSE_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    customText:'',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 10,
    imagePath: '',
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.OPEN_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 20,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 30,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 40,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.BE_QUIET',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 50,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.READ_TEXT',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 60,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.CLOSE_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 70,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.CLOSE_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 80,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.CLOSE_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 90,
    imagePath: ''
  },
  {
    title: 'Part',
    enabled: true,
    playAudio: false,
    selectedOption: 'DEFAULT_TEXT_OPTION.CLOSE_EYES',
    uploadedImage: '',
    audioUrl: '',
    audioName: '',
    defaultText: [
      {titleText: 'DEFAULT_TEXT_OPTION.CLOSE_EYES'},
      {titleText:'DEFAULT_TEXT_OPTION.OPEN_EYES'},
      {titleText: 'DEFAULT_TEXT_OPTION.ONE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.SEVEN_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.THREE_COUNTDOWN'},
      {titleText: 'DEFAULT_TEXT_OPTION.BE_QUIET'},
      {titleText: 'DEFAULT_TEXT_OPTION.READ_TEXT'},
      {titleText: 'DEFAULT_TEXT_OPTION.LISTEN_MUSIC'},
    ],
    seconds: 100,
    imagePath: ''
  }
]

export const TRAINING_CONFIGURATION = [
  {
    checked: true,
    length: '10',
    trainingProtocol: 'ratio',
    frequencyOne: 4,
    frequencyTwo: 1,
    threshHoldType: 'reward',
    thresholdDifficult: '70-80%',
    youtubePlaylistUrl: '',
    note: '',
    customText: '',
    showLineChart: true,
    showBarChart: true,
    showVideo: true,
    paybackSpeed: 1,
    feedBackType: 'startStop'
  },
  {
    checked: true,
    length: '10',
    trainingProtocol: 'ratio',
    frequencyOne: 3,
    frequencyTwo: 6,
    threshHoldType: 'reward',
    thresholdDifficult: '80-90%',
    youtubePlaylistUrl: '',
    note: '',
    customText: '',
    showLineChart: false,
    showBarChart: false,
    showVideo: true,
    paybackSpeed: 1,
    feedBackType: 'startStop'
  },
  {
    checked: true,
    length: '10',
    trainingProtocol: 'ratio',
    frequencyOne: 2,
    frequencyTwo: 6,
    threshHoldType: 'reward',
    thresholdDifficult: '80-90%',
    youtubePlaylistUrl: '',
    note: '',
    customText: '',
    showLineChart: false,
    showBarChart: false,
    showVideo: true,
    paybackSpeed: 1,
    feedBackType: 'startStop'
  }
]

export const MODERATOR_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6Im1pbmRmaWVsZG1lZXRpbmciLCJzdWIiOiJtZWV0aW5nLm1pbmRmaWVsZC5kZSIsInJvb20iOiIqIiwibW9kZXJhdG9yIjp0cnVlfQ.dw3KopkPdtsD9d2LF-FxELCpj68g1cKZlmZ3Bl1SRvU';
export const NORMAL_USER_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6Im1pbmRmaWVsZG1lZXRpbmciLCJzdWIiOiJtZWV0aW5nLm1pbmRmaWVsZC5kZSIsInJvb20iOiIqIiwibW9kZXJhdG9yIjpmYWxzZX0.l6H7xl7tC-OJSQ9PlKZmvR0FE2QNz9ExUyVzp-4UTbU';
