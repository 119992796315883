import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  private youtubeToken = 'AIzaSyB8NWGxzoPjMEn5hSeqywN5JoQOrM-g8tk';
  private youtubeUrl = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId'

  constructor(
    private http: HttpClient
  ) { }

  getPlayList(playListUrl: string) {
    const headers = new HttpHeaders();
    headers.set('Authorization', 'Bearer aAjctpllKMQleLuAzutuhzOMsNnAzisJ');
    headers.set('Content-Type', 'application/json');
    return this.http.get(`${this.youtubeUrl}=${playListUrl}&key=${this.youtubeToken}`, {
      headers
    });
  }

  getTRainerPlaylist(playListUrl: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', 'Bearer aAjctpllKMQleLuAzutuhzOMsNnAzisJ');
    headers.set('Content-Type', 'application/json');
     return this.http.get(`${this.youtubeUrl}=${playListUrl}&key=${this.youtubeToken}`, {
       headers
     });
  }
}
