import { Injectable } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/storage";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  storageUsedInMb = 0;
  maximumStorageAllowedMb = 5000;
  storageUsedSubject = new Subject<number>();

  constructor(
    private afstorage: AngularFireStorage,
  ) { }

  calculateUserStorage(userId) {
    this.storageUsedInMb = 0;
    const videosUrl = `TrainingVideos/${userId}`;
    const ref = this.afstorage.ref(videosUrl);
    ref.listAll().subscribe((data) => {
      data.items.map((video) =>
        video.getMetadata().then((metadata) => {
          this.storageUsedInMb += this.bytesToMB(metadata.size);
          this.storageUsedSubject.next(this.storageUsedInMb);
        })
      );
    });
  }
  bytesToMB(bytes) {
    return +(bytes / (1024 * 1024)).toFixed(1);
  }
  getUserStorageUsed() {
    return this.storageUsedInMb;
  }
}
