import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OtherChartAverageService {

  deltaArray = [];
  thetaArray = [];
  alphaArray = [];
  lowAlphaArray = [];
  hiAlphaArray = [];
  loBetaArray = [];
  betaOneArray = [];
  betaTwoArray = [];
  hiBetaArray = [];
  artefaktArray = [];

  deltaArrayRatio = [];
  thetaArrayRatio  = [];
  alphaArrayRatio  = [];
  lowAlphaArrayRatio  = [];
  hiAlphaArrayRatio  = [];
  loBetaArrayRatio  = [];
  betaOneArrayRatio  = [];
  betaTwoArrayRatio  = [];
  hiBetaArrayRatio  = [];
  artefaktArrayRatio  = [];

  constructor() { }

  amplitudeAverage(inputValue: number, maxLength: number, index: number) {
    switch (index) {
      case 0 :
        return this.calculatedAverage(inputValue, maxLength, this.deltaArray);
      case 1 :
        return this.calculatedAverage(inputValue, maxLength, this.thetaArray);
      case 2 :
        return this.calculatedAverage(inputValue, maxLength, this.lowAlphaArray);
      case 3 :
        return this.calculatedAverage(inputValue, maxLength, this.alphaArray);
      case 4 :
        return this.calculatedAverage(inputValue, maxLength, this.hiAlphaArray);
      case 5 :
        return this.calculatedAverage(inputValue, maxLength, this.loBetaArray);
      case 6 :
        return this.calculatedAverage(inputValue, maxLength, this.betaOneArray);
      case 7 :
        return this.calculatedAverage(inputValue, maxLength, this.betaTwoArray);
      case 8 :
        return this.calculatedAverage(inputValue, maxLength, this.hiBetaArray);
      case 9 :
        return this.calculatedAverage(inputValue, maxLength, this.artefaktArray);
    }
  }

  ratioAverage(inputValue: number, maxLength: number, index: number) {
    switch (index) {
      case 0 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.deltaArrayRatio );
      case 1 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.thetaArrayRatio );
      case 2 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.lowAlphaArrayRatio );
      case 3 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.alphaArrayRatio );
      case 4 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.hiAlphaArrayRatio );
      case 5 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.loBetaArrayRatio );
      case 6 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.betaOneArrayRatio );
      case 7 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.betaTwoArrayRatio );
      case 8 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.hiBetaArrayRatio );
      case 9 :
        return this.calculatedAverageRatio(inputValue, maxLength, this.artefaktArrayRatio );
    }
  }

  calculatedAverage(inputValue, maxLength, array) {
    if(inputValue) {
      let count = 0;
      if (array.length >= maxLength) {
        array.shift();
      }
      array.push(inputValue);
      for (let i = array.length - 1; i >= 0; i -= 1) {
        if (array[i]) {
          count += array[i];
        }
      }
      const res = count / array.length;
      return Number(res.toFixed(2));
    }
  }

  calculatedAverageRatio(inputValue, maxLength, array) {
    if(inputValue) {
      let count = 0;
      if (array.length >= maxLength) {
        array.shift();
      }
      array.push(inputValue);
      for (let i = array.length - 1; i >= 0; i -= 1) {
        if (array[i]) {
          count += array[i];
        }
      }
      const res = count / array.length;
      return Number(res.toFixed(2));
    }
  }
}
