export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Profile',
          root: true,
          icon: './assets/media/svg/submenu-icons/profile.svg',
          page: '/profile',
          translate: 'MENU.PROFILE',
          bullet: 'dot',
        },
        {
          title: 'Tree',
          root: true,
          icon: './assets/media/svg/submenu-icons/tree.svg',
          page: '/supervisors-tree',
          translate: 'PROFILE.PROFILE.SUPERVISORS_TREE',
          bullet: 'dot',
        },
        {
          title: 'Charts',
          translate: 'MENU.CHARTS',
          root: true,
          icon: './assets/media/svg/submenu-icons/line-view.svg',
          submenu: [
            {
              title: 'Live view',
              translate: 'MENU.LIVE_VIEW',
              bullet: 'dot',
              page: '/live-view',
            },
            {
              title: 'Amplitude Overview',
              translate: 'MENU.AMPLITUDE_OVERVIEW',
              bullet: 'dot',
              page: '/amplitude-overview',
            },
            {
              title: 'Raw Signal',
              translate: 'MENU.RAW_CHART',
              bullet: 'dot',
              page: '/raw-chart',
            },
            {
              title: 'Ratio Overview',
              translate: 'MENU.RATIO_CHART',
              bullet: 'dot',
              page: '/ratio-chart',
            },
          ]
        },
        {
          title: 'Test',
          root: true,
          icon: './assets/media/svg/submenu-icons/test-screen.svg',
          translate: 'MENU.TEST',
          submenu: [
            {
              title: 'Test Configuration',
              translate: 'MENU.TEST_CONFIGURATION',
              page: '/test-configuration',
            },
            {
              title: 'Test Screen',
              translate: 'MENU.TEST_SCREEN',
              page: '/test',
            },
          ],
        },
        {
          title: 'Training',
          translate: 'MENU.TRAINING',
          root: true,
          icon: './assets/images/fitness-icon.png',
          submenu: [
            {
              title: 'Training Configuration',
              translate: 'MENU.TRAINING_CONFIGURATION',
              bullet: 'dot',
              page: '/training-configuration',
            },
            {
              title: 'Training Screen',
              translate: 'MENU.TRAINING_SCREEN',
              bullet: 'dot',
              page: '/training-screen',
            },
            {
              title: 'Remote Training',
              translate: 'MENU.REMOTE_TRAINING',
              bullet: 'dot',
              page: '/remote-training',
            },
          ]
        },
        {
          title: 'Analysis',
          translate: 'MENU.ANALYSIS',
          root: true,
          icon: './assets/images/analysis.png',
          submenu: [
            {
              title: 'Select recording',
              translate: 'MENU.RECORDINGS',
              bullet: 'dot',
              page: '/recordings',
            },
            {
              title: 'Analysis Screen',
              translate: 'MENU.ANALYSIS_SCREEN',
              bullet: 'dot',
              page: '/analysis',
            },
          ]
        },
        {
          title: 'Statistic',
          translate: 'MENU.STATISTIC',
          root: true,
          icon: './assets/images/statistic.png',
          submenu: [
            {
              title: 'Dashboard',
              translate: 'MENU.DASHBOARD',
              bullet: 'dot',
              page: '/statistic',
            },
          ]
        }
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
