import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RecordService} from '../../../../services/record/record.service';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {NavigationStart, Router} from '@angular/router';
import {HideRecordButtonsService} from '../../../../services/hideRecordButtons/hide-record-buttons.service';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../core/charts/_selectors/charts.selectors';
import {AppState} from '../../../../core/reducers';
import {AutoTrainingService} from '../../../../services/auto-training/auto-training.service';
import {AutothresholdService} from '../../../../services/autothreshold/autothreshold.service';


@Component({
  selector: 'kt-record-actions',
  templateUrl: './record-actions.component.html',
  styleUrls: ['./record-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordActionsComponent implements OnInit , AfterViewInit {


  isRecordingStarted = false;
  startDate = null;
  recordTime = null;
  showTimer = false;
  pausedAt = 0;
  columnData = false;
  timerStarted = false;
  showButtons = false;

  clock: any;
  minutes: any = '00';
  seconds: any = '00';
  milliseconds: any = '00';
  counter: number;
  timerRef;
  running = false;
  recordPreset = '05';
  currentUser;

  constructor(
    private cd: ChangeDetectorRef,
    private record: RecordService,
    private firebase: FirebaseService,
    private router: Router,
    private hideRecodButtons: HideRecordButtonsService,
    private store: Store<AppState>,
    private autoTraining: AutoTrainingService,
    private autoThresholdService: AutothresholdService
  ) { }

  ngOnInit(): void {
    this.checkStyleChange();
    this.subscribeOnRoute();
    this.cd.markForCheck();
    this.subscribeOnTemplateHide();

  }

  ngAfterViewInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.store.pipe(select(currentUser)).subscribe((user) => {
      this.currentUser = user;
      this.cd.markForCheck();
    });
  }

  subscribeOnRoute() {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        if (route.url === '/amplitude-overview' || route.url === '/ratio-chart') {
          this.showButtons = true;
          this.cd.markForCheck();
        } else {
          this.showButtons = false;
          this.cd.markForCheck();
        }
      }
    });
  }

  subscribeOnTemplateHide() {
    this.hideRecodButtons.recordButtons.subscribe((condition) => {
      if (condition) {
        this.showButtons = false;
        this.cd.markForCheck();
      }
    });
  }

  startTimer() {
    this.running = !this.running;
    this.record.isNeedRecord.next(this.running);
    if (this.running) {
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.milliseconds = Math.floor(Math.floor(this.counter % 1000) / 10).toFixed(0);
        this.minutes = Math.floor(this.counter / 60000);
        this.seconds = Math.floor(Math.floor(this.counter % 60000) / 1000).toFixed(0);
        if (Number(this.minutes) < 10) {
          this.minutes = '0' + this.minutes;
        } else {
          this.minutes = '' + this.minutes;
        }
        if (this.recordPreset === this.minutes && this.seconds === '0') {
          this.stopRecording();
        }
        if (Number(this.milliseconds) < 10) {
          this.milliseconds = '0' + this.milliseconds;
        } else {
          this.milliseconds = '' + this.milliseconds;
        }
        if (Number(this.seconds) < 10) {
          this.seconds = '0' + this.seconds;
        } else {
          this.seconds = '' + this.seconds;
        }
        this.cd.markForCheck()
      },1000);
    } else {
      clearInterval(this.timerRef);
    }
    // this.autoTraining.subscribeOnSuccessValue();
    // this.autoTraining.startTriggerInterval();
    // const timeout = setTimeout(() => {
    //   this.autoTraining.clearTriggerInterval();
    //   clearTimeout(timeout);
    // },5000);
    const noteTimeout = setTimeout(() => {
      clearTimeout(noteTimeout);
      this.cd.markForCheck();
    }, 30 * 1000);
  }

  stopRecording() {
    this.running = false;
    this.counter = undefined;
    this.milliseconds = '00';
    this.seconds = '00';
    this.minutes = '00';
    clearInterval(this.timerRef);
    this.startDate = null;
    this.record.isNeedRecord.next(false);
    this.record.stopRecording.next(true);
    setTimeout(() => {
      this.firebase.generatedId = null;
    },1500)
    this.cd.markForCheck();
    this.autoTraining.unSubscribeOnSuccessValue();
    this.autoTraining.canTrigger = false;
  }

  checkStyleChange() {
    this.record.columnData.subscribe((condition) => {
      this.columnData = condition;
    })
  }
}
