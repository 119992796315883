import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  canPlayVideo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  changeChartWidth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  trainer : BehaviorSubject<string> = new BehaviorSubject<string>(null);
  lighterColor: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showOptions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  youtubeVideoShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }
}
