import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutothresholdService {

  constructor() { }
  minuteSamples = [];
  thresholdValues = {
    rewardThreshold: 0,
    inhibitThreshold:0
  }

  setMinuteSamples(inputValue: number, maxLength: number, inhibtion, reward) {
    if (this.minuteSamples.length >= maxLength) {
      this.minuteSamples.shift();
    } else {
      this.minuteSamples.push(+inputValue.toFixed(2));
    }
    let sortedSamples  = JSON.parse(JSON.stringify(this.minuteSamples));
    sortedSamples = sortedSamples.sort((a, b) => a - b);

    const indexInhibitPercentage = Math.floor((sortedSamples.length) * inhibtion / 100);
    const indexRewardPercentage = Math.floor((sortedSamples.length) * reward / 100);

    this.thresholdValues.rewardThreshold = sortedSamples[indexRewardPercentage];
    this.thresholdValues.inhibitThreshold = sortedSamples[indexInhibitPercentage];
  }

  getThresholdValues() {
    return this.thresholdValues;
  }
  clearMinuteSamples() {
    this.minuteSamples = [];
  }
}
