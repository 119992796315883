import {Injectable} from '@angular/core';
import {IUser} from '../../core/interfaces/user';
import {FirebaseService} from '../firebase/firebase.service';
import {concatMap, switchMap, take, takeUntil} from 'rxjs/operators';
import {MenuAsideService} from '../../core/_base/layout';
import {CurrentUserService} from '../current-user/current-user.service';
import {BehaviorSubject, Observable, Subject, forkJoin, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectStepsService {

  currentUser: IUser;
  menuConfig;
  menuConfigOriginal;
  userTrainings = [];
  unsubscribe: Subject<any> = new Subject();

  constructor(
    private firebase: FirebaseService,
    public menuAsideService: MenuAsideService,
    public firebaseUser: CurrentUserService,
  ) { }

  checkRoutes(userId): Observable<any> {
    return new Observable((observer) => {
      let userTrainings = [];
      this.firebase.checkForTests(userId, 'EEG').pipe(take(1)).subscribe((tests) => {
        if (tests.length) {
          userTrainings = [...userTrainings, ...tests]
        }
        this.firebase.checkForTrainings(userId, 'EEG').pipe(take(1)).subscribe((trainings) => {
          if (trainings.length) {
            userTrainings = [...userTrainings, ...trainings]
          }
          observer.next(userTrainings)
        })
      });
    })
  }
}
