<ng-container *ngIf="currentUser">
  <div class="topbar-item" tourAnchor="access-profile-tour" data-placement="bottom">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
      <ng-container *ngIf="greeting">
        <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
      </ng-container>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
          {{currentUser.displayName}}
        </span>
      <img *ngIf="currentUser.photoURL" style="border-radius: 50%;height: 35px;object-fit: cover" width="35" class="user-photo" [src]="currentUser.photoURL">
      <!--				<span class="symbol symbol-35 symbol-light-success user-initials">-->
      <!--					<span class="symbol-label font-size-h5 font-weight-bold" style="background-color: #6c757d;color: #17224a;">-->
      <!--&lt;!&ndash;						{{_user.displayName | firstLetter}}&ndash;&gt;-->

      <!--					</span>-->
      <!--				</span>-->
    </div>
  </div>
</ng-container>
