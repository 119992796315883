import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'kt-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent implements OnInit {

  note = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (!this.data.isCompleted) {
      this.note = this.translate.instant('EDIT_NOTE.INCOMPLETE');
      if (!this.data.isCompleted && this.data.seconds < 180) {
        setTimeout(() => {
          this.saveNote();
        }, 0);
        this.snackBar.open(`${this.translate.instant('TRAINING_SCREEN.SHORT_SESSION')}`, '', {
          duration: 6000,
          horizontalPosition: 'center'
        });
      }
    } else {
      this.note = '';
    }
  }

  saveNote() {
    this.dialogRef.close(this.note)
  }

  close() {
    this.dialogRef.close();
  }

}
