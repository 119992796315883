import { Injectable } from '@angular/core';
import { Chart} from 'chart.js'
import {annotationPlugin} from 'chartjs-plugin-annotation';

Chart.plugins.register({
  id: annotationPlugin
});

@Injectable({
  providedIn: 'root'
})
export class GeniusChartService {
  trainingChartStepSize = 10;

  constructor() {
  }

  createLineChart(element: HTMLElement, stepSize: number, max?: number, tension?:boolean) {
    const  datasets = [
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#FF00FF',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#0000FF',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#00FF00',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#00FF00',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#198200',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#d1d103',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#ffaa00',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#d86c00',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#FF0000',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#000000',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        tension: tension? 0.3: 0,
        lineTension: tension? 0.3: 0,
        normalized: true,
        spanGaps: true
      }
    ]

    // @ts-ignore
    return new Chart(element ,{
      type: 'line',
      data: {
        labels:[],
        pointStyle: 'triangle',
        datasets
      },
      options: {
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: max? max : 300,
              callback(value, index, ticks) {
                return value + ' ' + 'µV'
              },
            },
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              min:0,
              max:9999,
              maxTicksLimit: 30,
              padding:0,
              precision: 0,
              callback(value, index, values) {
                if (value < 0.08) {
                  return 0;
                } else if (value % stepSize === 0) {
                  return Math.round(value);
                }
              },
            }
          }]
        },
        animation: {
          duration: 0
        },
        legend: {
          display: false
        },
        responsive: true,
      }
    })
  }

  createRawChart(element: HTMLElement, stepSize: number, min?: number, max?: number) {
    const  datasets = [
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: 'black',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
        borderWidth: 0.5,
        tension: 0,
        lineTension: 0,
      }
    ]
    // @ts-ignore
    return new Chart(element ,{
      type: 'line',
      data: {
        labels:[],
        pointStyle: 'triangle',
        datasets
      },
      options: {
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true,
              min: min? -min : -1000,
              max: max? max : 1000,
              callback(value, index, ticks) {
                return value + ' ' + 'µV'
              },
            },
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              min:0,
              max:9999,
              maxTicksLimit: 30,
              padding:0,
              precision: 0,
              callback(value, index, values) {
                if (value < 0.08) {
                  return;
                } else if (value % stepSize === 0) {
                  return Math.round(value);
                }
              },
            }
          }]
        },
        animation: {
          duration: 0
        },
        legend: {
          display: false
        },
        responsive: true,
      }
    })
  }

  createRatioChart(element: HTMLElement, stepSize: number, max?: number) {

    const  datasets = [
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#FF0000',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#0000FF',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
      },
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#00FF00',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
      },
    ]

    // @ts-ignore
    return new Chart(element ,{
      type: 'line',
      data: {
        labels:[],
        pointStyle: 'triangle',
        datasets
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: max? +max : 5,
            },
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              min:0,
              max:9999,
              maxTicksLimit: 30,
              padding:0,
              precision: 0,
              callback(value, index, values) {
                if (value < 0.08) {
                  return 0;
                } else if (value % stepSize === 0) {
                  return Math.round(value);
                }
              },
            }
          }]
        },
        animation: {
          duration: 0
        },
        legend: {
          display: false
        },
        responsive: true,
      }
    })
  }
  // @ts-ignore
  createTrainingRatioChart(element: HTMLElement, stepSize: number, max?: number, lineValue: number) {
    this.trainingChartStepSize = stepSize;
    const  datasets = [
      {
        display: false,
        fill: true,
        hidden: false,
        pointRadius: 0,
        backgroundColor: 'transparent',
        borderColor: '#000',
        borderCapStyle: 'butt',
        borderDash: [],
        data: [],
      },
    ]

    // @ts-ignore
    return new Chart(element ,{
      type: 'line',
      data: {
        labels:[''],
        pointStyle: 'triangle',
        datasets
      },
      options: {
        parsing: false,
        normalized: true,
        animation: false,
        elements: {
          line: {
            tension: 0.5
          }
        },
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: lineValue? lineValue : 1,
            borderColor: 'black',
            borderWidth: 2,
            borderDash: [2, 2],
          }],
          drawTime: 'afterDraw'
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: max? max : 5,
            },
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              min:0,
              max:9999,
              maxTicksLimit: 30,
              padding:0,
              precision: 0,
              fontSize: 10,
              callback: (value, index, values) => {
                  if (value < 0.08) {
                    return 0;
                  } else if (value % this.trainingChartStepSize === 0) {
                    return Math.round(value);
                  }
              }
            }
          }]
        },
        legend: {
          display: false
        },
      }
    })
  }

  createTrainingBarChart(element: HTMLElement, max: number, lineValue) {
    // @ts-ignore
    return new Chart(element ,{
      type: 'bar',
      data: {
        labels: [''],
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#FF0000',
            ],
            borderWidth: 1,
          }
        ]
      },
      options: {
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: lineValue? lineValue : 1,
            borderColor: 'black',
            borderWidth: 2
          }],
          drawTime: 'afterDraw'
        },
        events: [],
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            barPercentage: 0.9,
          }],
          yAxes: [{
            ticks: {
              fontSize: 10,
              max: max? max : 10,
              min:0
            }
          }]
        },
        animation: {
          duration: 50
        },
        legend: {
          display: false
        },
      },
    });
  }
}



