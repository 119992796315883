import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class NavigationService {

  navigationSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor() { }
}
