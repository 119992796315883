import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectChartsState = (state) => state.charts;
const selectAuthState = (state) => state.auth;

export const chartType = createSelector(
  selectChartsState,
  (charts) => charts.chartType
);

export const chartsData = createSelector(selectChartsState, (charts) => {
  const data = [];
  let min = 0;
  let max = 0;
  let avg = null;
  let maxValue = null;
  let maxDMin = null;
  let minValue = null;
  let sign = null;
  let currentValue = null;
  let time = null;
  let breathMin = null;
  let calculatedRA = null;
  let coherence = null;
  let regularity = null;
  let value = null;

  if (charts.data && charts.data.length > 0) {
    // const range = 90000;
    // if (charts.chartType === 'Pulse') {
    //   range = 90;
    // }
    // const last = charts.data[charts.data.length - 1].time;
    if (charts.chartType !== 'Respiration') {
      charts.data
        // .filter((a) => a.time > last - range)
        .forEach((v, index) => {
          if (index === 0) {
            min = v.value;
            max = v.value;
          } else {
            min = min < v.value ? min : v.value;
            max = max > v.value ? max : v.value;
          }
          avg = v.avg;
          maxValue = v.max;
          maxDMin = v.maxDMin;
          minValue = v.min;
          value = v.value;
          sign = v.sign;
          time = v.time;
          currentValue = v.value;
          if (charts.chartType === 'Pulse') {
            data.push([v.time / 1000, v.value]);
          } else if (charts.chartType === 'Skin Response') {
            data.push([v.time / 1000, v.value, min - 3, max + 3]);
          } else if (charts.chartType === 'Temperature') {
            data.push([v.time / 1000, v.value, min - 1, max + 1]);
          } else if (charts.chartType === 'Respiration') {
            data.push([v.time / 1000, v.value, min - 20, max + 20]);
          } else {
            data.push([v.time / 1000, v.value]);
          }
        });
    } else {
      charts.data
        // .filter((a) => a.time > last - range)
        .forEach((v, index) => {
          if (index === 0) {
            min = v.value;
            max = v.value;
          } else {
            min = min < v.value ? min : v.value;
            max = max > v.value ? max : v.value;
          }
          maxValue = v.max;
          minValue = v.min;
          time = v.time;
          value = v.value;
          currentValue = v.value;
          breathMin = v.breathMin;
          calculatedRA = v.calculatedRA;
          coherence = v.coherence;
          regularity = v.regularity;
          if (charts.chartType === 'Pulse') {
            data.push([v.time, v.value]);
          } else if (charts.chartType === 'Skin Response') {
            data.push([v.time / 1000, v.value, min - 3, max + 3]);
          } else if (charts.chartType === 'Temperature') {
            data.push([v.time / 1000, v.value, min - 1, max + 1]);
          } else if (charts.chartType === 'Respiration') {
            data.push([v.time / 1000, v.value, min - 20, max + 20]);
          } else {
            data.push([v.time / 1000, v.value]);
          }
        });
    }
  }
  let result = null;
  if (charts.chartType === 'Pulse') {
    // result = [data, charts.chartType, min - 10, max + 10, avg, maxValue, minValue, maxDMin, sign, time,  currentValue];
    result = [
      data,
      charts.chartType,
      min - 10,
      max + 10,
      avg,
      maxValue,
      minValue,
      maxDMin,
      sign,
      time,
      currentValue,
    ];
  } else if (charts.chartType === 'Skin Response') {
    result = [
      data,
      charts.chartType,
      min - 3,
      max + 3,
      avg,
      maxValue,
      minValue,
      maxDMin,
      sign,
      time,
      currentValue,
    ];
  } else if (charts.chartType === 'Temperature') {
    result = [
      data,
      charts.chartType,
      min - 1,
      max + 1,
      avg,
      maxValue,
      minValue,
      maxDMin,
      sign,
      time,
      currentValue,
    ];
  } else if (charts.chartType === 'Respiration') {
    result = [
      data,
      charts.chartType,
      min - 20,
      max + 20,
      breathMin,
      calculatedRA,
      coherence,
      regularity,
      time,
      currentValue,
    ];
  } else {
    result = [];
  }
  return { type: charts.chartType, result };
});

export const chartTypeWithUserId = createSelector(
  selectAuthState,
  chartType,
  (userId: string, type: string) => {
    return;
  }
);

export const currentAuthToken = createSelector(
  selectAuthState,
  (auth) => auth.authToken
);

export const isUserLoaded = createSelector(
  selectAuthState,
  (auth) => auth.isUserLoaded
);

export const currentUser = createSelector(selectAuthState, (auth) => auth.user);

export const currentUserRoleIds = createSelector(currentUser, (user) => {
  if (!user) {
    return [];
  }

  return user.roles;
});
