import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {FirebaseService} from '../firebase/firebase.service';
import {CurrentUserService} from '../current-user/current-user.service';
import {IUser} from '../../core/interfaces/user';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  pagesArray = ['/profile', '/amplitude-overview', '/raw-chart', '/ratio-chart', '/test-configuration', '/test', '/training-configuration', '/training-screen', '/remote-training', '/recordings', '/analysis', '/statistic'];
  public seconds = 0;
  secondsInterval;
  lastPage = '';
  pageObject = {
    seconds: 0,
    page: '',
    date: new Date()
  }
  currentUser: IUser;
  public trackingData = [];

  constructor(
    private firebase: FirebaseService,
    private firebaseUser: CurrentUserService
  ) { }

  handlePages(page: string) {
    if (page === '/auth/login' || page === '/auth/register') {
      return;
    }
    this.stopPageInterval();
    this.startPageInterval(page);
    if (!this.currentUser) {
      this.firebaseUser.getCurrentUser().subscribe((user: IUser) => {
       this.currentUser = user;
        this.firebase.getTrackingData(this.currentUser.id).subscribe((data: any) => {
          if (data) {
            this.trackingData = [];
            data.forEach((item) => {
              item.date = new Date(item.date.seconds * 1000 + item.date.nanoseconds/1000000);
              if (this.sameDay(item.date, new Date())) {
                this.trackingData.push(item);
              }
            });
          }
        });
      });
    }
  }

  startPageInterval(page) {
    this.secondsInterval = setInterval(() => {
      this.seconds++;
      this.lastPage = page;
    },1000);
  }

  stopPageInterval() {
    if (this.secondsInterval) {
      if (this.lastPage === '/credits' || this.lastPage === '/auth/login' || this.lastPage === '/auth/register') {
        this.seconds = 0;
        clearInterval(this.secondsInterval);
        return;
      }
      clearInterval(this.secondsInterval);
      this.pageObject.seconds = this.seconds;
      this.pageObject.page = this.getPageTranslation(this.lastPage);
      this.pageObject.date = new Date();
      this.setDataToFirebase();
      this.seconds = 0;
    }
  }
  setDataToFirebase() {
    if (!this.pageObject.page || !this.currentUser) {
      return;
    }
    this.trackingData.forEach((item) => {
      if (this.sameDay(item.date, this.pageObject.date) && this.getPageTranslation(this.lastPage) === item.page) {
        item.seconds += this.pageObject.seconds;
        this.firebase.updateTrackingData(this.currentUser.id, item.id, item);
      }
    });
    if (!this.trackingData.some(e => e.page === this.getPageTranslation(this.lastPage))) {
      this.firebase.setTrackingData(this.currentUser.id, this.pageObject);
    }
    // if (!this.trackingData.length) {
    //   this.firebase.setTrackingData(this.currentUser.id, this.pageObject);
    // }
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  getPageTranslation(page) {
    switch (page) {
      case '/profile':
        return 'MENU.PROFILE'
      case '/amplitude-overview':
        return 'MENU.AMPLITUDE_OVERVIEW'
      case '/raw-chart':
        return 'MENU.RAW_CHART'
      case '/ratio-chart':
        return 'MENU.RATIO_CHART'
      case '/test-configuration':
        return 'MENU.TEST_CONFIGURATION'
      case '/test':
        return 'MENU.TEST'
      case '/training-configuration':
        return 'MENU.TRAINING_CONFIGURATION'
      case '/training-screen':
        return 'MENU.TRAINING_SCREEN'
      case '/remote-training':
        return 'MENU.REMOTE_TRAINING'
      case '/recordings':
        return 'MENU.RECORDINGS'
      case '/analysis':
        return 'MENU.ANALYSIS_SCREEN'
      case '/statistic':
        return 'MENU.DASHBOARD'
    }
  }
}
