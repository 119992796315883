import { Injectable } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutoTrainingService {

  triggerThresholdSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  triggerChartAutoScale: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  templateIndexChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public upperDifficulty: number;
  public lowerDifficulty: number;

  public triggerSeconds = 5;
  canTrigger = false;
  firstTimeout;
  secondTimeout
  triggerTimeout;
  fiftyInterval;
  successRateValue = null;
  constructor() {
  }

  fiveSecondsTrigger() {
    this.firstTimeout = setTimeout(() => {
      this.triggerThresholdSubject.next(true);
      clearTimeout(this.firstTimeout);
    },5000);
  }

  thirtySecondsTrigger() {
    this.secondTimeout = setTimeout(() => {
      this.canTrigger = true;
      this.triggerThresholdSubject.next(true);
      this.triggerChartAutoScale.next(true);
      clearTimeout(this.secondTimeout);
      // this.fifteenSecondsTrigger();
    },30000);
  }

  fifteenSecondsTrigger() {
    this.fiftyInterval = setInterval(() => {
      this.triggerChartAutoScale.next(true);
      clearInterval(this.fiftyInterval);
    },15000);
  }
  handleSuccessRateValue() {
    if (this.canTrigger && (this.successRateValue > this.upperDifficulty || this.successRateValue < this.lowerDifficulty)) {
      this.triggerThresholdSubject.next(true);
      this.canTrigger = false;
      this.triggerTimeout = setTimeout(() => {
        clearTimeout(this.triggerTimeout);
        this.canTrigger = true;
      },20 * 1000);
    }
  }

  clearTimeouts() {
    clearTimeout(this.firstTimeout);
    clearTimeout(this.secondTimeout);
    clearInterval(this.triggerTimeout);
    clearInterval(this.fiftyInterval);
    this.firstTimeout = null;
    this.secondTimeout = null;
    this.triggerTimeout = null;
    this.fiftyInterval = null;
  }

  unSubscribeOnSuccessValue() {
    this.canTrigger = false;
    this.triggerSeconds = 5;
    this.successRateValue = null;
    clearTimeout(this.firstTimeout);
    clearTimeout(this.secondTimeout);
    clearInterval(this.triggerTimeout);
  }
}
