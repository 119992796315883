import { Action, createReducer, on } from '@ngrx/store';
import { ArchiveActions, ArchiveActionTypes } from '../_actions/archive.actions';

export interface ArchiveState {
  selectedId: string,
  recordings: any[],
  isRecordingsAvailable: boolean
  sharedRecordings: any[],
  isSharedRecordingsAvailable: boolean
  othersRecordings: any[],
  isOthersRecordingsAvailable: boolean,
  sharedIdorEmail: string;
}

export const initialState: ArchiveState = {
  selectedId: null,
  recordings: null,
  isRecordingsAvailable: false,
  sharedRecordings: null,
  isSharedRecordingsAvailable: false,
  othersRecordings: null,
  isOthersRecordingsAvailable: false,
  sharedIdorEmail: null
}

export function archiveReducer(state: ArchiveState = initialState, action: ArchiveActions): ArchiveState {
  switch (action.type) {
    case ArchiveActionTypes.SaveRecordings: {
      const payload: any = action.payload;
      return {
        ...state,
        recordings: payload.recordings,
        isRecordingsAvailable: payload.isRecordingsAvailable
      };
    }
    case ArchiveActionTypes.SaveSharedRecordings: {
      const payload: any = action.payload;
      return {
        ...state,
        sharedRecordings: payload.recordings,
        isSharedRecordingsAvailable: payload.isRecordingsAvailable
      };
    }
    case ArchiveActionTypes.SaveOthersRecordings: {
      const payload: any = action.payload;
      return {
        ...state,
        othersRecordings: payload.othersRecordings,
        isOthersRecordingsAvailable: payload.isOthersRecordingsAvailable
      };
    }
    default:
      return state;
  }
}

