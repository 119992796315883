import { Action, createReducer, on } from '@ngrx/store';
import { ChartsActions, ChartsActionTypes } from '../_actions/charts.actions';


export const chartsFeatureKey = 'charts';

export interface ChartsState {
  chartType: 'Pulse' | 'Respiration' | 'Skin Response' | 'Temperature';
  data: any[];
  isDataAvailable: boolean;
}

export const initialState: ChartsState = {
  chartType: 'Skin Response',
  data: [],
  isDataAvailable: true
};

export function chartsReducer(state: ChartsState = initialState, action: ChartsActions): ChartsState {
  switch (action.type) {
    case ChartsActionTypes.LoadCharts: {
      const payload: any = action.payload;
      return {
        ...state,
        data: payload.data,
        isDataAvailable: payload.isDataAvailable
      };
    }
    case ChartsActionTypes.SetChartType: {
      const payload: any = action.payload;
      return {
        ...state,
        chartType: payload.chartType
      };
    }
    default:
      return state;
  }
}

