import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoachmarkService {

  showCoachMark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showTourAgain : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor() { }
}
