import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AverageRatioAnalysisService {

  constructor() { }

  analysisFirstRatioArray = [];
  analysisSecondRatioArray = [];
  analysisThirdRatioArray = [];

  analysisAverageFirst(inputValue: number, maxLength) {
    return this.analysisRatioAverage(inputValue, maxLength, this.analysisFirstRatioArray);
  }
  analysisAverageSecond(inputValue: number, maxLength) {
    return this.analysisRatioAverage(inputValue, maxLength, this.analysisSecondRatioArray);
  }
  analysisAverageThird(inputValue: number, maxLength) {
    return this.analysisRatioAverage(inputValue, maxLength, this.analysisThirdRatioArray);
  }

  analysisRatioAverage(inputValue, maxLength, array) {
    if(inputValue) {
      let count = 0;
      if (array.length >= maxLength) {
        array.shift();
      }
      array.push(inputValue);
      for (let i = array.length - 1; i >= 0; i -= 1) {
        if (array[i]) {
          count += array[i];
        }
      }
      const res = count / array.length;
      return res.toFixed(2);
    }
  }

  resetAnalysisAverage() {
    this.analysisFirstRatioArray = [];
    this.analysisSecondRatioArray = [];
    this.analysisThirdRatioArray = [];
  }
}
