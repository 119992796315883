<div #asideMenuOffcanvas class="aside aside-left d-flex flex-column flex-row-auto" [ngClass]="asideClasses"
     id="kt_aside">
  <!-- begin:: Brand -->
  <kt-brand></kt-brand>
  <!-- end:: Brand -->
  <!-- BEGIN: Aside Menu -->
  <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
    <div tourAnchor="aside-left-tour" #asideMenu ktMenu [options]="menuOptions"
         [perfectScrollbar]="{wheelPropagation: false, suppressScrollX: false}"
         [ngStyle]="{'max-height': '100vh', 'position': 'relative'}" id="kt_aside_menu" class="aside-menu scroll"
         (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
         [ngClass]="htmlClassService.getClasses('aside_menu', true)">
      <ul class="menu-nav" id="nav-menu" [ngClass]="htmlClassService.getClasses('aside_menu_nav', true)">
        <ng-container *ngFor="let item of currentRolePath">
          <li  *ngIf="item.isAvailable" [attr.aria-haspopup]="true" [attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)"
              [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']" class="menu-item" [ngClass]="getItemCssClasses(item)"
              [ngbTooltip]="item.tooltip" data-placement="right">

            <!-- if menu item hasn't submenu -->
            <a [routerLink]="!item.children.length ? item.path : null" class="menu-link menu-toggle">
              <img *ngIf="item.icon" class="menu-icon" [src]="item.icon">

              <!-- menu item title text -->
              <span class="menu-text" style="margin-left: 10px;">{{menuTranslate(item.translate)}}</span>

              <!-- if menu item has submenu child then put arrow icon -->
              <i *ngIf="item.children.length" class="menu-arrow"></i>
            </a>

            <!-- if menu item has submenu child then recursively call new menu item component -->
            <div *ngIf="item.children.length" class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <li *ngFor="let child of item.children"
                    [attr.aria-haspopup]="true" [attr.data-menu-toggle]="getItemAttrSubmenuToggle(child)"
                    [attr.data-ktmenu-dropdown-toggle-class]="child['dropdown-toggle-class']" class="menu-item" [ngClass]="getItemCssClasses(child)"
                    [ngbTooltip]="child.tooltip" data-placement="right">

                  <!-- if menu item hasn't submenu -->
                  <a [routerLink]="child.path" class="menu-link menu-toggle">
                    <span class="menu-text" style="margin-left: 10px;">{{menuTranslate(child.translate)}}</span>
                  </a>

                </li>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="images-container">
    <div id="bannerImg"
         [ngClass]="menuCollapsed() ? 'banner-menu-collapsed' : 'banner-container' ">
      <img (click)="onNavigate('https://mindfield-shop.com/individuelle-beratung-und-trainingserstellung-fuer-den-esense-eegenius-bis-60-minuten/')"
           [src]="getSelectedLanguage() === 'de' ? 'assets/banners/german-banner_720.png' : 'assets/banners/english-banner_720.png'"
           alt="banner">
    </div>
    <div class="chart-widget-wrap" id="widget">
      <kt-chat-widget *ngIf="this.currentUser" [currentUser]="this.currentUser"></kt-chat-widget>
    </div>
  </div>

  <div class="record-actions">
    <kt-record-actions></kt-record-actions>
  </div>
  <div class="credits-link">
    <a routerLink="/credits">{{ "CREDITS.CREDITS_TEXT" | translate }}</a>
    <img *ngIf="isMenuCollapsed()" (click)="downloadConnectApp()" src="assets/mindfieldConnect/Favicon_Mindfield-Connect.ico" alt="img">
  </div>
  <!-- END: Aside Menu -->
</div>
