import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';

export enum AuthActionTypes {
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  Register = '[Register] Action',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API',
  UpdateUser = '[Update User] Auth API',
  UpdateLoginTime = '[Update Login Time] Auth API',
  SetLoggedIn = '[Set Logged In] Auth API',
  FetchPlans = '[Fetch Plans] Auth API',
  FetchGroups = '[Fetch Groups] Auth API',
  SaveGroups = '[Save Groups] Auth API',
  CleanAuth = '[Clean Auth] Auth API',
  SavePlans = '[Save Plans Auth] API'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: { authToken: string; uid: string }) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;
  constructor(public payload: { authToken: string; uid: string }) {}
}

export class UserRequested implements Action {
  readonly type = AuthActionTypes.UserRequested;
}
export class SetLoggedIn implements Action {
  readonly type = AuthActionTypes.SetLoggedIn;
  constructor(public payload: { createdAt: any }) {}
}

export class UserLoaded implements Action {
  readonly type = AuthActionTypes.UserLoaded;
  constructor(public payload: { user: User }) {}
}

export class UpdateUser implements Action {
  readonly type = AuthActionTypes.UpdateUser;
  constructor(public payload: { user: User }) {}
}

export class CleanAuth implements Action {
  readonly type = AuthActionTypes.CleanAuth;
}

export class UpdateLoginTime implements Action {
  readonly type = AuthActionTypes.UpdateLoginTime;
  constructor(public payload: { uid: any }) {}
}

export class FetchPlans implements Action {
  readonly type = AuthActionTypes.FetchPlans;
  constructor(public payload: { uid: any }) {}
}

export class SavePlans implements Action {
  readonly type = AuthActionTypes.SavePlans;
  constructor(public payload: { plans: any }) {}
}

export class FetchGroups implements Action {
  readonly type = AuthActionTypes.FetchGroups;
  constructor(public payload: { id: any }) {}
}

export class SaveGroups implements Action {
  readonly type = AuthActionTypes.SaveGroups;
  constructor(public payload: { groups: any }) {}
}

export type AuthActions =
  | Login
  | Logout
  | Register
  | UserRequested
  | UserLoaded
  | UpdateUser
  | SetLoggedIn
  | FetchPlans
  | FetchGroups
  | SavePlans
  | SaveGroups
  | CleanAuth
  | UpdateLoginTime;
