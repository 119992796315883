import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculateAverageService {

  deltaArray = [];
  thetaArray = [];
  alphaArray = [];
  lowAlphaArray = [];
  hiAlphaArray = [];
  loBetaArray = [];
  betaOneArray = [];
  betaTwoArray = [];
  hiBetaArray = [];
  artefaktArray = [];
  rawSignalArrayFirstChannel = [];
  rawSignalArraySecondChannel = [];
  rawSignalTwoChannelsAverage = []
  constructor() { }



  amplitudeAverage(inputValue: number, maxLength: number, index: number) {
    switch (index) {
      case 0 :
        return this.calculatedAverage(inputValue, maxLength, this.deltaArray);
      case 1 :
        return this.calculatedAverage(inputValue, maxLength, this.thetaArray);
      case 2 :
        return this.calculatedAverage(inputValue, maxLength, this.lowAlphaArray);
      case 3 :
        return this.calculatedAverage(inputValue, maxLength, this.alphaArray);
      case 4 :
        return this.calculatedAverage(inputValue, maxLength, this.hiAlphaArray);
      case 5 :
        return this.calculatedAverage(inputValue, maxLength, this.loBetaArray);
      case 6 :
        return this.calculatedAverage(inputValue, maxLength, this.betaOneArray);
      case 7 :
        return this.calculatedAverage(inputValue, maxLength, this.betaTwoArray);
      case 8 :
        return this.calculatedAverage(inputValue, maxLength, this.hiBetaArray);
      case 9 :
        return this.calculatedAverage(inputValue, maxLength, this.artefaktArray);
    }
  }

  calculatedAverage(inputValue, maxLength, array) {
    if(inputValue) {
      let count = 0;
      if (array.length >= maxLength) {
        array.shift();
      }
      array.push(inputValue);
      for (let i = array.length - 1; i >= 0; i -= 1) {
        if (array[i]) {
          count += array[i];
        }
      }
      const res = count / array.length;
      return Number(res.toFixed(2));
    }
  }

  rawAverageFirstChannel(inputValue: number, maxLength) {
    let count = 0;
    if (this.rawSignalArrayFirstChannel.length >= maxLength) {
      this.rawSignalArrayFirstChannel.shift();
    }
    this.rawSignalArrayFirstChannel.push(inputValue);
    for (let i = this.rawSignalArrayFirstChannel.length - 1; i >= 0; i -= 1) {
      count += this.rawSignalArrayFirstChannel[i];
    }
    const res = count / this.rawSignalArrayFirstChannel.length;
    return res.toFixed(0);
  }

  rawAverageTwoChannels(inputValue: number, maxLength) {
    let count = 0;
    if (this.rawSignalTwoChannelsAverage.length >= maxLength) {
      this.rawSignalTwoChannelsAverage.shift();
    }
    this.rawSignalTwoChannelsAverage.push(inputValue);
    for (let i = this.rawSignalTwoChannelsAverage.length - 1; i >= 0; i -= 1) {
      count += this.rawSignalTwoChannelsAverage[i];
    }
    const res = count / this.rawSignalTwoChannelsAverage.length;
    return res.toFixed(0);
  }

  resetArrays() {
    this.deltaArray = [];
    this.thetaArray = [];
    this.alphaArray = [];
    this.lowAlphaArray = [];
    this.hiAlphaArray = [];
    this.loBetaArray = [];
    this.betaOneArray = [];
    this.betaTwoArray = [];
    this.hiBetaArray = [];
    this.artefaktArray = [];
    this.rawSignalArrayFirstChannel = [];
    this.rawSignalArraySecondChannel = [];
    this.rawSignalTwoChannelsAverage = [];
  }
}
