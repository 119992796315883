import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  currentTime = null;

  constructor() { }

  setVideo(source: HTMLVideoElement, url: string, isIosTablet): void {
    if (source) {
      if (this.currentTime) {
        source.addEventListener('loadeddata', () => {
          if (source.readyState >= 3) {
            source.currentTime = this.currentTime;
            this.currentTime = null;
          }
        });
      }
      source.setAttribute('src', url);
      if (isIosTablet) {
        source.muted = true;
      }
    }
  }

  playVideo(source: HTMLVideoElement): void {
    if (source) {
      source.play();
    }
  }

  pauseVideo(source: HTMLVideoElement): void {
    if (source) {
      source.pause();
    }
  }

  setVideoTime(source: HTMLVideoElement, time): void {
    if (time) {
      if (source) {
        this.currentTime = time;
        source.currentTime = time;
      }
    }
  }

  setVideoVolume(source: HTMLVideoElement, volume):void {
    if (source) {
      source.volume = volume / 100;
    }
  }

  muteVideo(source: HTMLVideoElement, condition):void {
    if (source) {
      source.muted = condition;
    }
  }

  setVideoPaybackRate(source: HTMLVideoElement, rate) {
    if (source) {
      source.defaultPlaybackRate = rate;
      source.playbackRate = rate;
    }
  }
}
