export const PATHS = {
  ['normal']: {
    routes: [
      {
        isAvailable: true,
        title: 'Profile',
        icon: './assets/media/svg/submenu-icons/profile.svg',
        path: '/profile',
        translate: 'MENU.PROFILE',
        children: []
      },
      {
        isAvailable: false,
        title: 'Test',
        icon: './assets/media/svg/submenu-icons/test-screen.svg',
        translate: 'MENU.TEST',
        children: [
          {
            title: 'Test Screen',
            translate: 'MENU.TEST_SCREEN',
            path: '/test',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: false,
        title: 'Training',
        icon: './assets/images/fitness-icon.png',
        translate: 'MENU.TRAINING',
        children: [
          {
            title: 'Training Screen',
            translate: 'MENU.TRAINING_SCREEN',
            path: '/training-screen',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: false,
        title: 'Analysis',
        icon: './assets/images/analysis.png',
        translate: 'MENU.ANALYSIS',
        children: [
          {
            title: 'Analysis Screen',
            translate: 'MENU.ANALYSIS_SCREEN',
            path: '/recordings',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: false,
        title: 'Statistic',
        icon: './assets/images/statistic.png',
        translate: 'MENU.STATISTIC',
        children: [
          {
            title: 'Dashboard',
            translate: 'MENU.DASHBOARD',
            path: '/statistic',
            isAvailable: true,
          }
        ]
      },
    ]
  },
  ['supervisor']: {
    routes: [
      {
        isAvailable: true,
        title: 'Profile',
        icon: './assets/media/svg/submenu-icons/profile.svg',
        path: '/profile',
        translate: 'MENU.PROFILE',
        children: []
      },
      {
        isAvailable: true,
        title: 'Charts',
        icon: './assets/media/svg/submenu-icons/line-view.svg',
        translate: 'MENU.CHARTS',
        children: [
          {
            title: 'Amplitude Overview',
            translate: 'MENU.AMPLITUDE_OVERVIEW',
            path: '/amplitude-overview',
            isAvailable: true,
          },
          {
            title: 'Raw Signal',
            translate: 'MENU.RAW_CHART',
            path: '/raw-chart',
            isAvailable: true,
          },
          {
            title: 'Ratio Overview',
            translate: 'MENU.RATIO_CHART',
            path: '/ratio-chart',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Test',
        icon: './assets/media/svg/submenu-icons/test-screen.svg',
        translate: 'MENU.TEST',
        children: [
          {
            title: 'Test Configuration',
            translate: 'MENU.TEST_CONFIGURATION',
            path: '/test-configuration',
            isAvailable: true,
          },
          {
            title: 'Test Screen',
            translate: 'MENU.TEST_SCREEN',
            path: '/test',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Training',
        icon: './assets/images/fitness-icon.png',
        translate: 'MENU.TRAINING',
        children: [
          {
            title: 'Training Configuration',
            translate: 'MENU.TRAINING_CONFIGURATION',
            path: '/training-configuration',
            isAvailable: true,
          },
          {
            title: 'Training Screen',
            translate: 'MENU.TRAINING_SCREEN',
            path: '/training-screen',
            isAvailable: true,
          },
          {
            title: 'Remote Training',
            translate: 'MENU.REMOTE_TRAINING',
            path: '/remote-training',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Analysis',
        icon: './assets/images/analysis.png',
        translate: 'MENU.ANALYSIS',
        children: [
          {
            title: 'Analysis Screen',
            translate: 'MENU.ANALYSIS_SCREEN',
            path: '/recordings',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Statistic',
        icon: './assets/images/statistic.png',
        translate: 'MENU.STATISTIC',
        children: [
          {
            title: 'Dashboard',
            translate: 'MENU.DASHBOARD',
            path: '/statistic',
            isAvailable: true,
          }
        ]
      },
    ]
  },
  ['super user']: {
    routes: [
      {
        isAvailable: true,
        title: 'Profile',
        icon: './assets/media/svg/submenu-icons/profile.svg',
        path: '/profile',
        translate: 'MENU.PROFILE',
        children: []
      },
      {
        isAvailable: true,
        title: 'Tree',
        icon: './assets/media/svg/submenu-icons/tree.svg',
        path: '/supervisors-tree',
        translate: 'PROFILE.PROFILE.SUPERVISORS_TREE',
        children: []
      },
      {
        isAvailable: true,
        title: 'Supervisors activity',
        icon: './assets/media/svg/submenu-icons/activity.svg',
        path: '/supervisors-activity',
        translate: 'PROFILE.PROFILE.SUPERVISORS_ACTIVITY',
        children: []
      },
      {
        isAvailable: true,
        title: 'Device Overview',
        icon: './assets/media/svg/submenu-icons/cable.svg',
        path: '/device-overview',
        translate: 'MENU.DEVICE_OVERVIEW',
        children: []
      },
      {
        isAvailable: true,
        title: 'Charts',
        icon: './assets/media/svg/submenu-icons/line-view.svg',
        translate: 'MENU.CHARTS',
        children: [
          {
            title: 'Amplitude Overview',
            translate: 'MENU.AMPLITUDE_OVERVIEW',
            path: '/amplitude-overview',
            isAvailable: true,
          },
          {
            title: 'Raw Signal',
            translate: 'MENU.RAW_CHART',
            path: '/raw-chart',
            isAvailable: true,
          },
          {
            title: 'Ratio Overview',
            translate: 'MENU.RATIO_CHART',
            path: '/ratio-chart',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Test',
        icon: './assets/media/svg/submenu-icons/test-screen.svg',
        translate: 'MENU.TEST',
        children: [
          {
            title: 'Test Configuration',
            translate: 'MENU.TEST_CONFIGURATION',
            path: '/test-configuration',
            isAvailable: true,
          },
          {
            title: 'Test Screen',
            translate: 'MENU.TEST_SCREEN',
            path: '/test',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Training',
        icon: './assets/images/fitness-icon.png',
        translate: 'MENU.TRAINING',
        children: [
          {
            title: 'Training Configuration',
            translate: 'MENU.TRAINING_CONFIGURATION',
            path: '/training-configuration',
            isAvailable: true,
          },
          {
            title: 'Training Screen',
            translate: 'MENU.TRAINING_SCREEN',
            path: '/training-screen',
            isAvailable: true,
          },
          {
            title: 'Remote Training',
            translate: 'MENU.REMOTE_TRAINING',
            path: '/remote-training',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Analysis',
        icon: './assets/images/analysis.png',
        translate: 'MENU.ANALYSIS',
        children: [
          {
            title: 'Analysis Screen',
            translate: 'MENU.ANALYSIS_SCREEN',
            path: '/recordings',
            isAvailable: true,
          },
        ]
      },
      {
        isAvailable: true,
        title: 'Statistic',
        icon: './assets/images/statistic.png',
        translate: 'MENU.STATISTIC',
        children: [
          {
            title: 'Dashboard',
            translate: 'MENU.DASHBOARD',
            path: '/statistic',
            isAvailable: true,
          }
        ]
      },
    ]
  }
}
