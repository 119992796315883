import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as moment from 'moment';
import {map, take} from 'rxjs/operators';
import {ToastrMessageService} from '../toastr-message/toastr-message.service';
import {firestore} from 'firebase/app';
import {Observable} from "rxjs";
import {superUser} from "../../core/consts/super-user";
import {ChatTemplate} from "../../core/interfaces/chatTemplateInterface";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public generatedId = null;
  sensorSignals = ['delta', 'theta', 'loAlpha', 'alpha', 'hiAlpha', 'smrLoBeta', 'betaOne', 'betaTwo', 'hiBeta', 'artefakt']

  constructor(
    public afs: AngularFirestore,
    private toast: ToastrMessageService,
  ) {
  }

  getMainTopicList() {
    return new Observable((observer) => {
      const mainTopicList = [];
      this.afs.doc('survey/mainTopicList').valueChanges().pipe(take(1)).subscribe((topics: any) => {
        Object.keys(topics).forEach(key => {
          const mainTopicObject = {
            index: topics[key].index,
            image: topics[key].image,
            title: topics[key].title,
            checked: false,
            data: topics[key].data,
            theme: topics[key].theme
          }
          mainTopicList.push(mainTopicObject);
        });
        observer.next(mainTopicList);
      });
    });
  }

  getSubtopicList() {
    return new Observable((observer) => {
      const subTopicList = [];
      this.afs.doc('survey/subTopicList').valueChanges().pipe(take(1)).subscribe((subtopics) => {
        Object.keys(subtopics).forEach(key => {
          const mainTopicObject = {
            index: subtopics[key].index,
            title: subtopics[key].title,
            checked: false,
            themeImg: subtopics[key].themeImg,
            themeText: subtopics[key].themeText,
            subcategory: subtopics[key].subcategory,
            theme: subtopics[key].theme,
          }
          subTopicList.push(mainTopicObject);
        });
        observer.next(subTopicList);
      });
    });
  }

  submitEditMainTopicTitle(topics) {
    Object.keys(topics).forEach(key => {
      topics[key].data = [];
    });
    this.afs.doc('survey/mainTopicList').set(topics);
  }

  submitEditSubtopics(subtopics) {
    this.afs.doc('survey/subTopicList').set(subtopics);
  }

  saveSession(userId, data, sensorType): void {
    if (!this.generatedId) {
      this.generatedId = Date.now();
    }
    data.id = this.generatedId;
    this.afs.doc(`records/${userId}/${sensorType}/id${this.generatedId}`).set(data);
  }

  saveProcedure(userId, data, sensorType) {
    return new Promise((resolve) => {
      if (data.demoMode) {
        resolve(true);
        return;
      }
      if (data.seconds < 180) {
        resolve(true);
        return;
      }
      const id = Date.now();
      data.id = id;
      try {
        resolve(true);
        return this.afs.doc(`records/${userId}/${sensorType}/id${id}`).set(data);
      } catch (err) {
        this.toast.message(`${err}`, '', 'toast-danger');
      }
    });
  }

  getTrackingData(userId) {
    return this.afs.collection(`tracking/users/${userId}/`).valueChanges();
  }

  setTrackingData(userId, data) {
    const id = Date.now();
    data.id = id;
    return this.afs.doc(`tracking/users/${userId}/id${id}`).set(data);
  }

  updateTrackingData(userId, dataId, data) {
    return this.afs.doc(`tracking/users/${userId}/id${dataId}`).update(data);
  }

  getDefaultTrainingConfigurations() {
    return this.afs.collection(`defaultTrainingConfigurations`).valueChanges();
  }

  getDefaultTestConfigurations() {
    return this.afs.collection(`defaultTestConfiguration`).valueChanges();
  }


  updateTestSuperUser(id, data) {
    this.afs.doc(`defaultTestConfiguration/id${id}`).update(data);
  }

  updateTest(user, data) {
    this.afs.doc(`users/${user.uid}/`).update({situations: data});
  }

  updateTraining(id, data) {
    this.afs.doc(`defaultTrainingConfigurations/id${id}`).update(data);
  }

  updateSupervisorTraining(userId, id, data) {
    this.afs.doc(`defaultTrainingConfigurations/id${id}`).update(data);
  }

  handleIncompleteRecord(record) {
    if (record.note === 'INCOMPLETE' || record.note === 'UNVOLLSTÄNDIG') {
      return true;
    }
  }

  saveStatisticRecord(userId, data) {
    if (data.demoMode || data.seconds < 180 || this.handleIncompleteRecord(data)) {
      return;
    }
    const id = Date.now();
    try {
      return this.afs.doc(`statistic/${userId}/Trainings/id${id}`).set(data);
    } catch (err) {
      this.toast.message(`${err}`, '', 'toast-danger');
    }
  }

  saveRecordToActivityFeed(userId, data) {
    const id = Date.now();
    this.afs.doc(`statistic/${userId}/ActivityFeed/id${id}`).set(data);
  }

  saveVideoTime(userId, data) {
    if (data.name.includes('/')) {
      data.name = data.name.split('/')[0];
    }
    if (data.data.some(el => el.videoUrlChanged)) {
      data.name = data.name + 'UrlChanged';
    }
    try {
      return this.afs.doc(`records/${userId}/VideoTiming/${data.name}`).set(data);
    } catch (err) {
      this.toast.message(`${err}`, '', 'toast-danger');
    }
  }

  getVideoTimingData(userId) {
    return this.afs.collection(`records/${userId}/VideoTiming/`).valueChanges();
  }

  getActivityFeed(userId) {
    return this.afs.collection(`statistic/${userId}/ActivityFeed/`).valueChanges();
  }

  increaseStatisticCount(userId, data) {
    this.afs.doc(`statistic/${userId}/UserStats/`).set(data);
  }

  getStatisticRecords(userId) {
    return this.afs.collection(`statistic/${userId}/Trainings/`).valueChanges();
  }

  shareSession(userId, data, sessionType) {
    this.afs.doc(`records/${userId}/${sessionType}/SharingTraining`).set(data);
  }

  setTrainingConfiguration(userId, data) {
    const docId = Date.now();
    data.id = docId;
    this.afs.doc(`records/${userId}/TrainingConfiguration/id${docId}`).set(data);
  }

  setTrainingConfigurationSuperUser(data) {
    const docId = Date.now();
    data.id = docId;
    this.afs.doc(`defaultTrainingConfigurations/id${docId}`).set(data);
  }

  getTrainingConfiguration(userId) {
    return this.afs.collection(`records/${userId}/TrainingConfiguration/`).valueChanges();
  }

  updateTrainingConfiguration(userId, id, data) {
    this.afs.doc(`records/${userId}/TrainingConfiguration/id${id}`).update(data);
  }

  deleteTrainingConfiguration(userId, id) {
    this.afs.doc(`records/${userId}/TrainingConfiguration/id${id}`).delete();
  }

  deleteTrainingConfigurationSuperUser(id) {
    this.afs.doc(`defaultTrainingConfigurations/id${id}`).delete();
  }

  setUserCustomEntries(userId, data) {
    this.afs.doc(`records/${userId}/CustomEntries/Entries`).set(data);
  }

  getUserCustomEntries(userId) {
    return this.afs.doc(`records/${userId}/CustomEntries/Entries`).valueChanges();
  }

  setCustomThresholdEntry(userId, data) {
    this.afs.doc(`records/${userId}/trainerThreshold/data`).set(data);
  }

  getCustomThresholdEntry(userId) {
    return this.afs.doc(`records/${userId}/trainerThreshold/data`).valueChanges();
  }

  triggerUserScreenOptionsChart(userId, data) {
    this.afs.doc(`records/${userId}/ScreenOptions/ChartOptions`).set(data);
  }

  triggerUserScreenOptionsVideo(userId, data) {
    this.afs.doc(`records/${userId}/ScreenOptions/Video`).set(data);
  }

  getUserScreenOptions(userId) {
    return this.afs.doc(`records/${userId}/ScreenOptions/ChartOptions`).valueChanges();
  }

  getUserScreenOptionsVideo(userId) {
    return this.afs.doc(`records/${userId}/ScreenOptions/Video`).valueChanges();
  }

  shareOtherSessionAmplitude(userId, data, sessionType) {
    this.afs.doc(`records/${userId}/${sessionType}/Amplitude`).set(data);
  }

  shareOtherSessionRatio(userId, data, sessionType) {
    this.afs.doc(`records/${userId}/${sessionType}/Ratio`).set(data);
  }

  deleteOtherSessionAmplitude(userId, data, sessionType) {
    this.afs.doc(`records/${userId}/${sessionType}/Amplitude`).delete();
  }

  deleteOtherSessionRatio(userId, data, sessionType) {
    this.afs.doc(`records/${userId}/${sessionType}/Ratio`).delete();
  }

  getOtherSessionsAmplitude(userId, sensorType) {
    return this.afs.doc(`records/${userId}/${sensorType}/Amplitude/`).valueChanges();
  }

  getOtherSessionsRatio(userId, sensorType) {
    return this.afs.doc(`records/${userId}/${sensorType}/Ratio/`).valueChanges();
  }

  getSessions(userId, sensorType) {
    return this.afs.collection(`records/${userId}/${sensorType}`).valueChanges();
  }

  getSessionsPartial(userId, sensorType, limit, recordType) {
    if (recordType === 'MENU.AMPLITUDE_OVERVIEW') {
      return new Observable((observer) => {
        this.afs.collection(`records/${userId}/${sensorType}/`, ref => ref.where('chartType', '==', recordType).orderBy('id').limitToLast(limit)).valueChanges().subscribe((amplitude) => {
          this.afs.collection(`records/${userId}/${sensorType}/`, ref => ref.where('chartType', '==', 'MENU.RATIO_CHART').orderBy('id').limitToLast(limit)).valueChanges().subscribe((ratio) => {
            observer.next([...amplitude, ...ratio]);
          });
        });
      });
    } else {
      return this.afs.collection(`records/${userId}/${sensorType}/`, ref => ref.where('chartType', '==', recordType).orderBy('id').limitToLast(limit)).valueChanges()
    }
  }

  getSessionsLoadMore(userId, sensorType, start, recordType) {
    return this.afs.collection(`records/${userId}/${sensorType}/`, ref => ref.where('chartType', '==', recordType).orderBy('id').endBefore(start).limitToLast(10)).valueChanges()
  }

  checkForTests(userId, sensorType) {
    return this.afs.collection(`records/${userId}/${sensorType}`, ref => ref.where('chartType', '==', 'MENU.TEST').limit(1)).valueChanges();
  }

  checkForTrainings(userId, sensorType) {
    return this.afs.collection(`records/${userId}/${sensorType}`, ref => ref.where('chartType', '==', 'MENU.TRAINING_SCREEN').limit(1)).valueChanges();
  }

  deleteSession(userId, sensorType, sessionId) {
    return this.afs.doc(`records/${userId}/${sensorType}/id${sessionId}`).delete();
  }

  getSessionById(userId, sensorType, sessionId) {
    return this.afs.doc(`records/${userId}/${sensorType}/id${sessionId}`).valueChanges();
  }

  getFormattedMoment(time?: number): string {
    return moment(time ? time : Date.now()).format('DDMMYYYY-HHmmss');
  }

  getUidByUserId(userId): Observable<any> {
    return this.afs.collection('users', ref => ref.where('id', '==', `${userId}`)).valueChanges();
  }

  getUidByEmail(userEmail) {
    userEmail = userEmail.toLowerCase();
    return this.afs.collection('users', ref => ref.where('email', '==', `${userEmail}`)).valueChanges();
  }

  getUsersForSuperVisor(supervisorId) {
    return this.afs.collection('users', ref => ref.where('allowedSupervisors', 'array-contains', `${supervisorId}`)).valueChanges();
  }

  getTrainingChartData(userId, sessionType) {
    return this.afs.doc(`records/${userId}/${sessionType}/SharingTraining`).valueChanges();
  }

  setTrainingOptions(userId, sessionType, options, youtubePlaylistUrl?) {
    if (youtubePlaylistUrl) {
      options.youtubePlaylist = youtubePlaylistUrl;
    }
    return this.afs.doc(`records/${userId}/${sessionType}/TrainingOptions`).set(options);
  }

  getTrainerOptions(userId, sessionType) {
    return this.afs.collection(`records/${userId}/${sessionType}`).valueChanges();
  }

  updateUser(userUid, credentials) {
    return this.afs.doc(`users/${userUid}/`).update(credentials);
  }

  updateHiddenSupervisorField(userUid, credentials) {
    this.afs.doc(`users/${userUid}/`).update({hidden: credentials.hidden});
  }

  updateShowCoachMarks(userUid, value) {
    this.afs.doc(`users/${userUid}/`).update({showCoachMarks: value});
  }

  deleteTrainer(trainerId, sessionType, deleteUserId) {
    this.afs.doc(`records/${trainerId}/${sessionType}/TrainingOptions`).valueChanges().pipe(take(1)).subscribe((chartOptions: any) => {
      if (chartOptions) {
        const newOptions = chartOptions;
        const newUsersList = chartOptions.usersList;
        const userToDelete = newUsersList.findIndex(item => item === deleteUserId);
        newUsersList.splice(userToDelete, 1);
        newOptions.usersList = newUsersList;
        this.setTrainingOptions(trainerId, sessionType, newOptions);
      }
    });
  }

  updateNote(userId, record) {
    return this.afs.doc(`records/${userId}/EEG/id${record.id}`).update(record);
  }

  setUserStatus(userUid, credentials) {
    return this.afs.doc(`users/${userUid}/`).update({status: credentials.status, lastSeen: credentials.lastSeen});
  }

  sendTrainingNotification(uid: string, email: string, html: string | number, subject: string): Promise<void> {
    return this.afs.collection('mail').doc(uid + Date.now()).set({
      to: email,
      message: {
        html,
        subject
      }
    });
  }

  sendSupervisorCreateEmail(uid: any, email: string, html: string | number, subject: string): Promise<void> {
    return this.afs.collection('mail').doc(uid).set({
      to: email,
      message: {
        html,
        subject: subject ? subject : ''
      }
    });
  }

  setSuperUserPublicYoutubeList(data) {
    this.afs.doc('publicYoutubeVideos/playlist/').update({data});
  }

  getPublicYoutubePlaylist() {
    return this.afs.doc('publicYoutubeVideos/playlist').valueChanges()
  }

  getFirebaseMessages(chatId) {
    return this.afs.doc(`chat/${chatId}`).valueChanges();
  }

  createChat(chatId, chatRoom) {
    const data = {
      messages: [],
      users: chatRoom.users
    }
    return this.afs.doc(`chat/${chatId}`).set(data);
  }

  sendChatMessage(chatId, data) {
    return this.afs.doc(`chat/${chatId}`).set(data);
  }

  createFaq(data) {
    const id = Date.now();
    data.id = id;
    return this.afs.doc(`faq/${id}`).set(data);
  }

  getFaq() {
    return this.afs.collection('faq').valueChanges();
  }

  updateFaq(data, id) {
    return this.afs.doc(`faq/${id}`).update(data);
  }

  updateFaqSeen(data, id) {
    this.afs.doc(`faq/${id}`).update({seen: data.seen})
  }

  deleteFaq(id) {
    return this.afs.doc(`faq/${id}`).delete();
  }

  sendEmail(uid: string, email: string, html: string | number, subject: string): Promise<void> {
    return this.afs.collection('mail').doc(uid + Date.now()).set({
      to: email,
      message: {
        html,
        subject
      }
    });
  }
  setMessageTemplate(data) {
    return this.afs.doc(`chatTemplates/id${data.id}`).set(data);
  }
  getChatTemplates(){
    return this.afs.collection('chatTemplates').valueChanges();
  }
  deleteChatTemplate(id: number): Promise<void> {
    return this.afs.doc(`chatTemplates/id${id}`).delete();
  }
  updateChatTemplate(id: number, updatedTemplate: ChatTemplate): Promise<void> {
    return this.afs.doc(`chatTemplates/id${id}`).update(updatedTemplate);
  }
  // EMAILS

  getEmailTrainingEmailData() {
    return this.afs.doc(`trainingEmailTemplate/Template`).valueChanges();
  }

  getEmailTrainingEmailDataDe() {
    return this.afs.doc(`trainingEmailTemplate/TemplateDE`).valueChanges();
  }

  setTrainingEmailData(data) {
    this.afs.doc(`trainingEmailTemplate/Template`).set(data);
  }

  getEmailTrainingEmailDataDE() {
    return this.afs.doc(`trainingEmailTemplate/TemplateDE`).valueChanges();
  }

  setTrainingEmailDataDE(data) {
    this.afs.doc(`trainingEmailTemplate/TemplateDE`).set(data);
  }

  getEmailTestData() {
    return this.afs.doc(`testEmailTemplate/Template`).valueChanges();
  }

  setEmailTestData(data) {
    this.afs.doc(`testEmailTemplate/Template`).set(data);
  }

  getEmailTestDataDE() {
    return this.afs.doc(`testEmailTemplate/TemplateDE`).valueChanges();
  }

  setEmailTestDataDE(data) {
    this.afs.doc(`testEmailTemplate/TemplateDE`).set(data);
  }

  // Assign
  getEmailSupervisorAssigned() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/TemplateAssigned`).valueChanges();
  }

  setEmailSupervisorAssigned(data) {
    this.afs.doc(`supervisorAssignedEmailTemplate/TemplateAssigned`).set(data);
  }

  getEmailSupervisorAssignedDE() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/TemplateAssignedDE`).valueChanges();
  }

  setEmailSupervisorAssignedDE(data) {
    this.afs.doc(`supervisorAssignedEmailTemplate/TemplateAssignedDE`).set(data);
  }


  // Unassign
  getEmailSupervisorUnassigned() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/TemplateUnassigned`).valueChanges();
  }

  setEmailSupervisorUnassigned(data) {
    this.afs.doc(`supervisorAssignedEmailTemplate/TemplateUnassigned`).set(data);
  }

  getEmailSupervisorUnassignedDE() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/TemplateUnassignedDE`).valueChanges();
  }

  getEmailAsSupervisorAssigned() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/asSupervisor`).valueChanges();
  }

  getEmailAsSupervisorAssignedDE() {
    return this.afs.doc(`supervisorAssignedEmailTemplate/asSupervisorDE`).valueChanges();
  }

  setEmailAsSupervisorAssigned(data) {
    return this.afs.doc(`supervisorAssignedEmailTemplate/asSupervisor`).set(data);
  }

  setEmailAsSupervisorAssignedDE(data) {
    return this.afs.doc(`supervisorAssignedEmailTemplate/asSupervisorDE`).set(data);
  }

  setEmailSupervisorUnassignedDE(data) {
    this.afs.doc(`supervisorAssignedEmailTemplate/TemplateUnassignedDE`).set(data);
  }

  getApproval() {
    return this.afs.doc(`approvalEmail/approval`).valueChanges();
  }

  getApprovalDE() {
    return this.afs.doc(`approvalEmail/approvalDE`).valueChanges();
  }

  setApproval(data) {
    this.afs.doc(`approvalEmail/approval`).set(data);
  }

  setApprovalDE(data) {
    this.afs.doc(`approvalEmail/approvalDE`).set(data);
  }

  getRemoveSupervisor() {
    return this.afs.doc(`approvalEmail/removeSupervisor`).valueChanges();
  }

  getRemoveSupervisorDE() {
    return this.afs.doc(`approvalEmail/removeSupervisorDE`).valueChanges();
  }

  getRemoveSupervisorForUser() {
    return this.afs.doc(`approvalEmail/removeSupervisorForUser`).valueChanges();
  }

  getRemoveSupervisorForUserDE() {
    return this.afs.doc(`approvalEmail/removeSupervisorForUserDE`).valueChanges();
  }

  setRemoveSupervisor(data) {
    return this.afs.doc(`approvalEmail/removeSupervisor`).set(data);
  }

  setRemoveSupervisorDE(data) {
    return this.afs.doc(`approvalEmail/removeSupervisorDE`).set(data);
  }

  setRemoveSupervisorForUser(data) {
    return this.afs.doc(`approvalEmail/removeSupervisorForUser`).set(data);
  }

  setRemoveSupervisorForUserDE(data) {
    return this.afs.doc(`approvalEmail/removeSupervisorForUserDE`).set(data);
  }

  getDeselectTrainingTemplate() {
    return this.afs.doc(`trainingEmailTemplate/deselectTrainingTemplate`).valueChanges();
  }

  getDeselectTrainingTemplateDE() {
    return this.afs.doc(`trainingEmailTemplate/deselectTrainingTemplateDE`).valueChanges();
  }

  setDeselectTrainingTemplate(data) {
    return this.afs.doc(`trainingEmailTemplate/deselectTrainingTemplate`).set(data);
  }

  setDeselectTrainingTemplateDE(data) {
    return this.afs.doc(`trainingEmailTemplate/deselectTrainingTemplateDE`).set(data);
  }

  getDeselectTestTemplate() {
    return this.afs.doc(`testEmailTemplate/deselectTestTemplate`).valueChanges();
  }

  getDeselectTestTemplateDE() {
    return this.afs.doc(`testEmailTemplate/deselectTestTemplateDE`).valueChanges();
  }

  setDeselectTestTemplate(data) {
    return this.afs.doc(`testEmailTemplate/deselectTestTemplate`).set(data);
  }

  setDeselectTestTemplateDE(data) {
    return this.afs.doc(`testEmailTemplate/deselectTestTemplateDE`).set(data);
  }

  getInviteLinkTemplate() {
    return this.afs.doc(`inviteLinkEmailTemplate/invite`).valueChanges();
  }

  getInviteLinkTemplateDE() {
    return this.afs.doc(`inviteLinkEmailTemplate/inviteDE`).valueChanges();
  }

  setInviteLinkTemplate(data) {
    return this.afs.doc(`inviteLinkEmailTemplate/invite`).set(data);
  }

  setInviteLinkTemplateDE(data) {
    return this.afs.doc(`inviteLinkEmailTemplate/inviteDE`).set(data);
  }

  getRemindEmail() {
    return this.afs.doc(`remindEmail/remind`).valueChanges();
  }

  getRemindEmailDE() {
    return this.afs.doc(`remindEmail/remindDE`).valueChanges();
  }

  setRemindEmail(data) {
    return this.afs.doc(`remindEmail/remind`).set(data);
  }

  setRemindEmailDE(data) {
    return this.afs.doc(`remindEmail/remindDE`).set(data);
  }

  getEnglishExpiresTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/englishTemplate`).valueChanges();
  }

  getGermanyExpiresTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/germanTemplate`).valueChanges();
  }

  setEnglishExpiresTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/englishTemplate`).set(data);
  }

  setGermanyExpiresTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/germanTemplate`).set(data);
  }

  setGermanyNormalToPremiumTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/normalToPremiumDE`).set(data);
  }

  setEnglishNormalToPremiumTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/normalToPremium`).set(data);
  }

  setGermanyPremiumToNormalTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/premiumToNormalDE`).set(data);
  }

  setEnglishPremiumToNormalTemplate(data) {
    return this.afs.doc(`premiumExpiresTemplate/premiumToNormal`).set(data);
  }

  getGermanyNormalToPremiumTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/normalToPremiumDE`).valueChanges();
  }

  getEnglishNormalToPremiumTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/normalToPremium`).valueChanges();
  }

  getGermanyPremiumToNormalTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/premiumToNormalDE`).valueChanges();
  }

  getEnglishPremiumToNormalTemplate() {
    return this.afs.doc(`premiumExpiresTemplate/premiumToNormal`).valueChanges();
  }
  getUsersWithDevices(): Observable<any[]> {
    return this.afs.collection('users', ref => ref.where('hasDevices', '==', true)).valueChanges();
  }
  updateUserDevices(userUid: string, devices: string[]){
    return this.afs.collection('users').doc(userUid).update({ devices });
  }
  getDevicesList() {
    return this.afs.collection('devicesList').valueChanges();
  }
  updateDeviceMaxAllowedUsers(deviceName: string, maxAllowedUsers: number): Promise<void> {
    return this.afs.collection('devicesList').doc(deviceName).update({ maxAllowedUsers });
  }
  setDeviceToList(device) {
    return this.afs.doc(`devicesList/${device.deviceName}`).set(device);
  }
  removeDeviceFromList(deviceName) {
    return this.afs.doc(`devicesList/${deviceName}`).delete();
  }
  getDeviceByName(deviceName) {
    return this.afs.doc(`devicesList/${deviceName}`).valueChanges();
  }
}
