<kt-training-prepare (startTraining)="setupTrainingComplete($event)"  *ngIf="!trainingPrepared"></kt-training-prepare>
<kt-trainer-template (handlePause)="stopTrainingInterval($event)" (handleStart)="startTrainingInterval($event)" (resumeTraining)="setTrainerTemplate($event)" (autoResume)="autoResumeTraining()" (isStopped)="markAsStopped()" [template]="selectedTemplate" [user]="currentUser" *ngIf="trainerTemplateSelected"></kt-trainer-template>

<div class="card-body-container" *ngIf="contentLoaded">
<!--  <button (click)="fakeData()">das</button>-->
  <div class="page-heading">
    <h2 *ngIf="!isTablet">{{ "CHARTS_NAMING.TRAINING_SCREEN" | translate }}</h2>
    <div class="trainer-templates"  *ngIf="currentUser?.role !== 'normal'">
      <mat-form-field class="training-choose" *ngIf="trainerTemplates.length">
        <mat-label>{{ "CHART_OPTIONS.TRAINER_TEMPLATES" | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedTemplate" (selectionChange)="setTrainerTemplate($event.value)" [disabled]="trainingStarted || !dataIsComing">
          <mat-option [value]="template" *ngFor="let template of trainerTemplates">{{getTemplateSelectName(template)}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="refresh-training-button" *ngIf="trainerTemplates.length && !selectedTemplate" [disabled]="trainingStarted" (click)="refreshTraining()">
        <mat-icon>refresh</mat-icon>
      </button>
<!--      <button *ngIf="!showOptionsManually" class="auto-threshold" mat-raised-button (click)="setAutoThreshold()">{{ "CHART_OPTIONS.AUTO_THRESHOLD" | translate }}</button>-->
      <div class="hide-options" tourAnchor="hide-options-tour">
        <button *ngIf="currentUser?.role !== 'normal'" mat-raised-button (click)="triggerHideOptions()">{{ "CUSTOM_TRAINING_SCREEN.SHOW_HIDE" | translate }}</button>
      </div>
    </div>
    <div class="user-sharing" *ngIf="currentUser?.role !== 'normal' && showOptions && showOptionsManually">
      <p *ngIf="!trainerId">{{'CHART_OPTIONS.REMOTE_TRAINING' | translate}}</p>
      <mat-form-field class="example-full-width" *ngIf="!trainerId">
        <mat-label>{{ "CHART_OPTIONS.ADD_TRAINER" | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto1" [(ngModel)]="trainerCredential">
        <mat-autocomplete #auto1="matAutocomplete" [class]="'blue-bg'" [panelWidth]="300">
          <mat-option [hidden]="option.id === superUser.id" *ngFor="let option of usersAutocomplete" [value]="option.id">
            <span *ngIf="option.displayName">{{ shortName(option.displayName) | titlecase}}</span> - id: {{option.id}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button *ngIf="!trainerId" mat-raised-button (click)="addTrainer()" class="trainer-button" [disabled]="trainerId">{{ "CHART_OPTIONS.ADD_TRAINER_BUTTON" | translate }}</button>
      <mat-error *ngIf="formError">{{ "CHART_OPTIONS.ADD_TRAINER_ERROR" | translate }}</mat-error>
    </div>
    <div class="user-sharing" *ngIf="currentUser?.role === 'normal'">
      <p *ngIf="!trainerId">{{'CHART_OPTIONS.REMOTE_TRAINING' | translate}}</p>
      <mat-form-field class="example-full-width" *ngIf="!trainerId">
        <mat-label>{{ "CHART_OPTIONS.ADD_TRAINER" | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto1" [(ngModel)]="trainerCredential">
        <mat-autocomplete #auto1="matAutocomplete" [class]="'blue-bg'" [panelWidth]="300">
          <mat-option *ngFor="let option of usersAutocomplete" [value]="option.id">
            <span *ngIf="option.displayName">{{ shortName(option.displayName) | titlecase}}</span> - id: {{option.id}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button *ngIf="!trainerId" mat-raised-button (click)="addTrainer()" class="trainer-button" [disabled]="trainerId">{{ "CHART_OPTIONS.ADD_TRAINER_BUTTON" | translate }}</button>
      <mat-error *ngIf="formError">{{ "CHART_OPTIONS.ADD_TRAINER_ERROR" | translate }}</mat-error>
    </div>
    <div class="transmission-speed" *ngIf="trainerId">
             <mat-form-field class="channel-choose">
               <mat-label>{{ "CHART_OPTIONS.TRANSMISSION_SPEED" | translate }}</mat-label>
               <mat-select style="font-weight: bold" (selectionChange)="changeShareTrainingInterval()" [(value)]="shareTrainingSpeed">
                 <mat-option [value]="500">500ms</mat-option>
                 <mat-option [value]="600">600ms</mat-option>
                 <mat-option [value]="700">700ms</mat-option>
                 <mat-option [value]="800">800ms</mat-option>
                 <mat-option [value]="900">900ms</mat-option>
                 <mat-option [value]="1000">1sec</mat-option>
               </mat-select>
               <mat-select-trigger>
                 <kt-channel-one-dots></kt-channel-one-dots>
               </mat-select-trigger>
             </mat-form-field>
    </div>
  </div>
 <div class="training-view">
   <section [ngClass]="changeChartWidth? 'training-left-full' : 'training-left'">
     <div class="charts-options" *ngIf="showOptions && showOptionsManually">
<!--       <mat-form-field class="channel-choose">-->
<!--         <mat-select disabled [(value)]="selectedChannel" (selectionChange)="changeChannel($event)">-->
<!--           <mat-option value="bp_ch1">{{ "CHART_OPTIONS.CHANNEL_ONE" | translate }}</mat-option>-->
<!--           <mat-option value="bp_ch2">{{ "CHART_OPTIONS.CHANNEL_TWO" | translate }}</mat-option>-->
<!--         </mat-select>-->
<!--         <mat-select-trigger>-->
<!--           <kt-channel-one-dots></kt-channel-one-dots>-->
<!--         </mat-select-trigger>-->
<!--       </mat-form-field>-->
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.Y_VALUE" | translate }}</mat-label>
         <input matInput [(ngModel)]="maxChartZoom" min="0" (input)="changeChartZoomLevel($event)">
       </mat-form-field>
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.X_VALUE" | translate }}</mat-label>
         <input matInput [(ngModel)]="maxChartSeconds" (input)="changeChartUpdateInterval($event)">
       </mat-form-field>
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.ZOOM_STEP" | translate }}</mat-label>
         <input matInput [(ngModel)]="zoomStep">
       </mat-form-field>
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.AVERAGE_OSCILLOSCOPE" | translate }}</mat-label>
         <input matInput [(ngModel)]="selectedAverage" (input)="changeMovingAverage($event)">
       </mat-form-field>
<!--       <mat-form-field>-->
<!--         <mat-label>{{ "CHART_OPTIONS.AVERAGE_BAR" | translate }}</mat-label>-->
<!--         <input matInput [(ngModel)]="selectedAverage" (input)="changeMovingAverageBar($event)">-->
<!--       </mat-form-field>-->
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.THRESHOLD_VALUE" | translate }}</mat-label>
         <input matInput [(ngModel)]="threshHoldValue" (input)="setTrainingDifficult($event)">
       </mat-form-field>
<!--       <button class="auto-threshold" mat-raised-button (click)="setAutoThreshold()">{{ "CHART_OPTIONS.AUTO_THRESHOLD" | translate }}</button>-->
     </div>
     <div class="example-container" cdkScrollable>
     </div>
     <div class="frequency-container" *ngIf="showOptions && showOptionsManually">
       <mat-form-field class="training-type">
         <mat-label>{{ "CHART_OPTIONS.TRAINING_TYPE" | translate }}</mat-label>
         <mat-select [(ngModel)]="trainingType" (selectionChange)="changeTrainingType($event.value)">
           <mat-option class="channel-option" value="ratio">Ratio</mat-option>
           <mat-option class="channel-option" value="amplitude">Amplitude</mat-option>
         </mat-select>
       </mat-form-field>
       <div class="ratio-one">
         <mat-form-field class="ratio-choose" >
           <mat-select [(ngModel)]="frequencyOne" (selectionChange)="resetTrainingAverage()" [disabled]="!showOptions && trainerId && !showOptionsManually && trainerId">
             <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
           </mat-select>
         </mat-form-field>
         <span *ngIf="trainingType === 'ratio'"> / </span>
         <mat-form-field class="ratio-choose" *ngIf="trainingType === 'ratio'">
           <mat-select [(ngModel)]="frequencyTwo" (selectionChange)="resetTrainingAverage()" [disabled]="!showOptions && trainerId && !showOptionsManually && trainerId">
             <mat-option class="channel-option" *ngFor="let ratio of ratioChannels" [value]="ratio.id">{{ratio.name}}</mat-option>
           </mat-select>
         </mat-form-field>
         <mat-form-field class="threshold-type">
           <mat-label>{{ "CHART_OPTIONS.THRESHOLD_TYPE" | translate }}</mat-label>
           <mat-select [(value)]="threshHoldType" (selectionChange)="changeTrashHoldType($event)">
             <mat-option value="reward">{{ "CHART_OPTIONS.THRESHOLD_TYPE_REWARD" | translate }}</mat-option>
             <mat-option value="inhibit">{{ "CHART_OPTIONS.THRESHOLD_TYPE_INHIBIT" | translate }}</mat-option>
           </mat-select>
         </mat-form-field>
       </div>
     </div>
     <div class="ratio-after" *ngIf="selectedTemplate">
       <p *ngIf="trainingType === 'ratio'">
         <span>{{getRatioName(frequencyOne)}}</span>
         <span class="spacer">/</span>
         <span>{{getRatioName(frequencyTwo)}}</span>
         <span class="hide-options-training-type" *ngIf="!showOptionsManually">( {{threshHoldType}} )</span>
       </p>
       <p *ngIf="trainingType === 'amplitude'">
         <span>{{getRatioName(frequencyOne)}}</span>
         <span class="hide-options-training-type" *ngIf="!showOptionsManually">( {{threshHoldType}} )</span>
       </p>
       <div class="success-rate">
         <p>{{ "CHART_OPTIONS.SUCCESS_RATE" | translate }}</p>
         <p class="value">{{minuteSuccess}}</p>
       </div>
       <mat-form-field>
         <mat-label>{{ "CHART_OPTIONS.TRAINING_DIFFICULTY" | translate }}</mat-label>
         <mat-select [(value)]="thresholdDifficult" (selectionChange)="setTrainingDifficult()">
           <mat-option value="80-90%">{{ "CHART_OPTIONS.EASY_TRAINING" | translate }}</mat-option>
           <mat-option value="70-80%">{{ "CHART_OPTIONS.NORMAL_TRAINING" | translate }}</mat-option>
           <mat-option value="60-70%">{{ "CHART_OPTIONS.HARD_TRAINING" | translate }}</mat-option>
         </mat-select>
       </mat-form-field>
     </div>
     <div class="charts-container">
       <button class="visibility-button button-line" (click)="triggerLineChart()" placement="right" [ngbTooltip]="'Oscilloscope'" tourAnchor="hide-chart-tour">
         <img [src]="showCharts.showLineChart ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
       </button>
       <div [ngClass]="showCharts.showLineChart ? 'line-chart' : 'hidden-chart'">
         <canvas #mainRatioChart
                 id="mainLineChart"
                 tourAnchor="page-tour">
         </canvas>
         <div class="chart-zoom">
           <button mat-raised-button (click)="zoomOutChart()">+</button>
           <button mat-raised-button (click)="zoomInChart()">-</button>
         </div>
       </div>
       <button class="visibility-button button-bar" (click)="triggerBarChart()" placement="left" [ngbTooltip]="'Bar'">
         <img [src]="showCharts.showBarChart ? './assets/visibility-icons/show.png' : './assets/visibility-icons/hide.png'" alt="img">
       </button>
       <div class="bar-chart" [ngClass]="showCharts.showBarChart ? 'bar-chart' : 'hidden-chart'">
         <canvas #mainBarChart
                 id="mainBarChart">
         </canvas>
         <div class="slider-container">
           <mat-slider vertical [min]="0.01" [max]="maxChartZoom" step="0.01" [value]="threshHoldValue" (change)="setBarChartThreshold($event.value)"></mat-slider>
         </div>
       </div>
     </div>
     <div class="ratios-container" *ngIf="showCharts.showLineChart && selectedTemplate">
       <div class="ratio-item">
         <p [ngStyle]="getTextColor()">{{trainingType}}:</p>
         <p [ngStyle]="getTextColor()" class="value">{{averageRatioOne}}</p>
       </div>
       <div class="ratio-item" tourAnchor="signal-distance-tour">
         <p>{{ "CHART_OPTIONS.THRESHOLD_VALUE" | translate }}: </p>
         <p class="value">{{threshHoldValue}}</p>
       </div>
     </div>
   </section>
   <section [ngClass]="changeChartWidth? 'training-right-small' : 'training-right'" tourAnchor="youtube-tour">
    <kt-youtube-video [sessionTime]="recordData.seconds" *ngIf="trainingPrepared"></kt-youtube-video>
   </section>
 </div>
<!--  <div class="training-overlay" *ngIf="pageLoading">-->
<!--    <mat-spinner [diameter]="100" class="page-spinner"></mat-spinner>-->
<!--  </div>-->
</div>

<div class="countdown-wrapper" *ngIf="showCountDown">
  <div class="seconds-wrap">
    <p>{{trainingCountDown}}</p>
  </div>
</div>
<div class="ending-spinner-wrap" *ngIf="showEndingSpinner">
  <span>{{'TRAINING_SCREEN.SAVING_SESSION' | translate}}</span>
      <mat-spinner [diameter]="100" class="page-spinner"></mat-spinner>
</div>
