<div
	class="position-absolute top-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 register-top-nav">
	<span class="font-weight-bold text-dark-50">
		{{ "AUTH.REGISTER.BACK_TO_LOGIN" | translate }}
	</span>
	<a href="javascript:;" routerLink="/auth/login" class="font-weight-bold ml-2"
		id="kt_login_signup">{{ "AUTH.REGISTER.BACK" | translate }}</a>
</div>
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block; height: 570px;">
		<div class="text-center mb-10 mb-lg-20">
			<h3 class="font-size-h1">{{ "AUTH.REGISTER.REGISTRATION" | translate }}</h3>
		</div>

		<kt-auth-notice></kt-auth-notice>
      <h3>{{ "AUTH.REGISTER.YOUR_DETAILS" | translate }}</h3>
		<form class="form" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
						formControlName="email" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
						formControlName="password" autocomplete="off" [type]="hide ? 'password' : 'text'" />
					<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hide">
						<mat-icon>{{ hide ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input matInput type="text" placeholder="Name" formControlName="username" />
					<mat-error *ngIf="isControlHasError('username','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ 'AUTH.REGISTER.AGE' | translate }}</mat-label>
					<input matInput type="text" placeholder="{{ 'Age' | translate }}" formControlName="age"
						autocomplete="off" />
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				</mat-form-field>
				<!-- <mat-form-field> -->

					<!-- <input matInput type="text" placeholder="{{ 'Gender' | translate }}"
						formControlName="gender" autocomplete="off" /> -->
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				<!-- </mat-form-field> -->
			</div>
			<div class="form-group" style="padding-bottom: unset">
				<mat-form-field>
					<mat-label>{{ "PROFILE.PROFILE.GENDER" | translate }}</mat-label>
					<mat-select placeholder="Gender" formControlName="gender">
						<mat-option value="Female">{{"PROFILE.PROFILE.FEMALE" | translate}}</mat-option>
						<mat-option value="Male">{{"PROFILE.PROFILE.MALE" | translate}}</mat-option>
						<mat-option value="Other">{{"PROFILE.PROFILE.OTHER" | translate}}</mat-option>
					</mat-select>
					<!-- <mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error> -->
				</mat-form-field>
			</div>

      <div class="form-group" style="padding-bottom: unset;margin-bottom: 10px">
        <mat-checkbox (change)="triggerSupervisorField()" formControlName="isSupervisor">
          {{ "AUTH.REGISTER.I_IM_SUPERVISOR" | translate }}
        </mat-checkbox>
        <mat-form-field *ngIf="isSupervisor">
          <input matInput type="text" placeholder="Code" formControlName="supervisorCode" autocomplete="off" />
          <mat-error *ngIf="handleSupervisorCode()">
            <strong>{{ "AUTH.REGISTER.WRONG_SUPERVISOR_CODE" | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!isSupervisor">
        <h3>{{ "AUTH.REGISTER.YOUR_SUPERVISOR" | translate }}</h3>

        <div class="form-group" style="padding-bottom: unset;margin-bottom: 10px">
          <mat-radio-group formControlName="supervisorFromList" (change)="handleSupervisorsList($event.value)">
            <mat-radio-button [value]="false">{{ "AUTH.REGISTER.SUPERVISED_TEXT" | translate }}</mat-radio-button>
<!--            <mat-radio-button [value]="true">{{ "AUTH.REGISTER.SUPERVISOR_FROM_LIST" | translate }}</mat-radio-button>-->
          </mat-radio-group>
        </div>

        <div class="form-group" style="padding-bottom: unset" [hidden]="hideSupervisorsList" *ngIf="supervisorFromList">
          <mat-form-field>
            <mat-label>{{ "AUTH.REGISTER.CHOOSE_SUPERVISOR" | translate }}</mat-label>
              <mat-select multiple formControlName="allowedSupervisors">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="{{'SELECT_RECORDING.SEARCH_TEXT' | translate}}" noEntriesFoundLabel="{{'SELECT_RECORDING.NO_RESULTS' | translate}}" [formControl]="searchValue"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let supervisor of supervisorsArray" [value]="supervisor.id">
                  <span *ngIf="supervisor.name">{{shortName(supervisor.name)}} - ID: {{supervisor.id}}</span>
                  <span *ngIf="!supervisor.name"><span class="id">ID:</span> {{supervisor.id}}</span>
                </mat-option>
              </mat-select>
          </mat-form-field>
          <div class="supervisor-info">
            <span class="material-symbols-outlined">
              info
            </span>
            <p>{{'AUTH.REGISTER.SUPERVISOR_HINT' | translate}}</p>
          </div>
        </div>
      </div>

			<div class="form-group" style="padding-bottom: unset">
				<mat-checkbox name="agree" formControlName="agree">
          {{ "AUTH.REGISTER.AGREE" | translate }} <a href="https://www.websitepolicies.com/policies/view/kaX1BwAE">{{ "AUTH.REGISTER.TERMS" | translate }}</a>
				</mat-checkbox>
			</div>

      <div class="captcha form-group d-flex flex-wrap flex-center">
        <ngx-recaptcha2 #captchaElem siteKey="6Le7bRwiAAAAAFQ4wHN84v6BNhPdLzXtQFdLvr_Z"
                        formControlName="recaptcha" (success)="handleSuccess($event)">
        </ngx-recaptcha2>
      </div>

			<!--begin::Action-->
			<div class="form-group d-flex flex-wrap flex-center">
				<button style="width: 100%;" [disabled]="registerForm.invalid"  (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
				<a style="width: 100%;" routerLink="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">{{ "AUTH.REGISTER.CANCEL" | translate }}</a>
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>
