import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChatService} from "../../../services/chat/chat.service";

@Component({
  selector: 'kt-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  file = null;

  constructor(
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private chat: ChatService
  ) { }

  ngOnInit(): void {
    this.getFileFromStorage();
  }

  getFileFromStorage() {
    this.chat.getPreviewUrl(this.data.chatId, this.data.file).subscribe((url) => {
      this.file = url;
    });
  }
  isImageFile(name) {
    name = name.split('.').pop();
    const validImageTypes = ['gif', 'jpeg', 'jpg', 'png', 'PNG'];
    if (validImageTypes.includes(name)) {
      return true;
    }
  }
  isPdfFile(name) {
    name = name.split('.').pop();
    if (name === 'pdf') {
      return true;
    }
  }
}
