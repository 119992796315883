import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { AppState} from '../../../core/reducers/';

import { currentUser } from '../_selectors/auth.selectors';
import { map, tap } from 'rxjs/operators';

export class RoleGuard implements CanActivate {

  constructor(private store: Store<AppState>, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store
    .pipe(
      select(currentUser),
      tap(user => {
        if (user && user.role !== 'supervisor') {
          this.router.navigateByUrl('/');
        }
      }),
      map(user => {
        if (user && user.role === 'supervisor') {
          return true
        } else {
          return false
        }
      })
    );
  }
}
