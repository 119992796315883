<div class="actions-container" *ngIf="showButtons && currentUser?.role === 'supervisor'" tourAnchor="record-actions">
  <div class="date-container">
    <p>{{ "RECORD_ACTIONS.ACTIVITY" | translate }}</p>
    <p class="record-length" [ngClass]="{'record-length-minimize': columnData}">{{ "RECORD_ACTIONS.PRESET_LENGTH" | translate }}</p>
    <mat-form-field>
      <mat-select [(ngModel)]="recordPreset">
        <mat-option value="05">5 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
        <mat-option value="10">10 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
        <mat-option value="15">15 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
        <mat-option value="20">20 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
        <mat-option value="25">25 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
        <mat-option value="30">30 {{ "RECORD_ACTIONS.MIN" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <p><span>{{minutes}}:</span><span>{{seconds}}</span></p>
  </div>
  <div class="buttons-container" [ngClass]="{'buttons-container-collapse': columnData}">
    <button [disabled]="isRecordingStarted" (click)="startTimer()">
      <span class="material-icons recording" [ngClass]="{'record-blinking': running}" *ngIf="running">
        fiber_manual_record
      </span>
      <span class="material-icons recording" *ngIf="!running">
        fiber_manual_record
    </span>
    </button>
    <button (click)="stopRecording()">
      <img src="./assets/button-actions/stop.png" alt="stop">
    </button>
  </div>
</div>
