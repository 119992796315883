import { Injectable } from '@angular/core';
import {ExgPacketDecoder} from '../core/interfaces/exg_interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MuscleDataService {

  muscleDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  dataStreamingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bluetoothServiceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  deviceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  artefaktSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  tooHightArtefact: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sensorConnectState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  currentImpedance = 0;

  userId = '';

  constructor(
  ) { }


  decodeMuscleData(value: any): void {
    this.muscleDataSubject.next(ExgPacketDecoder.decode(new Uint8Array(value)));
  }
}
